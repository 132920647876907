import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  AlertTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import usePostAxiosData from "../../../hooks/usePostAxiosData";
import useCheckFormSection from "../../../hooks/useCheckFormSection";
import CardForm from "../../../components/form/card";
import FormSectionAccordion from "../../../components/form/FormSection";
import Input from "../../../components/Input";
import RadioButton from "../../../components/form/RadioButton";
import Button from "../../../components/Button";
import PreloaderSpinner from "../../../components/PreloaderSpinner";
import { t } from "i18next";
import Asterisk from "../../../components/form/Asteris";
import { ArrowBack, ArrowForward, HelpCenter, HelpCenterOutlined, HelpCenterRounded, HelpCenterSharp } from "@mui/icons-material";
import { PageContext } from "../../../App";
import { useTranslation } from "react-i18next";


const ProjectStudy = ({ PROPS_DATA }) => {
  const schema = yup.object().shape({
    history: yup.string().required(),
    swot: yup.string().required(),
    reducerisks: yup.string().required(),
    successfactors: yup.string().required(),
    problemsolved: yup.string().required(),
    innovationassociated: yup.string().required(),
    needs: yup.string().required(),


  });
  const [innovation_id, setinnovation_id] = useState(null)
  const [marketofproject_id, setmarketofproject_id] = useState(null)
  const [project_faisability_id, setproject_faisability_id] = useState(null)
  const [project_rd_id, setproject_rd_id] = useState(null)
  const [project_juridique_id, setproject_juridique_id] = useState(null)
  const [project_plan_id, setproject_plan_id] = useState(null)

  const [project_maturation_id, setproject_maturation_id] = useState(null)

  const { i18n } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    handleSave(data);
  };

  const [loading, postAxiosData, errorPostMassage, successPostMessage, result] =
    usePostAxiosData(`candidatures/candidates/${PROPS_DATA?.candidate_id}/step-three`, "POST");
    const [errorC, setErrorC] = useState('');
    const handleSave = async (data) => {
      setErrorC('')
     if(!innovation_id || !marketofproject_id || !project_faisability_id || !project_rd_id || !project_juridique_id || !project_plan_id || !project_maturation_id){
      setErrorC(t('forms.All fields containing'))
      alert(t('forms.All fields containing'))
     }else{
    const toSave = {
      ...data,

      innovation_id,
      marketofproject_id,
      project_faisability_id,
      project_rd_id,
      project_juridique_id,
      project_plan_id,
      project_maturation_id,

    }
    console.log("🚀 ~ file: 2_ProjectLeader.js:98 ~ handleSave ~ toSave:", toSave)
    // console.log('tosave => ',toSave)
    await postAxiosData(toSave);
  }
  };
  const {activeTab, setactiveTab, handleNext, handlePrevious} = useContext(PageContext);

  useEffect(() => {
    if (successPostMessage.status === 200 || successPostMessage.status === 201) {
      PROPS_DATA?.fetchDataCandidature()
      handleNext()

    }
  }, [successPostMessage])

  const description = [
    "history",
    "swot",
    "reducerisks",
    "successfactors",
    "problemsolved",
    "innovationassociated",
    "needs",
    "innovation_id"
  ]

  const entrepreneurial = [
    "innovation_id",
    "marketofproject_id",
    "project_faisability_id",
    "project_rd_id",
    "project_juridique_id",
    "project_plan_id",
  ]


  const technological = [
    "project_maturation_id",
  ]

  const completed1 = useCheckFormSection(PROPS_DATA?.candidature, description)
  const completed2 = useCheckFormSection(PROPS_DATA?.candidature, entrepreneurial)
  const completed3 = useCheckFormSection(PROPS_DATA?.candidature, technological)
  const [building, setBuilding] = useState(null)

  useEffect(() => {
    if (PROPS_DATA?.candidature) {
      setBuilding(true)
      const all = [...description, ...entrepreneurial, ...technological];

      all.map((key) => {
        const val = PROPS_DATA?.candidature[key]
        // Yup set value
        setValue(key, val)
        // States setValue
        if (key === "innovation_id") setinnovation_id(val)
        if (key === "marketofproject_id") setmarketofproject_id(val)

        if (key === "project_faisability_id") setproject_faisability_id(val)
        if (key === "project_rd_id") setproject_rd_id(val)

        if (key === "project_juridique_id") setproject_juridique_id(val)
        if (key === "project_plan_id") setproject_plan_id(val)

        if (key === "project_maturation_id") setproject_maturation_id(val)

      })
      setTimeout(() => {

        setBuilding(false)
      }, 1000);

    }
  }, [PROPS_DATA?.candidature])

  return (
    loading || building === true ? <PreloaderSpinner /> :
      <>
        <form className="px-2" onSubmit={handleSubmit(onSubmitHandler)}>

        {
            (errorPostMassage || errorC )&& <Alert className="my-2 mb-3 rounded shadow  " severity="error">< AlertTitle> {(errorC || errorPostMassage?.message)}</AlertTitle></Alert>
          }
          <Alert className="shadow rounded" severity="info" >

            <span className="text-decoration-underline">{t("forms.Objective")}:</span> 
            {t("forms.step3.Show that there is a match between people")}
          </Alert>
          <FormSectionAccordion
            title={t("Instructions")}

            type="info"
          >
            <div className="row row-cols-1 row-cols-sm-3 my-2 mx-2">
              <CardForm text={t("forms.step3.Be concise and precise")} />
              <CardForm text={t("forms.step3.Complete all sections")} />
              <CardForm text={t("forms.step3.If you do not yet have certain data")} />
            </div>
          </FormSectionAccordion>

          <FormSectionAccordion expanded={'panel1'} completed={completed1}
            title={t("forms.step3.General description of the project")}
          >
            <Input
              register={register}
              error={errors.history?.message}
              className={errors.history?.message && `border-danger`}
              label={t("forms.step3.Genesis of the project")}
              placeholder={t("forms.step3.Genesis of the project")}
              isRequired={true}
              name={"history"}
              id={"history"}
              type={"textarea"}
            />

            <Input
              register={register}
              error={errors.swot?.message}
              className={errors.swot?.message && `border-danger`}
              label={t("forms.step3.What are the strengths")}
              placeholder={t("forms.step3.What are the strengths")}
              isRequired={true}
              name={"swot"}
              id={"swot"}
              type={"textarea"}
            />

            <Input
              register={register}
              error={errors.reducerisks?.message}
              className={errors.reducerisks?.message && `border-danger`}
              label={t("forms.step3.How do you intent to limit these risks ?")}
              placeholder={t("forms.step3.How do you intent to limit these risks ?")}
              isRequired={true}
              name={"reducerisks"}
              id={"reducerisks"}
              type={"textarea"}
            />

            <Input
              register={register}
              error={errors.successfactors?.message}
              className={errors.successfactors?.message && `border-danger`}
              label={t("forms.step3.List 3 key factors")}
              placeholder={t("forms.step3.List 3 key factors")}
              isRequired={true}
              name={"successfactors"}
              id={"successfactors"}
              type={"textarea"}
            />

            <Input
              register={register}
              error={errors.problemsolved?.message}
              className={errors.problemsolved?.message && `border-danger`}
              label={t("forms.step3.The problem to be solved (motivation, goals)")}
              placeholder={t("forms.step3.The problem to be solved (motivation, goals)")}
              isRequired={true}
              name={"problemsolved"}
              id={"problemsolved"}
              type={"textarea"}
            />

            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">{t("forms.step3.Innovationcarried by the proposed solution")}<Asterisk /></FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue={PROPS_DATA?.candidature?.innovation_id}

              >
                {
                  PROPS_DATA?.innovations?.map((t) => {
                    return (
                      <RadioButton label={i18n?.language==='fr' ? (t?.name_fr || t?.name) :  (t?.name || t?.name_fr)} value={t?.id}
                        handlChange={(e) => setinnovation_id(e.target.value)} />

                    )
                  })
                }
              </RadioGroup>
            </FormControl>

            <Input
              register={register}
              error={errors.innovationassociated?.message}
              className={errors.innovationassociated?.message && `border-danger`}
              label={t("forms.step3.Innovation is linked")}
              placeholder={t("forms.step3.Innovation is linked")}
              isRequired={true}
              name={"innovationassociated"}
              id={"innovationassociated"}
              type={"textarea"}
            />
            <Input
              register={register}
              error={errors.needs?.message}
              className={errors.needs?.message && `border-danger`}
              label={t("forms.step3.How does your project")}
              placeholder={t("forms.step3.How does your project")}
              isRequired={true}
              name={"needs"}
              id={"needs"}
              type={"textarea"}
            />
          </FormSectionAccordion>

          <FormSectionAccordion completed={completed2}
            title={t("forms.step3.Level of entrepreneurial maturity")}
          >
            <div className="row row-cols-1 row-cols-sm-2 ms-1">

              <FormControl >
                <FormLabel id="demo-row-radio-buttons-group-label">a.{t("forms.step3.Market research")}  <Asterisk /></FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={PROPS_DATA?.candidature?.marketofproject_id}
                // defaultValue={"99f3b88a-61cc-4b0a-a90a-457f99603ba5"}

                >

                  {
                    PROPS_DATA?.project_states?.map((t) => {
                      // console.log("t?.id", t?.id === PROPS_DATA?.candidature?.marketofproject_id)
                      return (
                        <RadioButton label={i18n?.language==='fr' ? (t?.name_fr || t?.name) :  (t?.name || t?.name_fr)} value={t?.id}
                          handlChange={(e) => setmarketofproject_id(e.target.value)} />

                      )
                    })
                  }
                </RadioGroup>
              </FormControl >

              <FormControl >
                <FormLabel id="demo-row-radio-buttons-group-label">b){t("forms.step3.Financial feasibility study?")} <Asterisk /></FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={PROPS_DATA?.candidature?.project_faisability_id}

                >
                  {
                    PROPS_DATA?.project_faisabilities?.map((t) => {
                      return (
                        <RadioButton label={i18n?.language==='fr' ? (t?.name_fr || t?.name) :  (t?.name || t?.name_fr)} value={t?.id}
                          handlChange={(e) => setproject_faisability_id(e.target.value)} />

                      )
                    })
                  }
                </RadioGroup>
              </FormControl>

              <FormControl >
                <FormLabel id="demo-row-radio-buttons-group-label">c){t("forms.step3.R&D Technological Process?")} <Asterisk /></FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={PROPS_DATA?.candidature?.project_rd_id}

                >
                  {
                    PROPS_DATA?.project_rds?.map((t) => {
                      return (
                        <RadioButton label={i18n?.language==='fr' ? (t?.name_fr || t?.name) :  (t?.name || t?.name_fr)} value={t?.id}
                          handlChange={(e) => setproject_rd_id(e.target.value)} />

                      )
                    })
                  }
                </RadioGroup>
              </FormControl>

              <FormControl >
                <FormLabel id="demo-row-radio-buttons-group-label">d){t("forms.step3.Legalstudy")} <Asterisk /></FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={PROPS_DATA?.candidature?.project_juridique_id}

                >
                  {
                    PROPS_DATA?.project_juridiques?.map((t) => {
                      return (
                        <RadioButton label={i18n?.language==='fr' ? (t?.name_fr || t?.name) :  (t?.name || t?.name_fr)} value={t?.id}
                          handlChange={(e) => setproject_juridique_id(e.target.value)} />

                      )
                    })
                  }
                </RadioGroup>
              </FormControl>


              <FormControl >
                <FormLabel id="demo-row-radio-buttons-group-label">e){t("forms.step3.Job plan")}<Asterisk /></FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={PROPS_DATA?.candidature?.project_plan_id}

                >
                  {
                    PROPS_DATA?.project_plans?.map((t) => {
                      return (
                        <RadioButton label={i18n?.language==='fr' ? (t?.name_fr || t?.name) :  (t?.name || t?.name_fr)} value={t?.id}
                          handlChange={(e) => setproject_plan_id(e.target.value)} />

                      )
                    })
                  }
                </RadioGroup>
              </FormControl>
            </div>

          </FormSectionAccordion>


          <FormSectionAccordion completed={completed3}
            title={t("forms.step3.Level of technological maturity")}>


            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">{t("forms.step3.Please tick")} <Asterisk /></FormLabel>
              <RadioGroup
                row
                className="d-flex flex-column"
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue={PROPS_DATA?.candidature?.project_maturation_id}
              >
                {
                  PROPS_DATA?.project_maturations?.map((t) => {
                    return (
                      <RadioButton label={i18n?.language==='fr' ? (t?.name_fr || t?.name) :  (t?.name || t?.name_fr)} value={t?.id}
                        handlChange={(e) => setproject_maturation_id(e.target.value)} />

                    )
                  })
                }
              </RadioGroup>
            </FormControl>

          </FormSectionAccordion>


          <div className="d-flex justify-content-between mt-5 " aria-haspopup="true">
          <Button text={t('forms.Previous')}
              // disabled={PROPS_DATA?.candidature?.step8 === 1}
              className={'btn-danger me-2'}
              handleClick={handlePrevious}
              icon={<ArrowBack />}

            />
            <Button text={t('forms.Save And Continue')}

              disabled={PROPS_DATA?.candidature?.step8 === 1}
              className={'btn-primary'}
              icon={<ArrowForward />}
              arrowEnd={true}

            />
          </div>
        </form>
      </>
  );
};

export default ProjectStudy;
