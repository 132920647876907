import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Asterisk from "./form/Asteris";
const Input = ({
  className,
  register,
  isRequired,
  error,
  label,
  name,
  placeholder,
  value,
  onChange,
  type,
  onKeyDown,
  disabled,
  rows,
  cols,
  accept

}) => {
  // console.log(type);
  return (
    <>
      {type === "text" || type === "textarea" || type === "number" ? (
        <div className="col">

          <TextField label={label} variant="standard"
            // color={"warning"}
            // style={{color : 'black'}}
            className="shadow-sm"
            fullWidth
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            aria-readonly={disabled}
            id={name}
            {...register(`${name}`)}
            required={isRequired}
            multiline={type === 'textarea'}
            rows={type === 'textarea' ? rows : ''}
            type={type === 'number' ? 'number' : ''}
          // type={type}
          />

          {/* <div className="d-flex flex-row">
            <span className="p-sm input-header text-truncate w-100 text-dark">
              {label} {isRequired ? <span class="text-danger">*</span> : <></>}
            </span>
          </div>
          <input
            {...register(`${name}`)}
            className={`form-control name ${className}`}
            type={`${type ? type : "text"}`}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            disabled={disabled}
            id={name}
          /> */}
          <p className="text-danger">{error !== "" ? error : ""}</p>
        </div>
      )

        // : type == "textarea" ? (
        //   <div>
        //     <div className="d-flex flex-row">
        //       <span className="p-sm input-header text-truncate w-100 text-dark">
        //         {label}
        //       </span>
        //       {isRequired ? <span class="text-danger">*</span> : <></>}
        //     </div>
        //     <textarea
        //       {...register(`${name}`)}
        //       className={`form-control ${className}`}
        //       name={name}
        //       value={value}
        //       placeholder={placeholder}
        //       onChange={onChange}
        //       onKeyDown={onKeyDown}
        //       disabled={disabled}
        //       id={name}
        //       cols={cols}
        //       rows={rows}
        //     />
        //     <p className="text-danger">{error !== "" ? error : ""}</p>
        //   </div>
        // ) 

        : type === "file" ? (
          <div className="col">
            <div className="d-flex flex-row">
              <span className="p-sm input-header text-truncate w-100 text-dark">
                {label} {isRequired ? <span class="text-danger">*</span> : <></>}
              </span>
            </div>
            <input
              // {...register(`${name}`)}
              className={`form-control name ${className}`}
              type={`${type ? type : "file"}`}
              name={name}
              value={value}
              onChange={onChange}
              onKeyDown={onKeyDown}
              disabled={disabled}
              id={name}
              accept={accept || "image/*"}
            />
            <p className="text-danger">{error !== "" ? error : ""}</p>
          </div>
        ) : type === "date" && (
          <div className="col">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                // {...register(`${name}`)}
                className="shadow-sm"
                defaultValue={value ? dayjs(new Date(value)) : null}
                onChange={onChange}
                disableFuture
                label={<>{label} { isRequired && <Asterisk/>}</>}
                

              />
            </LocalizationProvider>
          </div>
        )}
    </>
  );
};

export default Input;
