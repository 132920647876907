import { Light, LightRounded, LightbulbRounded } from "@mui/icons-material";

export default function CardForm({text}) {
    
    return(
        <>
        <div className="col border rounded-4 p-3 mb-2  shadow  text-info">
          <p className="m-0 p-0 d-flex">
          <LightbulbRounded className="me-1 text-warning "/>

            <div>
            {text}

            </div>
          </p>
        </div>
        </>
    )
}