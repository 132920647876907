import React, { useContext, useEffect, useState } from "react";
import FileUploader from "../../../components/form/FileUploader";
import FormSectionAccordion from "../../../components/form/FormSection";
import useCheckFormSection from "../../../hooks/useCheckFormSection";
import Button from "../../../components/Button";
import { t } from "i18next";
import { PageContext } from "../../../App";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Alert } from "@mui/material";
import { FILE_MAX_SIZE } from "../../../constants/GLOBAL_VARIALABELS";
import usePostAxiosData from "../../../hooks/usePostAxiosData";
const Documents = ({ PROPS_DATA }) => {
  // const completed1 = useCheckFormSection(PROPS_DATA?.candidature?.document, description)
  const [loading, postAxiosData, errorPostMassage, successPostMessage, result] =
    usePostAxiosData(`candidatures/candidates/${PROPS_DATA?.candidate_id}/step-seven`, "POST");

  const { activeTab, setactiveTab, handleNext, handlePrevious } =
    useContext(PageContext);
  const [errors, seterrors] = useState(
    { phbp: "" },
    { phcv: "" },
    { partner1_cv: "" },
    { partner2_cv: "" },
    { partner3_cv: "" },
    { other_file: "" },

  );
  const [phpbLoaded, setphpbLoaded] = useState(false);
  return (
    <>
      {/* <FormSectionAccordion
        title={t("forms.step7.Profile Pictures")}
        completed={PROPS_DATA?.candidature?.documents?.length === 6}
        expanded={'panel1'}
      >
        <div className='row row-cols-2 p-2 '>
          <FileUploader url={`/candidatures/candidates/${PROPS_DATA?.candidate_id}/step-seven`} label={t("forms.step7.pic1")} name={"phbp"} documents={PROPS_DATA?.candidature?.documents} />
          <FileUploader url={`/candidatures/candidates/${PROPS_DATA?.candidate_id}/step-seven`} label={t("forms.step7.pic1")} name={"phcv"} documents={PROPS_DATA?.candidature?.documents} />
          <FileUploader url={`/candidatures/candidates/${PROPS_DATA?.candidate_id}/step-seven`} label={t("forms.step7.pic1")} name={"partner1_cv"} documents={PROPS_DATA?.candidature?.documents} />
        </div>
      </FormSectionAccordion> */}

      <FormSectionAccordion
        title={t("forms.step7.Documents")}
        completed={PROPS_DATA?.candidature?.documents?.find((d) => d?.name === 'phbp') ? true : false}
        expanded={"panel1"}
      >
        <Alert severity="warning">
          About files :  <small>Max : {FILE_MAX_SIZE} Mb</small>; Accpet : [images, pdf, word, excel, document formats...]
        </Alert>
        <div className="row row-cols-1 row-cols-sm-2 p-2 ">
          <FileUploader
            url={`/candidatures/candidates/${PROPS_DATA?.candidate_id}/step-seven`}
            label={t("forms.step7.Business plan") + t("forms.Optional")}
            name={"phbp"}
            documents={PROPS_DATA?.candidature?.documents}
            candidate_id={PROPS_DATA?.candidate_id}
            // required={true}
            errors={errors}
            setphpbLoaded={setphpbLoaded}
          />
          <FileUploader
            url={`/candidatures/candidates/${PROPS_DATA?.candidate_id}/step-seven`}
            label={t("forms.step7.Project holder cv") + t("forms.Optional")}
            name={"phcv"}
            documents={PROPS_DATA?.candidature?.documents}
            candidate_id={PROPS_DATA?.candidate_id}
            errors={errors}

          />
          <FileUploader
            url={`/candidatures/candidates/${PROPS_DATA?.candidate_id}/step-seven`}
            label={t("forms.step7.Partner 1 cv") + t("forms.Optional")}
            name={"partner1_cv"}
            documents={PROPS_DATA?.candidature?.documents}
            candidate_id={PROPS_DATA?.candidate_id}
            errors={errors}

          />
          <FileUploader
            url={`/candidatures/candidates/${PROPS_DATA?.candidate_id}/step-seven`}
            label={t("forms.step7.Partner 2 cv") + t("forms.Optional")}
            name={"partner2_cv"}
            documents={PROPS_DATA?.candidature?.documents}
            candidate_id={PROPS_DATA?.candidate_id}
          />
          <FileUploader
            url={`/candidatures/candidates/${PROPS_DATA?.candidate_id}/step-seven`}
            label={t("forms.step7.Partner 3 cv") + t("forms.Optional")}
            name={"partner3_cv"}
            documents={PROPS_DATA?.candidature?.documents}
            candidate_id={PROPS_DATA?.candidate_id}
            errors={errors}

          />
          <FileUploader
            url={`/candidatures/candidates/${PROPS_DATA?.candidate_id}/step-seven`}
            label={t("forms.step7.Other document") + t("forms.Optional")}
            name={"other_file"}
            documents={PROPS_DATA?.candidature?.documents}
            candidate_id={PROPS_DATA?.candidate_id}
            errors={errors}

          />
        </div>
      </FormSectionAccordion>
      <div className="d-flex justify-content-between mt-5 ">
        <Button
          text={t("forms.Previous")}
          // disabled={PROPS_DATA?.candidature?.step8 === 1}
          className={"btn-danger me-2"}
          handleClick={handlePrevious}
          icon={<ArrowBack />}
        />
        <Button
          text={t("forms.Continue")}

          handleClick={async () => {


            if (PROPS_DATA?.candidature?.documents?.find((d) => d?.name === 'phbp') || phpbLoaded) {
              PROPS_DATA?.fetchDataCandidature();
              handleNext();
            } else {
              // alert("Please provide the business plan")
              // seterrors({phbp : {message : "Please provide the business plan"}})
              await postAxiosData()
              PROPS_DATA?.fetchDataCandidature();
              handleNext();

            }
          }}
          disabled={PROPS_DATA?.candidature?.step8 === 1}
          className={"btn-primary"}
          icon={<ArrowForward />}
          arrowEnd={true}
        />
      </div>
    </>
  );
};

export default Documents;
