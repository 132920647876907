import { useState, useEffect } from 'react';

export default function useCheckFormSection(data, keys) {

    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        if (data !== null && data !== undefined && keys !== undefined && keys !== null) {
            let res = true;
            keys && Object.values(keys).map((key) => {

                if (data[key] === null || data[key] === undefined || data[key] === '') {
                    return res = false
                }
            })
            setCompleted(res);
        }


    }, [data, keys]);

    return completed;
}