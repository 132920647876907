

export const COLORS = [
  "#FFB8B8",
  "#46cdcf",
  "#6498d5",
  "#ff3232",
  "#2b415b",
  "#cce6ff",
  "#0080FF",
  "#52A952",
  "#7A5CFA",
  "#1F1F1F",
];


export const FILE_MAX_SIZE = 5;

