import React, { useEffect, useState } from "react";
import Footer from "../../layouts/Footer";
import Navbar from "../../layouts/Navbar";
import TabApply from "../../components/TabApply";
import ProjectLeader from "./steps/2_ProjectLeader";
import ProjectSommary from "./steps/1_ProjectSommary";
import ProjectStudy from "./steps/3_ProjectStudy";
import MarketAnalysis from "./steps/4_MarketAnalysis";
import InvestmentsAndFinancing from "./steps/5_InvestmentsAndFinancing";
import Coaching from "./steps/6_Coaching";
import Documents from "./steps/7_Documents";
import SubmitApplication from "./steps/8_SubmitApplication";
import DashBoardNavbar from "../../layouts/DasboardNavBar";
import useDataFetching from "../../hooks/useDataFetching";
import PreloaderSpinner from "../../components/PreloaderSpinner";
import axios from "axios";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { t } from "i18next";
import ChooseLanguage from "../../components/ChooseLanguage";
import { toogleMenu } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import DialogNewCandidature from "../../components/DialogNewCandidature";
import { PageContext } from "../../App";

const Apply = () => {
  const auth = useAuthUser()

  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()
  if (isAuthenticated()) {
    // auth()?.user?.candidate
    // console.log("🚀 ~ file: App.js:497 ~ useEffect ~ candidate:", auth()?.user?.candidate)
    if (auth()?.user?.candidate === undefined || auth()?.user?.candidate === null) {
      navigate('/login')
    }
  }


  const [loadingcad, errorcad, candidature, fetchDataCandidature] = useDataFetching(`candidates/${auth()?.user?.candidate?.id}/candidatures`)

  const [loadingRegion, errorRe, regions, fetchDataRegion] = useDataFetching('regions')
  const [loadingsectors, errorsectors, sectors, fetchDatasectors] = useDataFetching('sectors')
  const [loadingOcc, errorO, occupations, fetchDataOcc] = useDataFetching('occupations')
  const [loadingG, errorR, genders, fetchDataG] = useDataFetching('genders')
  const [loadingschoollevels, errorschoollevels, schoollevels, fetchDataschoollevels] = useDataFetching('schoollevels')
  const [loadingerrorstime_for_projects, errorstime_for_projects, time_for_projects, fetchDatastime_for_projects] = useDataFetching('time-for-projects')
  const [loadingstime_finish_projects, errorstime_finish_projects, time_finish_projects, fetchDatastime_finish_projects] = useDataFetching('time-finish-projects')

  // Step3
  const [loadinginnovations, errorinnovations, innovations, fetchDataGinnovations] = useDataFetching('innovations')
  const [loading_project_states, error_project_states, project_states, fetchData_project_states] = useDataFetching('project-states')
  const [loading_project_faisabilities, error_project_faisabilities, project_faisabilities, fetchData_project_faisabilities] = useDataFetching('project-faisabilities')
  const [loading_project_rds, error_project_rds, project_rds, fetchData_project_rds] = useDataFetching('project-rds')
  const [loading_project_juridiques, error_project_juridiques, project_juridiques, fetchData_project_juridiques] = useDataFetching('project-juridiques')
  const [loading_project_plans, error_project_plans, project_plans, fetchData_project_plans] = useDataFetching('project-plans')
  const [loading_project_maturations, error_project_maturations, project_maturations, fetchData_project_maturations] = useDataFetching('project-maturations')

  const [countries, setCountries] = useState(null)
  const fetchCountry = () => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        // setCountries(response.data);
        let countrie = [];
        response.data.forEach((data) => {
          countrie.push({ name: data.name.common, value: data.name.common });
        });
        setCountries(
          countrie.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    // alert('yes')
    fetchCountry();
  }, []);

  const PROPS_DATA = {
    regions,
    sectors,
    occupations,
    countries: countries,
    candidature: candidature,
    loadingcad: loadingcad,
    fetchDataCandidature: fetchDataCandidature,
    genders,
    schoollevels,
    time_for_projects,
    time_finish_projects,
    defaultNationality: 'Camerounais',

    innovations,

    project_states,
    project_faisabilities,
    project_rds,
    project_juridiques,
    project_plans,
    project_maturations,
    candidate_id: auth()?.user?.candidate?.id
  }

  const tabs = [
    {
      id: "ProjectSummary",
      title: t("dashboardsidebar.Project summary"),
      subtitle: t("dashboardsidebar.Project summary"),
      status: candidature?.step1,
      component: <ProjectSommary PROPS_DATA={PROPS_DATA} />
    },
    {
      status: candidature?.step2,
      id: "ProjectLeaders",
      title: t("dashboardsidebar.Project Carrier(s)"),
      subtitle: t("dashboardsidebar.Project Carrier(s)"),
      component: <ProjectLeader PROPS_DATA={PROPS_DATA} />
    },
    {
      status: candidature?.step3,
      id: "ProjectStudy",
      title: t("dashboardsidebar.Project study"),
      subtitle: t("dashboardsidebar.Project study"),
      component: <ProjectStudy PROPS_DATA={PROPS_DATA} />
    },
    {
      status: candidature?.step4,
      id: "MarketAnalysis",
      title: t("dashboardsidebar.Market analysis"),
      subtitle: t("dashboardsidebar.Market analysis"),
      component: <MarketAnalysis PROPS_DATA={PROPS_DATA} />
    },
    {
      status: candidature?.step5,

      id: "InvestmentsAndFinancing",
      title: t("dashboardsidebar.Investment and finances"),
      subtitle: t("dashboardsidebar.Investment and finances"),
      component: <InvestmentsAndFinancing PROPS_DATA={PROPS_DATA} />
    },
    {
      status: candidature?.step6,
      id: "Coaching",
      title: t("dashboardsidebar.Participation"),
      subtitle: t('dashboardsidebar.Participation'),
      component: <Coaching PROPS_DATA={PROPS_DATA} />
    },
    {
      status: candidature?.step7,

      id: "Documents",
      title: t("dashboardsidebar.Documents"),
      subtitle: t("dashboardsidebar.Documents"),
      component: <Documents PROPS_DATA={PROPS_DATA} />
    },
    {
      status: candidature?.step8,

      id: "SubmitApplication",
      title: t("dashboardsidebar.Submit"),
      subtitle: t("dashboardsidebar.Submit"),
      component: <SubmitApplication PROPS_DATA={PROPS_DATA} />
    },
  ]

  const [open, setOpen] = React.useState(false);
  const { activeEdition } = React.useContext(PageContext);

  const location = useLocation()
  useEffect(() => {
    if (location?.state?.newCandidature) {
      // alert("New created cadidature this edition")
      setOpen(true)
    }
  }, [location]);

  return (

    activeEdition?.can_push === 0 ?
      <div id="hero-1">
        <Navbar />
        <div className="container bg-soft rounded" >
          <div className="border rounded shadow d-flex flex-column justify-content-center p-3">
            <p className="text-center">  <i className="bi bi-exclamation-circle text-warning" style={{ fontSize: '5em' }} /></p>
            <h2 className="text-center text-warning my-4"> {t("dialogs.Pas de candidatures")} {activeEdition?.title}</h2>

            {/* <p className="text-center">
         {t("dialogs.Your application has been")} {activeEdition?.title}
         </p> */}
          </div>
        </div>
      </div>
      :

      (activeEdition && candidature?.validation === "1") ?
        <div id="hero-1">
          <Navbar />
          <div className="container bg-soft rounded">
            <div className="border rounded shadow d-flex flex-column justify-content-center p-3">
              <span className="text-center">  <i className="bi bi-check-circle text-success" style={{ fontSize: '5em' }} /></span>

              <h2 className="text-center text-success my-2"> {t("dialogs.New Created application")}</h2>

              <p className="text-center">
                {t("dialogs.Your application has been")} {activeEdition?.title}<br></br>

                {t("dialogs.Evaluating")}
              </p>
            </div>
          </div>
        </div>

        :
        <>
          <DialogNewCandidature open={open} setOpen={setOpen} />
          {(loadingcad
            // ||
            // loadingRegion ||
            // loadingOcc ||
            // loadingG ||
            // loadingschoollevels ||
            // loadingerrorstime_for_projects ||
            // loadingstime_finish_projects ||
            // loadinginnovations ||
            // loading_project_states ||
            // loading_project_faisabilities ||
            // loading_project_rds ||
            // loading_project_juridiques ||
            // loading_project_plans ||
            // loading_project_maturations
          )
            && <PreloaderSpinner />}
          {


            <div className="d-flex flex-column"
              style={{ height: '100dvh' }}

            >
              <DashBoardNavbar show={false} />


              <div className=" d-flex d-sm-none bg-danger">
                <span id="wsnavtoggle" className="wsanimated-arrow  mt-1 me-1" onClick={(e) => toogleMenu(e)}>
                  <span></span>
                </span>
                <ChooseLanguage />
              </div>
              <TabApply candidature={candidature} tabs={tabs} />
            </div>

          }
        </>
  );
};


export default Apply;
