import { t } from "i18next";
import React from "react";
import VideoPlayer from "../../components/LoghtBox";
import { Link } from "react-router-dom";

export const Starting = () => {
  return (
    <>
      <section id="banner-3" className="pt-100 banner-section">
        <div className="container">
          {/* <!-- BANNER-3 WRAPPER --> */}
          <div className="banner-3-wrapper bg--03 bg--scroll r-16">
            <div className="banner-overlay">
              <div className="row">
                {/* <!-- BANNER-3 TEXT --> */}
                <div className="col">
                  <div className="banner-3-txt color--white">
                    {/* <!-- Title --> */}
                    <h2 className="s-48 w-700">{t("What are you waiting for")}? </h2>

                    {/* <!-- Text --> */}
                    <p className="p-xl">
                      {t("It only takes a few clicks to get started")}
                    </p>

                    {/* <!-- Button --> */}
                    <div className="row">


                      <a href="/criteria" className="btn r-04 mb-3 rounded btn-white bg-white text-dark shadow fs-3">
                        <i className="bi bi-card-heading text-dark" />  {t("Dossier d'appel à candidature")}
                      </a>

                      <><Link to={'/docs/POSTULER.pdf'} target="_blank" className="btn r-04 mb-3 rounded btn-primary shadow fs-3 w-100">
                        <i className="bi-play-btn-fill" />  {t("Guide d'utilisation")}
                      </Link></>


                      <a href="/docs/REGLEMENT.pdf" target="_blank" className="btn r-04 mb-3 rounded bg-warning btn-warning  text-dark shadow fs-3">
                        <i className="bi bi-filetype-pdf  text-dark" />  {t("Voir le règlement")}
                      </a>
                      <a href="/criteria" className="btn r-04 mb-3 rounded btn-success shadow fs-3">
                        <i className="bi bi-plus-circle" />   {t("nav.Apply")}
                      </a>


                    </div>

                    {/* <!-- Button Text --> */}
                    {/* <p className="p-sm btn-txt ico-15">
                      <span className="flaticon-check"></span> Free for 14 days,
                      no credit card required.
                    </p> */}
                  </div>
                </div>
                {/* <!-- END BANNER-3 TEXT --> */}
              </div>
              {/* <!-- End row --> */}
            </div>
            {/* <!-- End banner overlay --> */}
          </div>
          {/* <!-- END BANNER-3 WRAPPER --> */}
        </div>
        {/* <!-- End container --> */}
      </section>

      <div id="brands-1" className="pt-80 pb-100 brands-section">
        <div className="container">
          {/* <!-- BRANDS TITLE --> */}
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="brands-title mb-50">
                <h5 className="s-30">
                  <span className="text-warning fw-bold me-2">30+</span>{" "}
                  <span className="text-dark fw-bold">Partners</span>
                </h5>
              </div>
            </div>
          </div>


          <img className="w-100" src="assets/images/evaluators/partners.jpeg" />
        </div>
        {/* <!-- End container --> */}
      </div>
    </>
  );
};
