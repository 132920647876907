import React, { useState } from "react";
import { useSignIn } from "react-auth-kit";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "../../hooks/usePostAxiosData";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PreloaderSpinner from "../../components/PreloaderSpinner";
import useCustomSignin from "../../hooks/useCunstomSigin";
import ResendCode from "./ResendCode";
import Navbar from "../../layouts/Navbar";
import Button from "../../components/Button";
import { t } from "i18next";
import DialogHelper from "./DialogHelper";
import { Alert } from "@mui/material";

const VerifyEmail = () => {
  const navigate = useNavigate();
  /** Form validation config */
  const schema = yup.object().shape({
    email: yup.string().required("Email is a required field"),
    code: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    handleLogin(data);
  };
  /** End validation config */

  /** handle signIn */
  // const signIn = useSignIn();
  const customSignin = useCustomSignin()
  function handleSignin(data) {
    customSignin(data?.data, "/criteria")

  }

  /** post verify */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`users/verify`, "POST");



  const handleLogin = async (dataYub) => {
    const data = {
      email: dataYub?.email,
      code: dataYub?.code,
    };

    await postAxiosData(data);
  };

  React.useEffect(() => { }, [errorMassage]);
  React.useEffect(() => {
    if (successMessage?.status == 200 || successMessage?.status == 201) {
      toast.success(`${successMessage?.message} `, {
        duration: 4000,
        icon: "👏",
        className: "bg-success text-white",
      });
      handleSignin(result);
    }
  }, [successMessage]);
  const [open, setOpen] = useState(true)


  return (
    <>
      {loading ? (
        <PreloaderSpinner />
      ) : (
        <>
          <DialogHelper open={open} setOpen={setOpen} />
          <Navbar className={'bg-primary'} />
          <div id="login" className="bg--scroll login-section division auth">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-11">
                  <div className="register-page-wrapper r-16 bg--fixed">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="register-page-txt color--white">
                          <h2 className="s-42 w-700">Welcome</h2>
                          <h2 className="s-42 w-700">back to APME</h2>

                          {/* <p className="p-md mt-25">Integer congue sagittis and velna augue egestas magna 
											   suscipit purus aliquam
											</p> */}

                          <div className="register-page-copyright">
                            <p className="p-sm">
                              {" "}
                              Copyright &copy; APME 2023.{" "}
                              <span>All Rights Reserved</span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="d-flex justify-content-center">
                          <img
                            className="img-fluid"
                            style={{ maxHeight: "90px" }}
                            src="/logo.png"
                            alt="logo-image"
                          />
                        </div>

                        <span
                          className="d-flex justify-content-center mt-3 mb-0 fw-bold text-dark"
                          style={{ fontSize: "2rem" }}
                        >
                          {t("forms.Verify your email account")}
                        </span>
                        <Alert severity="info"> {t("dialogs.Bonjour, merci")} </Alert>

                        <div className="register-page-form">
                          <form
                            className="row sign-in-form"
                            onSubmit={handleSubmit(onSubmitHandler)}
                          >
                            {/* <div className="col-md-12">
													<a  href="#" className="btn btn-google ico-left">
														<img src="/assets/images/png_icons/google.png" alt="google-icon" /> Sign in with Google
													</a>
												</div>  

												<div className="col-md-12 text-center">	
													<div className="separator-line">Or, sign in with your email</div>
												</div> */}
                            {errorMassage?.message && (
                              <span
                                class="alert alert-danger d-flex align-items-center p-1 w-100"
                                role="alert"
                              >
                                <span>
                                  {errorMassage?.message != "" &&
                                    errorMassage?.message}
                                </span>
                              </span>
                            )}
                            <div className="col-md-12">
                              <p className="p-sm input-header">{t("dialogs.Email address")}</p>
                              <input
                                {...register(`email`)}
                                className={`form-control  m-0 eamil ${errors.email?.message && `border-danger`
                                  }`}
                                type="email"
                                name="email"
                                id="email"
                                placeholder="example@example.com"
                              />
                              <p className="text-danger p-0 ">
                                {errors.email?.message !== ""
                                  ? errors.email?.message
                                  : ""}
                              </p>
                            </div>


                            <div className="col-md-12">
                              <p className="p-sm input-header">Code</p>
                              <input
                                {...register(`code`)}
                                className={`form-control  m-0 code ${errors.code?.message && `border-danger`
                                  }`}
                                type="number"
                                name="code"
                                id="code"
                                placeholder="code"
                              />
                              <p className="text-danger p-0 ">
                                {errors.code?.message !== ""
                                  ? errors.code?.message
                                  : ""}
                              </p>
                            </div>




                            <div className="d-flex justify-content-center mt-4">

                              <Button text={t('forms.verify')}
                                className={'btn-primary'}
                              />
                            </div>

                            <div className="col-md-12">
                              <p className="create-account text-center"
                                role="button"

                              >
                                <ResendCode />
                              </p>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VerifyEmail;
