import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../components/Input";
import { Alert, AlertTitle, Divider } from "@mui/material";
import usePostAxiosData from "../../../hooks/usePostAxiosData";
import useCheckFormSection from "../../../hooks/useCheckFormSection";
import FormSectionAccordion from "../../../components/form/FormSection";
import Button from "../../../components/Button";
import PreloaderSpinner from "../../../components/PreloaderSpinner";
import { t } from "i18next";
import { PageContext } from "../../../App";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const InvestmentsAndFinancing = ({ PROPS_DATA }) => {
  /** Form validation config */
  const schema = yup.object().shape({
    financialneeds: yup
      .string()
      .required("Financial Needs is a required field"),
    preliminarfees: yup
      .string()
      .required("Preliminary Costs is a required field"),
    ressources: yup
      .string()
      .required("Future Customers is a required field"),
    ressourcesneeds: yup
      .string()
      .required("Financial Resources is a required field"),
    personalapport: yup
      .string()
      .required("Resources needs is a required field"),
    othersapport: yup
      .string()
      .required("Generate Revenue is a required field"),
    dept: yup
      .string()
      .required("Personal Contribution is a required field"),
    autofinance: yup
      .string()
      .required("Pther Equity Contributions is a required field"),
    subvention: yup.string().required("Credit/Borrow is a required field"),
    lovemoney: yup.string().required("Self-Financing is a required field"),
    othersfinance: yup
      .string()
      .required("Public/private subsidy is a required field"),
    managetwoyear: yup.string().required("Love money is a required field"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = (data) => {
    handleSave(data);
  };
  const [loading, postAxiosData, errorPostMassage, successPostMessage, result] =
    usePostAxiosData(`candidatures/candidates/${PROPS_DATA?.candidate_id}/step-five`, "POST");

  const handleSave = async (data) => {
    const toSave = {
      ...data

    }
    console.log("🚀 ~ file: 2_ProjectLeader.js:98 ~ handleSave ~ toSave:", toSave)
    // console.log('tosave => ',toSave)
    await postAxiosData(toSave);

  };
  const {activeTab, setactiveTab, handleNext, handlePrevious} = useContext(PageContext);

  useEffect(() => {
    if (successPostMessage.status === 200 || successPostMessage.status === 201) {
      PROPS_DATA?.fetchDataCandidature()
      handleNext()
    }
  }, [successPostMessage])

  const description = [
    "financialneeds",
    "preliminarfees",
    "ressources",
    "ressourcesneeds",
    "managetwoyear",
  ]

  const plan = [
    "personalapport",
    "othersapport",
    "dept",
    "autofinance",
    "subvention",
    "lovemoney",
    "othersfinance",
  ]

  const completed1 = useCheckFormSection(PROPS_DATA?.candidature, description)
  const completed2 = useCheckFormSection(PROPS_DATA?.candidature, plan)
  const [building, setBuilding] = useState(null)
  useEffect(() => {
    if (PROPS_DATA?.candidature) {
      setBuilding(true)
      description.map((key) => {
        const val = PROPS_DATA?.candidature[key]
        // Yup set value
        setValue(key, val)
        // States setValue

      })
      plan.map((key) => {
        const val = PROPS_DATA?.candidature[key]
        // Yup set value
        setValue(key, val)
        // States setValue

      })
      setTimeout(() => {

        setBuilding(false)
      }, 1000);

    }
  }, [PROPS_DATA?.candidature])

  return (
    loading || building === true ? <PreloaderSpinner /> :

      <>
        <form className="mt-4" onSubmit={handleSubmit(onSubmitHandler)}>
        {
            errorPostMassage && <Alert className="my-2 mb-3 rounded shadow  " severity="error">< AlertTitle> {errorPostMassage?.message}</AlertTitle></Alert>
          }
          <Alert className="shadow rounded" severity="info" >
            <span className="text-decoration-underline">{t("forms.Objective")} :</span> {t("forms.step5.Assess the estimated")}
          </Alert>
          <FormSectionAccordion expanded={'panel1'} completed={completed1}
            title={''}>

            <Input
              register={register}
              error={errors.financialneeds?.message}
              className={errors.financialneeds?.message && `border-danger`}
              label={t("forms.step5.What are your financial")}
              placeholder={t("forms.step5.What are your financial")}
              isRequired={true}
              name={"financialneeds"}
              id={"financialneeds"}
              type={"textarea"}
              cols={20}
              rows={3}
            />
            <Input
              register={register}
              error={errors.preliminarfees?.message}
              className={errors.preliminarfees?.message && `border-danger`}
              label={
                t("forms.step5.What are the preliminary")
              }
              placeholder={
                t("forms.step5.What are the preliminary")
              }
              isRequired={true}
              name={"preliminarfees"}
              id={"preliminarfees"}
              type={"textarea"}
              cols={20}
              rows={3}
            />
            <Input
              register={register}
              error={errors.ressources?.message}
              className={errors.ressources?.message && `border-danger`}
              label={
                t("forms.step5.What are your financial resources?")
              }
              placeholder={
                t("forms.step5.What are your financial resources?")
              }
              isRequired={true}
              name={"ressources"}
              id={"ressources"}
              type={"textarea"}
              cols={20}
              rows={3}
            />
            <Input
              register={register}
              error={errors.ressourcesneeds?.message}
              className={errors.ressourcesneeds?.message && `border-danger`}
              label={
                t("forms.step5.What are your needs in financial")
              }
              placeholder={
                t("forms.step5.What are your needs in financial")
              }
              isRequired={true}
              name={"ressourcesneeds"}
              id={"ressourcesneeds"}
              type={"textarea"}
              cols={20}
              rows={3}
            />
            <Input
              register={register}
              error={errors.managetwoyear?.message}
              className={errors.managetwoyear?.message && `border-danger`}
              label={
                t("forms.step5.How do you expect to generate")
              }
              placeholder={
                t("forms.step5.How do you expect to generate")
              }
              isRequired={true}
              name={"managetwoyear"}
              id={"managetwoyear"}
              type={"textarea"}
              cols={20}
              rows={3}
            />
          </FormSectionAccordion>
          <FormSectionAccordion completed={completed2}
            title={t('forms.step5.Financing plan (sources of financing)')}>

            <Alert className="shadow rounded" severity="info" >

              <p className="text-start ">
                <span className="text-decoration-underline">NB:</span> 
                {t("forms.step5.Just present the actual")}
              </p>
            </Alert>

            <Input
              register={register}
              isRequired={true}
              error={errors.personalapport?.message}
              className={
                errors.personalapport?.message && `border-danger`
              }
              label={t("forms.step5.Personal contribution")}
              // placeholder={"Enter name"}
              name={"personalapport"}
              id={"personalapport"}
              type={"text"}
            />
            <Input
              register={register}
              isRequired={true}
              error={errors.othersapport?.message}
              className={
                errors.othersapport?.message && `border-danger`
              }
              label={t("forms.step5.Other equity contributions")}
              // placeholder={"Enter name"}
              name={"othersapport"}
              id={"othersapport"}
              type={"text"}
            />
            <Input
              register={register}
              isRequired={true}
              error={errors.dept?.message}
              className={errors.dept?.message && `border-danger`}
              label={t("forms.step5.Credit/Borrow")}
              // placeholder={"Enter name"}
              name={"dept"}
              id={"dept"}
              type={"number"}
            />
            <Input
              register={register}
              isRequired={true}
              error={errors.autofinance?.message}
              className={errors.autofinance?.message && `border-danger`}
              label={t("forms.step5.self-financing")}
              // placeholder={"Enter name"}
              name={"autofinance"}
              id={"autofinance"}
              type={"number"}
            />
            <Input
              register={register}
              isRequired={true}
              error={errors.subvention?.message}
              className={errors.subvention?.message && `border-danger`}
              label={t("forms.step5.Public/private subsidy")}
              // placeholder={"Enter name"}
              name={"subvention"}
              id={"subvention"}
              type={"text"}
            />
            <Input
              register={register}
              isRequired={true}
              error={errors.lovemoney?.message}
              className={errors.lovemoney?.message && `border-danger`}
              label={t("forms.step5.Love Money")}
              // placeholder={"Enter name"}
              name={"lovemoney"}
              id={"lovemoney"}
              type={"text"}
            />
            <Input
              register={register}
              isRequired={true}
              error={errors.othersfinance?.message}
              className={errors.othersfinance?.message && `border-danger`}
              label={t("forms.step5.Others")}
              // placeholder={"Enter name"}
              name={"othersfinance"}
              id={"othersfinance"}
              type={"text"}
            />
          </FormSectionAccordion>
          <div className="d-flex justify-content-between mt-5 " aria-haspopup="true">
            
          <Button text={t('forms.Previous')}
              // disabled={PROPS_DATA?.candidature?.step8 === 1}
              className={'btn-danger me-2'}
              handleClick={handlePrevious}
              icon={<ArrowBack />}

            />
            <Button text={t('forms.Save And Continue')}

              disabled={PROPS_DATA?.candidature?.step8 === 1}
              className={'btn-primary'}

              icon={<ArrowForward />}
              arrowEnd={true}
            />
          </div>
        </form>
      </>
  );
};

export default InvestmentsAndFinancing;
