import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import usePostAxiosData from '../../hooks/usePostAxiosData';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useCustomSignin from '../../hooks/useCunstomSigin';
import { toast } from 'react-hot-toast';
import PreloaderSpinner from '../../components/PreloaderSpinner';
import { t } from 'i18next';

export default function ResendCode() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [loadingR, postAxiosDataResend, errorMassageR, successMessage, resultResend] =
        usePostAxiosData(`users/resend-code`, "POST");

    const schema = yup.object().shape({
        email: yup.string().required("Email is a required field"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = (data) => {
        postAxiosDataResend(data);
    };
    /** End validation config */

    /** handle signIn */
    // const signIn = useSignIn();
    React.useEffect(() => { }, [errorMassageR]);
    React.useEffect(() => {
        if (successMessage?.status == 200 || successMessage?.status == 201) {
            toast.success(`${successMessage?.message} `, {
                duration: 4000,
                icon: "👏",
                className: "bg-success text-white",
            });
            handleClose();
        }
    }, [successMessage]);


    return (
        loadingR ? (
            <PreloaderSpinner />
          ) : <>
            <Button variant="outlined" onClick={handleClickOpen}>
                {t("dialogs.Resend code")}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <DialogTitle>{t("dialogs.Send code")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        {t("dialogs.Nous vous enverrons un code")}
                        </DialogContentText>
                        <div className="col-md-12 my-3">
                            <p className="p-sm input-header">{t("forms.Email address")}</p>
                            <input
                                {...register(`email`)}
                                className={`form-control  m-0 eamil ${errors.email?.message && `border-danger`
                                    }`}
                                type="email"
                                name="email"
                                id="email"
                                placeholder="example@example.com"
                            />
                            <p className="text-danger p-0 ">
                                {errors.email?.message !== ""
                                    ? errors.email?.message
                                    : ""}
                            </p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {console.log(errors)}
                        <button
                            className=" btn bg-danger text-white"
                            onClick={(e) => { e.preventDefault(); handleClose() }}
                        >
                            {t("Cancel")}
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primarysubmit"
                        >
                            {t("Send")}
                        </button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
