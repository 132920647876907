import { t } from "i18next";
import Navbar from "../layouts/Navbar";
import { Link } from "react-router-dom";
import useDataFetching from "../hooks/useDataFetching";
import PreloaderSpinner from "../components/PreloaderSpinner";

export default function Preselect() {
    const [loadingG, errorR, preselecteds, fetchDataG] = useDataFetching('preselecteds')

    return (
        <>
        {loadingG && <PreloaderSpinner/>}
            <Navbar show={false} />
            <section id="blog-page" className="pb-60 inner-page-hero blog-page-section">
                <div className="container">

                    <h2 className="bg-primary text-white text-center rounded shadow py-2 mb-3">{t("nav.Présélectionnés")}</h2>
                    {/* <p>
                        {t("result.header")}
                    </p> */}
                    <div className="d-flex flex-row justify-content-center mb-3">
                        <Link to={'/docs/result2024.pdf'} target="_blank"
                            className="text-warning border border-warning rounded shadow p-2 me-2 mt-2">
                            {t('Download EN')}
                            <i className="bi bi-file-pdf ms-2" />
                        </Link>
                        <Link to={'/docs/french.pdf'} target="_blank"
                            className="text-warning border border-warning rounded shadow p-2 me-2 mt-2">
                            {t('Download FR')}
                            <i className="bi bi-file-pdf ms-2" />
                        </Link>


                    </div>
                    <table class="table table-striped ">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{t("forms.step2.Full name")}</th>
                                <th scope="col">{t("forms.step1.Project title")}</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {preselecteds?.sort((a, b) => a.ph_name.localeCompare(b.ph_name))?.map((p, index) => {
                                return <tr>
                                    <th scope="row">{index+1}</th>
                                    <td>{p?.ph_name}</td>
                                    <td>{p?.title_project}</td>
                                </tr>
                            })}

                        </tbody>
                    </table>

                 
                    {/* <p>
                        {t("result.footer1")}
                    </p> */}
                    {/* <p>
                        {t("result.footer2")}
                    </p> */}
                    {/* <p>
                        {t("result.contact")} : 679 91 85 33 / 656 56 99 10 / 655 37 39 98 / 672 88 10 88
                    </p> */}
                </div>
            </section>

        </>
    )
}