
import { t } from "i18next";
import React, { useContext, useState } from "react";
import { useIsAuthenticated, useSignOut } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ChooseLanguage from "../components/ChooseLanguage";
import { PageContext } from "../App";
import { MenuItem, Select } from "@mui/material";

const Navbar = ({ show, className, bg }) => {
  const isAuthenticated = useIsAuthenticated();
  const { i18n } = useTranslation();
  const signOut = useSignOut();

  const { activeEdition } = useContext(PageContext);
  return (
    <header id="header" className={show === false ? 'hover-text-none' : `tra-menu navbar-light white-scroll ${bg || ''}`}>
      <span className="text-center fixed-top top-0 text-warning " style={{ zIndex: "999999" }}>
        {t("Edition")} {activeEdition?.title}
      </span>
      <div className="header-wrapper
     
      text-dark">
        {/* <div className="header-img">

        </div> */}
        {/* <img src="/assets/images/apmelogo22.png" alt="apme logo"/> */}

        {/* <!-- MOBILE HEADER --> */}
        <div className="wsmobileheader clearfix d-flex justify-content-end "

        >
          <span className="smllogo d-sm-none  ms-auto me-2 ">
            <a id="wsnavtoggle"

              onClick={(e) => {
                e.preventDefault();
                document.getElementById('body').classList.toggle('wsactive')
                // document.getElementById('logo-mo').style.display="none"
                document.getElementsByClassName('wsanimated-arrow')[0].classList.toggle("wsanimated-arrow-right")
              }}

              className="wsanimated-arrow">
              <span></span>
            </a>
            <img
              id="logo-mo"
              src="/logo.png"
              alt="mobile-logo"
            />
          </span>

        </div>
        {/* <!-- NAVIGATION MENU --> */}
        <div className={` ${className || 'wsmainfull'} menu clearfix `}>
          <div className="wsmainwp clearfix d-flex flex-nowrap align-items-center justify-content-between"

            onClick={(e) => {
              document.getElementById('body').classList.toggle('wsactive')
              // document.getElementById('logo-mo').style.display="none"
              document.getElementsByClassName('wsanimated-arrow')[0].classList.toggle("wsanimated-arrow-right")
            }}

          >
            {/* <!-- HEADER BLACK LOGO --> */}
            <div className="desktoplogo">
              <a href="/" className="logo-black">
                <img
                  className="light-theme-img"
                  src="/logo.png"
                  alt="logo"
                />
                <img
                  className="dark-theme-img"
                  src="/logo.png"
                  alt="logo"
                />
              </a>
            </div>

            {/* <!-- HEADER WHITE LOGO --> */}
            <div className="desktoplogo">
              <a href="/" className="logo-white">
                {show != false && (
                  <img
                    src="/logo.png"
                    alt="logo"
                  />
                )}
              </a>
            </div>
            <nav className="wsmenu clearfix mt-sm-3 w-100 d-flex">
              <ul className="wsmenu-list nav-theme d-flex flex-column flex-sm-row justify-content-start justify-content-sm-center">
                {/* <!-- DROPDOWN SUB MENU --> */}

                {/* <!-- SIMPLE NAVIGATION LINK --> */}
                <li className="nl-simple" aria-haspopup="true">
                  <a href="/" className="h-link">
                    {t("nav.Home")}
                  </a>
                </li>
                <li className="nl-simple" aria-haspopup="true"
                   onMouseOver={()=>{document.getElementById("res")?.classList?.add('show')}} 
                   onMouseLeave={()=>{document.getElementById("res")?.classList?.remove('show')}} 
                >
                  {/* <a href="/result" className="h-link">
                    {t("nav.Results")}
                  </a> */}

                  {/* <ChooseLanguage text_color={show === false ? 'text-dark' : 'text-white'} /> */}
                    <a onClick={(e)=>{e?.preventDefault(); e?.stopPropagation()}} 
                 
                    
                    className="h-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {t("nav.Results")} {activeEdition?.title}
                    </a>

                    <ul id="res" class="dropdown-menu">
                      {/* <li><a  class="dropdown-item" href="/result">{t("nav.Selection finale")}</a></li> */}
                      <li><a  class="dropdown-item" href="/preselected">{t("nav.Présélectionnés")}</a></li>
                    </ul>

                </li>
                {<li className="nl-simple" aria-haspopup="true">
                  <a href="/criteria" className="h-link">
                    {t("nav.Apply")}
                  </a>
                </li>}
                <li className="nl-simple" aria-haspopup="true">
                  <a href="/success-stories" className="h-link">
                    {t("nav.Success Stories")}
                  </a>
                </li>
                {<li className="nl-simple" aria-haspopup="true">
                  <a href="/evaluators" className="h-link">
                    {t("nav.Evaluator")}
                  </a>
                </li>}



                {!isAuthenticated() ?
                  <>
                    <li
                      className="nl-simple reg-fst-link mobile-last-link"
                      aria-haspopup="true"
                    >
                      <a href="login" className="h-link">
                        {t("nav.Sign in")}
                      </a>
                    </li>

                    {/* <!-- SIGN UP BUTTON --> */}
                    <li className="nl-simple" aria-haspopup="true">
                      <a
                        href="/register"
                        className="btn r-04 btn-success last-link"
                      >
                        {t("nav.Sign up")}
                      </a>
                    </li>
                  </> :
                  <>
                    <li className="nl-simple" aria-haspopup="true">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          signOut()

                        }}
                        href="/login"
                        className="btn r-04 btn-success last-link"
                      >
                        {t("nav.logOut")}
                      </a>
                    </li>
                  </>
                }
                <li className="nl-simple" aria-haspopup="true">
                  <ChooseLanguage text_color={show === false ? 'text-dark' : 'text-white'} />
                </li>
              </ul>

            </nav>
          </div>

        </div>
      </div>
    </header>
  );
};

export default Navbar;
