import { t } from "i18next";
import Navbar from "../layouts/Navbar";
import { Link } from "react-router-dom";

export default function Result() {

    return (
        <>
            <Navbar show={false} />
            <section id="blog-page" className="pb-60 inner-page-hero blog-page-section">
                <div className="container">

                    <h2 className="bg-primary text-white text-center rounded shadow py-2 mb-3">{t("nav.Selection finale")}</h2>
                    <p>
                        {t("defMessage1")}
                    </p>
                    <p>
                        {t("defMessage2")}
                    </p>
                    <p>
                        {t("defMessage3")}
                    <Link to={"www.fonds-proto-apne.cm"} className="text-primary text-decoration-underline ms-2">www.fonds-proto-apne.cm</Link>
                    </p>
                    <p>
                        {t("defMessage4")}

                    </p>

                    <div className="d-flex flex-row justify-content-center shadow py-5 flex-wrap">
                        <Link to={'/docs/Communique_en_francais.pdf'} target="_blank" 
                            className="text-warning border border-warning rounded shadow p-2 me-2 mt-2">
                            {t('resultatFr')}
                            <i className="bi bi-file-pdf ms-2" />
                        </Link>
                        <Link to={'/docs/Communique_en_anglais.pdf'} target="_blank" 
                            className="text-warning border border-warning rounded shadow p-2 mt-2">
                            {t('resultatEn')}
                            <i className="bi bi-file-pdf ms-2" />
                        </Link>

                    </div>

                </div>
            </section>

        </>
    )
}