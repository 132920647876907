import { FormControl, InputLabel, MenuItem, Select, Skeleton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function MSelect(
  { handleChange, value, items, label, isRequired, disabled }
) {

  const { i18n } = useTranslation();

  return (<>
    {(items || value) ? <div className="col">
      <FormControl className="my-2" required={isRequired} variant="standard" fullWidth sx={{ minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={value}
          onChange={handleChange}
          // label={label}
          label={<>{label} {isRequired && <span className="css-wgai2y-MuiFormLabel-asterisk">*</span>}</>}

          fullWidth
          disabled={disabled}

          required={isRequired}

        >
          <MenuItem value="">
            <em>...</em>
          </MenuItem>

          {items?.map((item) => {
            return (
              <MenuItem value={item?.id || item?.value}>{i18n?.language === 'fr' ? (item?.name_fr || item?.name) : (item?.name || item?.name_fr)}</MenuItem>
            )
          })}

        </Select>
      </FormControl>
    </div> : <div className="my-2"><InputLabel id="demo-simple-select-standard-label">{label}</InputLabel><Skeleton height={50} /></div>}
  </>)
}