export default function CriteriaCard({text,icon}) {



    return (
        <>
            <div className="col p-1 wow fadeInRight card-criteria ">

                <div class=" shadow content p-2 d-flex flex-column align-items-center align-items-sm-start flex-sm-row position-relative">
                    {icon}


                    <p class=" col-12 col-sm-10 text-muted text-start ms-2">{text}</p>
                </div>
            </div>
        </>
    )
}