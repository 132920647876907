import { FormControl, FormControlLabel, Radio } from "@mui/material";

export default function RadioButton({ label, value, handlChange, checked, defaultValue, required }) {

  return (
    <>
      <FormControl className="my-2" required={true} variant="standard" fullWidth >
    
      <FormControlLabel
        value={value}
        control={<Radio size={"small"} />}
        label={label}
        name=""
        onChange={handlChange}
        className="text-muted mb-3"
      />
      </FormControl>
    </>
  )
}