import React, { useContext } from "react";
import Navbar from "./Navbar";
import MCarousel from "../components/Carousel";
import { t } from "i18next";
import { PageContext } from "../App";
import useDataFetching from "../hooks/useDataFetching";
import CardStory from "../pages/stories/CardStoriy";
import VideoPlayer from "../components/LoghtBox";
import { Link } from "react-router-dom";
// import { succesStories } from "../pages/content/SuccessStories";
const Header = () => {
  const { activeEdition } = useContext(PageContext);
  // const [loadinginnovations, errorinnovations, stories, fetchDataGinnovations] = useDataFetching('stories')
 
  const header = [{
    content:
      <section id="hero-1" className="bg--scroll hero-section">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div className="hero-1-txt color--white wow fadeInRight">
                <h2 className="s-58 w-700">
                  {t("header.CALL FOR PROJECTS")} {activeEdition?.title}
                </h2>

                {/* <p className="p-xl">Official site</p> */}

                <a href="/criteria" className="btn r-04 btn-success">
                  {t('nav.Apply')}
                </a>

                <Link to={'/docs/POSTULER.pdf'} target="_blank" className="btn r-04 btn-success" style={{marginLeft: 2}}>
                  {t('Comment postuler ?')}
                </Link>
                {/* <p className="p-sm btn-txt ico-15">
              <span className="flaticon-check"></span>{t("header.No credit")}
            </p> */}
              </div>
            </div>

            <div className="col-md-6">
              <div className="hero-1-img wow fadeInLeft">
                <img
                  className="img-fluid"
                  src="/assets/images/img-10.png"
                  alt="hero-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    ,
  }]
  const succesStories = [
    {
      content:
        <section id="hero-1" className="bg--scroll hero-section">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-md-6">
                <div className="hero-1-txt color--white wow fadeInRight">
                  <h2 className="s-58 w-700">
                    {t('nav.Success Stories')} {activeEdition?.title}
                  </h2>

                  {/* <p className="p-xl">Official site</p> */}

                  <a href="/success-stories" className="btn r-04 btn-success">
                    {t('nav.Success Stories')}
                  </a>
                  {/* <p className="p-sm btn-txt ico-15">
              <span className="flaticon-check"></span>{t("header.No credit")}
            </p> */}
                </div>
              </div>

              <div className="col-md-6">
                <div className="hero-1-img wow fadeInLeft">
                  <img
                    className="img-fluid"
                    src="/assets/images/img-13.png"
                    alt="hero-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
    }
  ]
  const sections = [...header, ...succesStories]
  // const sections = [ ...header]
  return (
    <>
      {/* // <!-- HEADER ============================================= --> */}

      <Navbar />

      {/* <!-- HERO-1 ============================================= --> */}
      <MCarousel sections={sections} />


      {/* <!-- END HERO-1 --> */}
    </>
  );
};

export default Header;
