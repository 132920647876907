import React, { useContext, useEffect, useState } from "react";
import Input from "../../../components/Input";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "../../../hooks/usePostAxiosData";
import useCheckFormSection from "../../../hooks/useCheckFormSection";
import { Alert, AlertTitle } from "@mui/material";
import CardForm from "../../../components/form/card";
import FormSectionAccordion from "../../../components/form/FormSection";
import Button from "../../../components/Button";
import PreloaderSpinner from "../../../components/PreloaderSpinner";
import { t } from "i18next";
import { PageContext } from "../../../App";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const MarketAnalysis = ({ PROPS_DATA }) => {
  /** Form validation config */
  const schema = yup.object().shape({
    market: yup
      .string()
      .required("Market Description is a required field"),
    marketsegment: yup.string().required("Market Segment is a required field"),
    clients: yup
      .string()
      .required("Future Customers is a required field"),
    competitors: yup.string().required("Concurence is a required field"),
    constraints: yup.string().required("Constraints is a required field"),
    partners: yup.string().required("Partnerships is a required field"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = (data) => {
    handleSave(data);
  };
  const [loading, postAxiosData, errorPostMassage, successPostMessage, result] =
    usePostAxiosData(`candidatures/candidates/${PROPS_DATA?.candidate_id}/step-four`, "POST");

  const handleSave = async (data) => {
    const toSave = {
      ...data

    }
    console.log("🚀 ~ file: 2_ProjectLeader.js:98 ~ handleSave ~ toSave:", toSave)
    // console.log('tosave => ',toSave)
    await postAxiosData(toSave);

  };
  const {activeTab, setactiveTab, handleNext, handlePrevious} = useContext(PageContext);

  useEffect(() => {
    if (successPostMessage.status === 200 || successPostMessage.status === 201) {
      PROPS_DATA?.fetchDataCandidature()
      handleNext()
    }
  }, [successPostMessage])

  const description = [
    "market",
    "marketsegment",
    "clients",
    "competitors",
    "constraints",
    "partners",
  ]
  const completed1 = useCheckFormSection(PROPS_DATA?.candidature, description)
  const [building, setBuilding] = useState(null)
  useEffect(() => {
    if (PROPS_DATA?.candidature) {
      setBuilding(true)
      description.map((key) => {
        const val = PROPS_DATA?.candidature[key]
        // Yup set value
        setValue(key, val)
        // States setValue

      })
      setTimeout(() => {

        setBuilding(false)
      }, 1000);

    }
  }, [PROPS_DATA?.candidature])

  return (
    loading || building === true ? <PreloaderSpinner /> :

      <>
        <form onSubmit={handleSubmit(onSubmitHandler)}>

        {
            errorPostMassage && <Alert className="my-2 mb-3 rounded shadow  " severity="error">< AlertTitle> {errorPostMassage?.message}</AlertTitle></Alert>
          }
          <Alert className="shadow rounded" severity="info" >

            <span className="text-decoration-underline">{t("forms.Objective")}:</span> {t("forms.step4.Check the existence of a need")}
          </Alert>
          <FormSectionAccordion
            title={t("Instructions")}

            type="info"
          >
            <div className="row row-cols-1 row-cols-sm-3 my-2 mx-2 justify-content-center">
             
            <CardForm text={t("forms.step3.Be concise and precise")} />
              <CardForm text={t("forms.step3.Complete all sections")} />
            </div>
          </FormSectionAccordion>
          <FormSectionAccordion expanded={'panel1'} completed={completed1}
            title={t('dashboardsidebar.Market analysis')}>

            <div className="row row row-cols-1 row-cols-sm-2 ">
              <div class="col">
                <Input
                  register={register}
                  error={errors.market?.message}
                  className={errors.market?.message && `border-danger`}
                  label={t("forms.step4.Describe your market")}
                  placeholder={"Describe your market"}
                  isRequired={true}
                  name={"market"}
                  id={"market"}
                  type={"textarea"}
                  cols={20}
                  rows={3}
                />
              </div>
              <div class="col">
                <Input
                  register={register}
                  error={errors.marketsegment?.message}
                  className={errors.marketsegment?.message && `border-danger`}
                  label={
                    t("forms.step4.Describe the market segment")
                  }
                  placeholder={
                    t("forms.step4.Describe the market segment")
                  }
                  isRequired={true}
                  name={"marketsegment"}
                  id={"marketsegment"}
                  type={"textarea"}
                  cols={20}
                  rows={3}
                />
              </div>
              <div class="col">
                <Input
                  register={register}
                  error={errors.clients?.message}
                  className={errors.clients?.message && `border-danger`}
                  label={
                    t("forms.step4.Who are your future")
                  }
                  placeholder={
                    t("forms.step4.Who are your future")
                  }
                  isRequired={true}
                  name={"clients"}
                  id={"clients"}
                  type={"textarea"}
                  cols={20}
                  rows={3}
                />
              </div>
              <div class="col">
                <Input
                  register={register}
                  error={errors.competitors?.message}
                  className={errors.competitors?.message && `border-danger`}
                  label={
                    t("forms.step4.Describe the competition")
                  }
                  placeholder={
                    t("forms.step4.Describe the competition")
                  }
                  isRequired={true}
                  name={"competitors"}
                  id={"competitors"}
                  type={"textarea"}
                  cols={20}
                  rows={3}
                />
              </div>
              <div class="col">
                <Input
                  register={register}
                  error={errors.constraints?.message}
                  className={errors.constraints?.message && `border-danger`}
                  label={
                    t("forms.step4.Are there any constraints")
                  }
                  placeholder={
                    t("forms.step4.Are there any constraints")
                  }
                  isRequired={true}
                  name={"constraints"}
                  id={"constraints"}
                  type={"textarea"}
                  cols={20}
                  rows={3}
                />
              </div>
              <div class="col">
                <Input
                  register={register}
                  error={errors.partners?.message}
                  className={errors.partners?.message && `border-danger`}
                  label={

                    t("forms.step4.Who are your suppliers")
                  }
                  placeholder={
                    t("forms.step4.Who are your suppliers")
                  }
                  isRequired={true}
                  name={"partners"}
                  id={"partners"}
                  type={"textarea"}
                  cols={20}
                  rows={3}
                />
              </div>
            </div>
          </FormSectionAccordion>

          <div className="d-flex justify-content-between mt-5 " aria-haspopup="true">

          <Button text={t('forms.Previous')}
              // disabled={PROPS_DATA?.candidature?.step8 === 1}
              className={'btn-danger me-2'}
              handleClick={handlePrevious}
              icon={<ArrowBack />}

            />
            <Button text={t('forms.Save And Continue')}

              disabled={PROPS_DATA?.candidature?.step8 === 1}
              className={'btn-primary'}
              icon={<ArrowForward />}
              arrowEnd={true}

            />
          </div>
        </form>
      </>
  );
};

export default MarketAnalysis;
