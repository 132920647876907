import { t } from "i18next";
import React from "react";
import { FallingLines, Grid } from "react-loader-spinner";

const PreloaderSpinner = () => {
  return (
    <>
     <div className="d-flex flex-column justify-content-center" >

      <div  style={{ position: 'fixed', zIndex: "99999", left: 0, top: 0, width: '100dvw', height: '100vh',
       backgroundColor: "rgba(0,0,0,0.2)",
       backdropFilter : 'blur(2px)',
       opacity: 1 }}></div>
      <Grid
        height="180"
        width="180"
        color="#3565ae"
        radius="7"
        wrapperStyle={{ position: 'fixed', zIndex: "99999", left: 0, top: 0, width: '100vw', height: '100vh' }}
        wrapperClass="d-flex align-items-center justify-content-center"
        visible={true}
        ariaLabel="grid-loading"
        />
        <p className="fixed-bottom w-100 text-center" style={{zIndex : '999992'}}>{t('Loading')}...</p>
        </div>
    </>
  );
};

export default PreloaderSpinner;
