import { LanguageRounded, Logout, SignLanguageRounded, TranslateRounded } from "@mui/icons-material";
import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { useAuthUser, useIsAuthenticated, useSignOut } from "react-auth-kit";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import ChooseLanguage from "../components/ChooseLanguage";
import { toogleMenu } from "../utils";
import { PageContext } from "../App";

const DashBoardNavbar = ({ show }) => {
  const { i18n } = useTranslation();

  const location = useLocation();


  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const signOut = useSignOut();

  const handleLogout = () => {
    //call the logout action
    // localStorage.removeItem("selectedCentre");
    signOut();
    navigate("/login");
    //navigate to logout page on logout
  };
  const { activeEdition } = useContext(PageContext);

  return (
    <div className="header-wrapper-dashboard border-bottom  bg-primary d-none d-sm-block  " >
      {/* <!-- MOBILE HEADER --> */}
      <div className="wsmobileheader clearfix">
        <span className="smllogo">
          <img
            src="/logo.png"
            alt="mobile-logo"
          />
        </span>
        <a id="wsnavtoggle" className="wsanimated-arrow" onClick={(e) => toogleMenu(e)}>
          <span></span>
        </a>
      </div>
      {/* <!-- NAVIGATION MENU --> */}
      <div className=" menu clearfix ">
        <div className=" clearfix d-flex align-items-center justify-content-around  mx-2">
          {/* <!-- HEADER BLACK LOGO --> */}
          <div>

            <div className="desktoplogo">
              <a href="/" className="logo-black">
                <img
                  className="light-theme-img"
                  src="/logo.png"
                  alt="logo"
                />
                <img
                  className="dark-theme-img"
                  src="/logo.png"
                  alt="logo"
                />
              </a>
            </div>

            {/* <!-- HEADER WHITE LOGO --> */}
            <div className="desktoplogo">
              <a href="/" className="logo-white">
                {show != false && (
                  <img
                    src="/logo.png"
                    alt="logo"
                  />
                )}
              </a>
            </div>
          </div>
          <nav className="wsmenu     w-100 d-flex justify-content-end align-items-center   ">
            <ul className="text-white nav-theme d-flex justify-content-end ">


              {/* <!-- SIMPLE NAVIGATION LINK --> */}
              <li className="nl-simple me-4 " aria-haspopup="true">
                <span className="h-link text-white">
                  {/* Home */}
                  Edition  {activeEdition?.title}
                </span>
              </li>
              <li className="nl-simple me-4 " aria-haspopup="true">
                <a href="/" className="h-link text-white">
                  {/* Home */}
                  {t('nav.Home')}
                </a>
              </li>

              {/* <li className="nl-simple me-4" aria-haspopup="true">
                <a href="/apply" className="h-link text-white">
                  Show Previous
                </a>
              </li>

              <li className="nl-simple me-4" aria-haspopup="true">
                <a href="/" className="h-link text-white">
                  Info
                </a>
              </li> */}





            </ul>
            <div className="lang">
              <ChooseLanguage text_color={'text-white'} />
            </div>


            <div className="profile">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings " className="p-0">
                  <IconButton
                    className="shadow-sm"
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar
                      className="bg-white text-primary "
                      sx={{
                        width: 50,
                        height: 50,
                      }}
                    >
                      <span className="fw-bold  mt-md-1">
                        {auth()?.user?.name[0]
                          || 'F'}
                      </span>
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <Link to={"#"} className="text-dark">
                  <MenuItem onClick={() => navigate("/dashboard/profile")}>
                    <Avatar sx={{ ml: "1" }} /> Profile
                  </MenuItem>
                </Link>
                <Divider className="my-1 bg-dark fw-bold" />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  {/* Deconnexion */}
                  {t("nav.logOut")}
                </MenuItem>
              </Menu>
            </div>

          </nav>
        </div>
      </div>
    </div>
  );
};

export default DashBoardNavbar;
