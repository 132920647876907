import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { PageContext } from '../App';
import { t } from 'i18next';

export default function SucccessCandidature({open, setOpen , fetchDataCandidature}) {

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    fetchDataCandidature()
  };
  const {activeEdition} = React.useContext(PageContext);

  return (
    <div>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("dialogs.New Created application")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {t("dialogs.Your application has been")} {activeEdition?.title}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            {t("dialogs.Got it")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
