import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Divider, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SectionTitle from "../../../components/form/SectionTitle";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../components/Input";
import Select from "../../../components/form/Select";
import MSelect from "../../../components/form/Select";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormSectionAccordion from "../../../components/form/FormSection";
import axios from "axios";
import { config } from "../../../constants/config";
import usePostAxiosData from "../../../hooks/usePostAxiosData";
import PreloaderSpinner from "../../../components/PreloaderSpinner";
import useCheckFormSection from "../../../hooks/useCheckFormSection";
import Button from "../../../components/Button";
import { t } from "i18next";
import { PageContext } from "../../../App";
import { ArrowForward } from "@mui/icons-material";
import useDataFetching from "../../../hooks/useDataFetching";

const ProjectSommary = ({ PROPS_DATA }) => {
  
  const schema = yup.object().shape({
    title_project: yup
      .string().required(),
    resume: yup
      .string().required(),
    ph_cni: yup
      .string().required(),

    ph_name: yup
      .string().required(),

    ph_phone: yup.string().required(),
    paddress: yup.string().required(),
    ph_father_name: yup.string().required(),
    mother_name: yup.string(),

  });
  const [occupation_id, setoccupation_id] = useState(null)
  const [p_sector_id, setp_sector_id] = useState(null)
  const [p_sub_sector_id, setp_sub_sector_id] = useState(null)
  const [ph_pays, setph_pays] = useState(null)
  const [ph_residence_region_id, setph_residence_region_id] = useState(null)
  const [pregion, setPregion] = useState(null)
  const [ph_father_region_id, setph_father_region_id] = useState(null)
  const [ph_mother_region_id, setph_mother_region_id] = useState(null)
  const [ph_dob, setph_dob] = useState(null)
  const [ph_nationality, setph_nationality] = useState('Camerounais')
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmitHandler = (data) => {
    handleSave(data);
  };


  const [loading, postAxiosData, errorPostMassage, successPostMessage, result] =
    usePostAxiosData(`candidatures/candidates/${PROPS_DATA?.candidate_id}/step-one`, "POST");


  const handleSave = async (data) => {
    const toSave = {
      ...data,
      occupation_id,
      ph_pays,
      ph_residence_region_id,
      pregion,
      ph_father_region_id,
      ph_mother_region_id,
      ph_nationality,
      ph_dob,
      p_sector_id,
      p_sub_sector_id
    }
    // console.log('tosave => ',toSave)
    await postAxiosData(toSave);

  };
  const { activeTab, setactiveTab, handleNext, handlePrevious } = useContext(PageContext);

  useEffect(() => {
    if (successPostMessage.status === 200 || successPostMessage.status === 201) {
      PROPS_DATA?.fetchDataCandidature()
      handleNext()
    }

  }, [successPostMessage])



  const [building, setBuilding] = useState(null)

  const projetInfoFilds = [
    "title_project",
    "resume",
    "ph_name",
    "occupation_id",
    "ph_nationality",
    "ph_cni",
    "ph_pays",
    "ph_residence_region_id",
    "p_sector_id",
// "p_sub_sector_id"
  ]

  const DobFields = [
    "ph_dob",
  ]

  const locationFields = [
    "pregion",
    "ph_phone",
    "paddress",
  ]


  const aboutParentFields = [
    "ph_father_name",
    "mother_name",
    "ph_father_region_id",
    "ph_mother_region_id",
  ]
  const completed1 = useCheckFormSection(PROPS_DATA?.candidature, projetInfoFilds)
  const completed2 = useCheckFormSection(PROPS_DATA?.candidature, DobFields)
  const completed3 = useCheckFormSection(PROPS_DATA?.candidature, locationFields)
  const completed4 = useCheckFormSection(PROPS_DATA?.candidature, aboutParentFields)
  // const [sub_sectors, setSub_sectors] = useState(null);
  const [loadingsub_sectors, errorRe, sub_sectors, fetchDatasub_sectors] = useDataFetching('sectors/' + p_sector_id + '/sub-sectors', 'no')

  useEffect(() => {
    if (p_sector_id) {
      fetchDatasub_sectors()
    }
  }, [p_sector_id]);
  useEffect(() => {
    if (PROPS_DATA?.candidature) {
      setBuilding(true)
      const all = [...projetInfoFilds, ...DobFields, ...locationFields, ...aboutParentFields]
      all.map((key) => {
        const val = PROPS_DATA?.candidature[key]
        setValue(key, val)
        if (key === "occupation_id") setoccupation_id(val)
        if (key === "ph_pays") setph_pays(val)
        if (key === "ph_residence_region_id") setph_residence_region_id(val)
        if (key === "pregion") setPregion(val)
        if (key === "ph_father_region_id") setph_father_region_id(val)
        if (key === "ph_mother_region_id") setph_mother_region_id(val)
        if (key === "ph_dob") setph_dob(val)
        if (key === "p_sector_id") setp_sector_id(val)
        if (key === "p_sub_sector_id") setp_sub_sector_id(val)
      })

      setTimeout(() => {
        setBuilding(false)

      }, 1000);
    }
  }, [PROPS_DATA?.candidature])

  return (


    (building || loading) ? <PreloaderSpinner /> :
      <>
        <form onSubmit={handleSubmit(onSubmitHandler)} className="px-2">

          {
            errorPostMassage && <Alert className="my-2 mb-3 rounded shadow  " severity="error">< AlertTitle> {errorPostMassage?.message}</AlertTitle></Alert>
          }
          <FormSectionAccordion expanded={'panel1'} completed={completed1} title={t("forms.step1.Projet information")}>

            <Input
              register={register}
              error={errors.title_project?.message}
              className={errors.title_project?.message && `border-danger`}
              label={t("forms.step1.Project title")}
              placeholder={t("forms.step1.Project title")}
              isRequired={true}
              name={"title_project"}
              id={"title_project"}
              type={"text"}

            />

            <Input
              register={register}
              error={errors.resume?.message}
              className={errors.resume?.message && `border-danger`}
              label={t("forms.step1.Summary of business idea")}
              placeholder={t("forms.step1.Summary of business idea")}
              isRequired={true}
              name={"resume"}
              id={"resume"}
              type={"textarea"}
              rows={3}

            />
            <div className="row row-cols-1 row-cols-sm-2">
              <Input
                register={register}
                error={errors.ph_name?.message}
                className={errors.ph_name?.message && `border-danger`}
                label={t("forms.step1.Project holder name")}
                placeholder={t("forms.step1.Project holder name")}
                isRequired={true}
                name={"ph_name"}
                id={"ph_name"}
                type={"text"}

              />


              <MSelect
                label={t('forms.step1.Current activity/occupation')}
                items={PROPS_DATA?.occupations}
                handleChange={(e) => setoccupation_id(e.target.value)}
                value={occupation_id}
                isRequired={true}

              />


              <Input
                register={register}
                error={errors.ph_nationality?.message}
                className={errors.ph_nationality?.message && `border-danger`}
                label={t("forms.step1.Nationality")}
                placeholder={t("forms.step1.Nationality")}
                isRequired={true}
                name={"ph_nationality"}
                id={"ph_nationality"}
                type={"text"}
                disabled={true}
                value={ph_nationality}
              />

              <Input
                register={register}
                error={errors.ph_cni?.message}
                className={errors.ph_cni?.message && `border-danger`}
                label={t("forms.step1.National ID")}
                placeholder={t("forms.step1.National ID")}
                isRequired={true}
                name={"ph_cni"}
                id={"ph_cni"}
                type={"text"}


              />


              <MSelect
                label={t("forms.step1.Country of permanent residence")}
                items={PROPS_DATA?.countries}
                handleChange={(e) => setph_pays(e.target.value)}
                isRequired={true}
                value={ph_pays}
              />


              <MSelect
                label={t("forms.step1.Region of residence in Cameroon")}
                items={PROPS_DATA?.regions}
                handleChange={(e) => setph_residence_region_id(e.target.value)}
                value={ph_residence_region_id}
                isRequired={true}

              />

              <MSelect
                label={t("forms.step1.Activity Sector")}
                items={PROPS_DATA?.sectors}
                handleChange={(e) => setp_sector_id(e.target.value)}
                value={p_sector_id}
                isRequired={true}
              />

              <MSelect
                label={t("forms.step1.Sub-Sector Activity")}
                // items={sub_sectors}
                items={PROPS_DATA?.sectors}
                handleChange={(e) => setp_sub_sector_id(e.target.value)}
                value={p_sub_sector_id}
                // isRequired={true}
                disabled={p_sector_id ? false : true}
              />
            </div>
          </FormSectionAccordion>


          <FormSectionAccordion title={t("forms.step1.Date of birth")} completed={completed2}>

            <Input
              // register={register}
              onChange={(e) => setph_dob(e?.$d)}
              error={errors.ph_dob?.message}
              className={errors.ph_dob?.message && `border-danger`}
              label={t("forms.step1.Date of birth")}
              placeholder={t("forms.step1.Date of birth")}
              isRequired={true}
              name={"ph_dob"}
              id={"ph_dob"}
              type={"date"}
              value={ph_dob}

            />
          </FormSectionAccordion>


          <FormSectionAccordion completed={completed3} title={t("forms.step1.Location of the project in Cameroon")}>

            <div className="row row-cols-1 row-cols-sm-2">

              <MSelect
                label={t("forms.step1.Region of installation")}
                items={PROPS_DATA?.regions}
                value={pregion}
                handleChange={(e) => setPregion(e.target.value)}

                isRequired={true}
              />

              <Input
                register={register}
                error={errors.ph_phone?.message}
                className={errors.ph_phone?.message && `border-danger`}
                label={t("forms.step1.Phone number")}
                placeholder={t("forms.step1.Phone number")}
                isRequired={true}
                name={"ph_phone"}
                id={"ph_phone"}
                type={"text"}

              />



              <Input
                register={register}
                error={errors.paddress?.message}
                className={errors.paddress?.message && `border-danger`}
                label={t("forms.step1.Address")}
                placeholder={t("forms.step1.Address")}
                isRequired={true}
                name={"paddress"}
                id={"paddress"}
                type={"text"}

              />

            </div>
          </FormSectionAccordion>

          <FormSectionAccordion completed={completed4} title={t("forms.step1.About your parents")}>
            <div className="row row-cols-1 row-cols-sm-2">

              <Input

                register={register}
                error={errors.ph_father_name?.message}
                className={errors.ph_father_name?.message && `border-danger`}
                label={t("forms.step1.Father's name")}
                placeholder={t("forms.step1.Father's name")}
                isRequired={true}
                name={"ph_father_name"}
                id={"ph_father_name"}
                type={"text"}
              />

              <MSelect
                label={t('forms.step1.Father region')}
                items={PROPS_DATA?.regions}
                isRequired={true}
                value={ph_father_region_id}
                handleChange={(e) => setph_father_region_id(e.target.value)}
              />

              <Input

                register={register}
                error={errors.mother_name?.message}
                className={errors.mother_name?.message && `border-danger`}
                label={t("forms.step1.Mother's name")}
                placeholder={t("forms.step1.Mother's name")}
                isRequired={true}
                name={"mother_name"}
                id={"mother_name"}
                type={"text"}
              />
              <MSelect
                label={t('forms.step1.Mother region')}
                items={PROPS_DATA?.regions}
                value={ph_mother_region_id}
                handleChange={(e) => setph_mother_region_id(e.target.value)}
                isRequired={true}

              />
            </div>

          </FormSectionAccordion>

          <div className="d-flex justify-content-end mt-5 ">

            <Button text={t('forms.Save And Continue')}
              disabled={PROPS_DATA?.candidature?.step8 === 1}
              className={'btn-primary'}
              icon={<ArrowForward />}
              arrowEnd={true}
            />
          </div>

        </form>
      </>
  );
};

export default ProjectSommary;
