export default function Button({arrowEnd, text,disabled ,icon, className, handleClick}) {
    return(
        <>
          <button
          onClick={handleClick}
              type="submit"
              className={`btn r-04 btn-primary text-center shadow-sm   p-2 p-sm-3 d-flex flex-nowrap justify-content-center align-items-center  ${className}`}
              name="step1"
              disabled={disabled}
            >
                {!arrowEnd ? icon : ''}
              {text}
              {arrowEnd ? icon : ''}

            </button>
        </>
    )
}