import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function MCarousel({ sections }) {

    return (
        <>
            <Carousel
            autoPlay={true}
            showThumbs={false}
            interval={6000}
            infiniteLoop={true}
            showIndicators={false}
            swipeable={false}
            
            >
                {

                    sections?.map((s) => {
                        return <>
                        {s?.content}
                        </>
                    })
                }

            
            </Carousel>
        </>
    )
}