import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "../../hooks/usePostAxiosData";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import PreloaderSpinner from "../../components/PreloaderSpinner";
import Navbar from "../../layouts/Navbar";
import Button from "../../components/Button";
import { t } from "i18next";
import { useRestrictCopyPaste } from "../../hooks/useRestrictCopyPaste";

const Register = () => {
  const navigate = useNavigate();
  /** Form validation config */
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const schema = yup.object().shape({
    name: yup.string().required("Full name is a required field"),
    // phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
    phone: yup.string().required("Phone is a required field"),
    email: yup
      .string()
      .email("Invalid Email address.")
      .required("Email is a required field"),
    emailConfirmation: yup
      .string()
      .email("Invalid Email address.")
      .oneOf([yup.ref("email"), null], "Emails must match"),
    password: yup.string().required("Password is a required field"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    handleSignUp(data);
  };
  /** End validation config */

  /** post registration */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`users`, "POST");

  const handleSignUp = async (dataYub) => {
    console.log("data yub", errors);
    // const data = {
    //   name: dataYub?.name,
    //   email: dataYub?.email,
    //   password: dataYub?.password,
    //   confirmPassword: dataYub?.confirmPassword,
    // };
    console.log("data t registration after submitted by yub", dataYub);

    await postAxiosData(dataYub);
  };

  React.useEffect(() => { }, [errorMassage]);
  React.useEffect(() => {
    if (successMessage?.status == 200 || successMessage?.status == 201) {
      toast.success(`${successMessage?.message} `, {
        duration: 4000,
        icon: "👏",
        className: "bg-success text-white",
      });
      navigate("/verify-email");
    }
  }, [successMessage]);

  /** End post regisration */

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = (e) => {
    e.preventDefault();
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };
  const [showCPassword, setShowCPassword] = useState(false);
  const handleShowCPassword = (e) => {
    e.preventDefault();
    showCPassword ? setShowCPassword(false) : setShowCPassword(true);
  };

  useRestrictCopyPaste({ window, actions: ["copy", "cut", "paste"] });
  return loading ? (
    <PreloaderSpinner />
  ) : (
    <div id="signup">
      <Navbar className={""} />

      <div className="bg--scroll login-section division auth">
        <div className="container">
          <div className="col bg-light border rounded">
            <div className="d-flex justify-content-center w-100">
              {/* <img
                className="img-fluid me-auto"
                style={{ maxHeight: "90px" }}
                src="/logo.png"
                alt="logo-image"
              /> */}
              <span className="d-flex justify-content-center mt-3 mb-0 fw-bold text-dark ms-2 h2  flex-fill  ">
                {t("nav.Sign up")}
              </span>
            </div>
            <div className="register-page-form">
              <form
                className=" sign-up-form"
                onSubmit={handleSubmit(onSubmitHandler)}
              >
                <div className="row row-cols-1 row-cols-sm-2">
                  {/* <div className="col">
                          <a href="#" className="btn btn-google ico-left">
                            <img
                              src="images/png_icons/google.png"
                              alt="google-icon"
                            />{" "}
                            Sign up with Google
                          </a>
                        </div> */}

                  {/* <div className="col text-center">
                          <div className="separator-line">
                            Or, sign up with your email
                          </div> errorMassage?.message != "" &&
                              `${errorMassage?.message}`
                        </div> */}
                  {errorMassage?.message && !errorMassage?.already_registered  && (
                    <span
                      class="alert alert-danger d-flex align-items-center p-1 w-100"
                      role="alert"
                    >
                      <span>
                        {errorMassage?.message != "" && errorMassage?.message}
                      </span>
                    </span>
                  )}
                    {/* {console.log("🚀 ~ file: Register.js:147 ~ Register ~ errorMassage:", errorMassage)} */}
                  {errorMassage?.already_registered && (
                    <span
                      class="alert alert-danger d-flex align-items-center p-2  shadow  text-center w-100"
                      role="alert"
                    >
                      <i className="bi bi-exclamation-circle me-2 fs-5"/>
                        {/* {errorMassage?.already_registered != "" && errorMassage?.already_registered} */}
                        {t('forms.Already have an accountP')} : 
                        <Link className="text-primary ms-2 rounded " to={'/login'}>{t("nav.Sign in")}</Link>
                    </span>
                  )}
                  <div className="col">
                    <p className="p-sm input-header">{t("forms.step2.Full name")}</p>
                    <input
                      {...register(`name`)}
                      className={`form-control  m-0 name ${errors.name?.message && `border-danger`
                        }`}
                      type="text"
                      name="name"
                      id="name"
                      placeholder="John Doe"
                    />
                    <p className="text-danger p-0 ">
                      {errors.name?.message != "" ? errors.name?.message : ""}
                    </p>
                  </div>

                  <div className="col">
                    <p className="p-sm input-header">{t("forms.step1.Phone number")}</p>
                    <input
                      {...register(`phone`)}
                      className={`form-control  m-0 name ${errors.name?.message && `border-danger`
                        }`}
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="+237 xxx xxx xxx"
                    />
                    <p className="text-danger p-0 ">
                      {errors.phone?.message != "" ? errors.phone?.message : ""}
                    </p>
                  </div>

                  <div className="col">
                    <p className="p-sm input-header">{t("forms.Email address")}</p>
                    <input
                      {...register(`email`)}
                      className={`form-control  m-0 email ${errors.email?.message && `border-danger`
                        }`}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="example@example.com"
                    />
                    <p className="text-danger p-0 ">
                      {errors.email?.message != "" ? errors.email?.message : ""}
                    </p>
                  </div>
                  <div className="col">
                    <p className="p-sm input-header">
                      {t("forms.Confirm An Email address")}
                    </p>
                    <input
                      {...register(`emailConfirmation`)}
                      className={`form-control  m-0 email ${errors.emailConfirmation?.message && `border-danger`
                        }`}
                      type="email"
                      name="emailConfirmation"
                      id="emailConfirmation"
                      placeholder="example@example.com"
                    />
                    <p className="text-danger p-0 ">
                      {errors.emailConfirmation?.message != ""
                        ? errors.emailConfirmation?.message
                        : ""}
                    </p>
                  </div>

                  <div className="col">
                    <p className="p-sm input-header">{t("forms.Password")}</p>
                    <div className="wrap-input">
                      <span
                        onClick={(e) => handleShowPassword(e)}
                        className="btn-show-pass ico-20"
                      >
                        <span
                          className={`${showPassword
                              ? "flaticon-invisible"
                              : "flaticon-visibility "
                            } eye-pass`}
                        ></span>
                      </span>
                      <input
                        {...register(`password`)}
                        className={`form-control  m-0 password ${errors.password?.message && `border-danger`
                          }`}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="password"
                      />
                    </div>
                    <p className="text-danger p-0 ">
                      {errors.password?.message != ""
                        ? errors.password?.message
                        : ""}
                    </p>
                  </div>
                  <div className="col">
                    <p className="p-sm input-header">{t("forms.Confirm Password")}</p>
                    <div className="wrap-input">
                      <span
                        onClick={(e) => handleShowCPassword(e)}
                        className="btn-show-pass ico-20"
                      >
                        <span
                          className={`${showCPassword
                              ? "flaticon-invisible"
                              : "flaticon-visibility "
                            } eye-pass`}
                        ></span>
                      </span>
                      <input
                        {...register(`confirmPassword`)}
                        className={`form-control  m-0 password ${errors.confirmPassword?.message && `border-danger`
                          }`}
                        type={showCPassword ? "text" : "password"}
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="password"
                      />
                    </div>
                    <p className="text-danger p-0 ">
                      {errors.confirmPassword?.message != ""
                        ? errors.confirmPassword?.message
                        : ""}
                    </p>
                  </div>
                </div>

                <div className="col">
                  <div className="form-data">
                    <span>
                      {t("forms.By clicking")}
                      <a href="#">{t("forms.Terms")}</a> {t("forms.and that you have read our")}
                      <a href="#">{t("forms.Privacy Policy")}</a>
                    </span>
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-4">
                  {/* <button
                    type="submit"
                    className="btn btn-primary submit w-25"
                  >
                    Sign Up
                  </button> */}
                  <Button text={t("nav.Sign up")} className={"btn-primary"} />
                </div>

                <div className="col">
                  <p className="create-account text-center">
                    {t("forms.Already have an account")}?{" "}
                    <a href="/login" className="color--theme">
                      {t("nav.Sign in")}
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>

          {/* <div className="col-md-6">
                  <div className="register-page-txt color--white">
                    <span className="section-id">
                      First Stape for postulate
                    </span>

                    <h2 className="s-48 w-700">Create</h2>
                    <h2 className="s-48 w-700">an account</h2>

                    <div className="register-page-copyright">
                      <p className="p-sm">
                        Copyright &copy; APME 2023.{" "}
                        <span>All Rights Reserved</span>
                      </p>
                    </div>
                  </div>
                </div> */}
        </div>
      </div>
    </div>
  );
};

export default Register;
