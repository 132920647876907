import { ArrowBackIos, ArrowLeft, ArrowRightAlt, ArrowRightAltOutlined, ArrowRightAltRounded, CheckBoxRounded, HourglassFullRounded, HourglassTopRounded, ListAltRounded, MenuBookRounded } from "@mui/icons-material";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { toogleMenu } from "../utils";
import { Box, LinearProgress } from "@mui/material";
import CircularWithValueLabel from "./form/CircularProgress";
import { PageContext } from "../App";

const TabApply = ({ tabs, candidature }) => {
  // function toogleMenu() {
  //   var element = document.getElementById("navwrapper");
  //   element.classList.toggle("show");
  // } 
  // const [activeTab, setactiveTab] = useState(0);
  const { activeTab, setactiveTab } = useContext(PageContext);
  const [ratio, setratio] = useState(0);
  useEffect(() => {
    let res = 0;
    for (let index = 0; index < tabs?.length; index++) {
      if (candidature && candidature['step' + index] === 1) {
        res += 1
      }
    }

    setratio((res/tabs?.length)*100)

  }, [candidature]);
  return (
    <div className="d-flex flex-fill bg-light  position-relative  " >


      <nav id="navwrapper" className="wrapper-nav d-flex justify-content-between " onClick={(e) => toogleMenu(e)}>
        {/* <i className="bi bi-list h1"/> */}
        <div className="side-bar nav nav-tabs  text-start d-flex flex-column  " id="nav-tab" role="tablist" >
          {tabs &&
            tabs.map((tab, index) => {
              return (
                <>
                  <button
                    className={`
                    ${tab?.status === 1 ? "text-success shadow-success" : " text-muted "}
                    nav-link text-start border bg-white rounded shadow-sm my-1  
                  ${index === activeTab ? "active " : " "}
                  
                  
                  text-capitalyse fw-bold`}
                    style={{ fontSize: "15px" }}
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab?.id}`}
                    type="button"
                    role="tab"
                    aria-controls={`${tab?.id}`}
                    aria-selected="true"

                    onClick={() => setactiveTab(index)}
                  >
                    {tab?.status === 1 ? <CheckBoxRounded className="text-success me-1 "
                    /> :
                      <HourglassTopRounded className="me-1" />
                    }
                    {index + 1}. {tab?.title}

                  </button>

                </>
              );
            })}
        </div>
        <ArrowBackIos className=" fs-1 d-flex d-sm-none" onClick={toogleMenu} />

      </nav>
      <div className="tab-content  justify-content-center  w-100" id="nav-tabContent">
        {tabs &&
          tabs.map((tab, index) => {
            return (
              <div
                className={`p-2 tab-pane container fade show ${index === activeTab && "active"} `}
                id={`${tab?.id}`}
                role="tabpanel"
                aria-labelledby="nav-home-tab"
                tabindex="0"
                style={{ overflow: 'auto', height: '90dvh' }}
              >
          {/* <div className="alert alert-danger shadow h5 text-center"><i className="bi bi-exclamation-circle text-danger me-2 fs-3 "/>{t('Applications close Monday, September 25 at 11:59 p.m.')}</div> */}

                <div className="my-2 d-flex flex-column-reverse flex-sm-row  align-items-center justify-content-center">
                  <h4
                    className="text-center "
                  // style={{ color: "rgb(54, 102, 175)" }}
                  >
                    <strong> {t("Step")} {index + 1}/<span className="text-primary">{tabs?.length}</span> : </strong>
                    <span className="ms-2">

                      {tab?.subtitle}
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress aria-label="yes" variant="determinate" value={ratio} />
                      </Box>
                    </span>

                  </h4>
                  <div className="ms-sm-auto">
                    <CircularWithValueLabel value={ratio} />
                  </div>
                </div>
                {tab?.component}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TabApply;
