import React, { useEffect } from "react";
import PreloaderSpinner from "../components/PreloaderSpinner";
import Header from "../layouts/Header";
import CustomerCentric from "../pages/content/CustomerCentric";
import Engagement from "./content/Engagement";
import SmartSolution from "./content/SmartSolution";
import ProgressTowards from "./content/ProgressTowards";
import CompleteSolutions from "./content/CompleteSolutions";
import Productivity from "./content/Productivity";
import AmazingClients from "./content/AmazingClients";
import { Starting } from "./content/Starting";
import Register from "./auth/Register";
import Footer from "../layouts/Footer";
import Statistics from "../components/Statistics";
import MCarousel from "../components/Carousel";
import { Link, useLocation } from "react-router-dom";
import { t } from "i18next";
import VideoPlayer from "../components/LoghtBox";

const Home = () => {
  // const location = useLocation()

  // useEffect(() => {
  //   alert('changer')
  // }, [location]);
  const CallAction = () => {
    return (
      <button className=" btn bg-light d-flex justify-content-center align-items-center shadow border border-warning">
        {t('Comment postuler')}?
        <i className="bi bi-play-btn-fill fs-1 text-warning ms-2" />
      </button>
    )
  }
  return (
    <>

      <Header />
      <div id="page" className="page font--jakarta">
        {/* Build a customer-centric */}
        {/* <CustomerCentric /> */}
        <div className="d-flex justify-content-center mt-2">
          <Link to={'/docs/POSTULER.pdf'} target="_blank"
            className="text-warning border border-warning rounded shadow p-2 me-2 mt-2">
            {t('Comment postuler ?')}
            <i className="bi bi-file-pdf ms-2" />
          </Link>
          {/* <VideoPlayer callAction={<CallAction />} /> */}
        </div>


        {/* <hr className="divider" /> */}
        <Statistics />
        <hr className="divider" />

        <SmartSolution />
        {/* <ProgressTowards /> */}

        <hr className="divider" />
        {/* <CompleteSolutions /> */}

        <hr className="divider" />
        {/* <Productivity /> */}
        <Starting />
        {/* <AmazingClients /> */}
      </div>

    </>
  );
};

export default Home;
