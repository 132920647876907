import React, { useContext, useEffect, useState } from 'react'
import Button from '../../../components/Button'
import RadioButton from '../../../components/form/RadioButton';
import { Checkbox, FormControlLabel } from '@mui/material';
import usePostAxiosData from '../../../hooks/usePostAxiosData';
import PreloaderSpinner from '../../../components/PreloaderSpinner';
import { t } from 'i18next';
import { ArrowForwardRounded, CancelRounded, CheckBoxRounded, OpenInBrowser } from '@mui/icons-material';
import { PageContext } from '../../../App';
import SucccessCandidature from '../../../components/SucccessCandidature';

const SubmitApplication = ({ PROPS_DATA }) => {
  const [confirm, setConfirm] = useState(false);
  const [loading, postAxiosData, errorPostMassage, successPostMessage, result] =
    usePostAxiosData(`candidatures/candidates/${PROPS_DATA?.candidate_id}/step-submit`, "POST");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (successPostMessage.status === 200 || successPostMessage.status === 201) {
      PROPS_DATA?.fetchDataCandidature()
      setOpen(true)
    }
  }, [successPostMessage])

  function choose(checked) {

    return checked ? <CheckBoxRounded className="text-success mx-1 fs-5 " />
      : <CancelRounded className="text-danger mx-1 fs-5  " />

  }
  const { activeTab, setactiveTab, handleNext, handlePrevious } = useContext(PageContext);

  const Goto = ({ index }) => (
    <span
      onClick={() => setactiveTab(index)}
      role='button' className='text-primary text-decoration-underlined ms-1'>
      {t("Go to page")}
      <OpenInBrowser />

    </span>
  )
  return (
    loading === true ? <PreloaderSpinner /> :

      <>
        <SucccessCandidature fetchDataCandidature={PROPS_DATA?.fetchDataCandidature} open={open} setOpen={setOpen} />
        <div className='p-sm-3 bg-white h-100 rounded shadow'>

          {/* <h2 className='text-center  mb-4'>Soumettre votre candidature</h2> */}

          <ol className='list-group text-justify'>
            <li className='list-group-item'>
              <div className='d-flex'>
                <div className=''> {choose(PROPS_DATA?.candidature?.step1 === 1)}</div>
                <div> {t("forms.step8.Kindly SUMMARY")} <Goto index={0} /> </div>
              </div>
            </li>
            <li className='list-group-item'> <div className='d-flex'> <div>{choose(PROPS_DATA?.candidature?.step2 === 1)}</div> <div> {t("forms.step8.Kindly PROJECT")}  <Goto index={1} /> </div></div></li>
            <li className='list-group-item'> <div className='d-flex'> <div>{choose(PROPS_DATA?.candidature?.step3 === 1)}</div> <div> {t("forms.step8.Kindly STUDY")}  <Goto index={2} /> </div></div></li>
            <li className='list-group-item'> <div className='d-flex'> <div>{choose(PROPS_DATA?.candidature?.step4 === 1)}</div> <div> {t("forms.step8.Kindly MARKET")}  <Goto index={3} /> </div></div></li>
            <li className='list-group-item'> <div className='d-flex'> <div>{choose(PROPS_DATA?.candidature?.step5 === 1)}</div> <div> {t("forms.step8.Kindly INVESTMENT")}  <Goto index={4} /> </div></div></li>
            <li className='list-group-item'> <div className='d-flex'> <div>{choose(PROPS_DATA?.candidature?.step6 === 1)}</div> <div> {t("forms.step8.Kindly PARTICIPATION")}  <Goto index={5} /> </div></div></li>
            <li className='list-group-item'> <div className='d-flex'> <div>{choose(PROPS_DATA?.candidature?.step7 === 1)}</div> <div> {t("forms.step8.Kindly DOCUMENT")}  <Goto index={6} /> </div></div></li>

            <li className='list-group-item'> <div className='d-flex'> <div>{choose(
              PROPS_DATA?.candidature?.step1 === 1 &&
              PROPS_DATA?.candidature?.step2 === 1 &&
              PROPS_DATA?.candidature?.step3 === 1 &&
              PROPS_DATA?.candidature?.step4 === 1 &&
              PROPS_DATA?.candidature?.step5 === 1 &&
              PROPS_DATA?.candidature?.step6 === 1 &&
              PROPS_DATA?.candidature?.step7 === 1)}</div> <div> {t("forms.step8.You can not submit")}  </div></div></li>
          </ol>

          <FormControlLabel className='mx-4 mt-2 text-danger fs-5' control={<Checkbox disabled={PROPS_DATA?.candidature?.step8 === 1} defaultChecked={confirm} onChange={(e) => setConfirm(e?.target?.checked)} />}
            label={t("forms.step8.certify")} />

          <div className='d-flex justify-content-center mt-5'>
            <Button
              text={t('forms.Submit')}
              className={'btn-primary'}

              disabled={
                !(
                  confirm &&
                  PROPS_DATA?.candidature?.step1 === 1 &&
                  PROPS_DATA?.candidature?.step2 === 1 &&
                  PROPS_DATA?.candidature?.step3 === 1 &&
                  PROPS_DATA?.candidature?.step4 === 1 &&
                  PROPS_DATA?.candidature?.step5 === 1 &&
                  PROPS_DATA?.candidature?.step6 === 1 &&
                  PROPS_DATA?.candidature?.step7 === 1 &&
                  PROPS_DATA?.candidature?.step8 === 0
                )
              }
              handleClick={(e) => {
                e.preventDefault();
                e.stopPropagation()
                postAxiosData()
              }}
            />
          </div>
        </div>
      </>
  )
}

export default SubmitApplication