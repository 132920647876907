
//theme
//core
import "primereact/resources/primereact.min.css";


import React, { useEffect, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { CircularProgress, FormControl, FormLabel } from "@mui/material";
import { config } from "../../constants/config";
import { CancelRounded, CheckBoxRounded, InfoRounded } from "@mui/icons-material";
import { t } from "i18next";
import { FILE_MAX_SIZE } from "../../constants/GLOBAL_VARIALABELS";

export default function FileUploader({ label, name, documents, url, errors , setphpbLoaded}) {
    const [progress, setProgress] = useState(false)
    const [success, setSuccess] = useState(false)
    const [uploaded, setUploaded] = useState(documents?.find((d) => d?.name === name) ? true : false)
    const [error, setError] = useState(null)
    const [doc, setDoc] = useState(null)
 
    function getDoc(name) {
        return documents?.find((d) => d?.name === name) 
    }
    
    useEffect(() => {
        let d =getDoc(name);
        setUploaded(d ? true : false)
        setDoc(d)
    }, [documents]);

    const onUpload = () => {
        if(errors && errors[name]){
            errors[name]=null
        }
        if(setphpbLoaded) {setphpbLoaded(true)}
        setSuccess(true)
        setUploaded(true)
        setProgress(false)
        setError(null)
        // toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };
    const onProgress = () => {
        setSuccess(false)
        setUploaded(false)
        setError(null)
        setProgress(true)
    };
    const onError = () => {
        setSuccess(false)
        setProgress(false)
        setUploaded(false)

        setError("Failed to upload")
        if(setphpbLoaded) {setphpbLoaded(false)}

    };

   const accetps = "image/*,.pdf,.doc,.docx,.odt,.ods,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    return (<>
        <FormControl className="col mb-3">
            <FormLabel className={error ? "text-danger" : uploaded ? "text-success" : ""}>{label} </FormLabel>
            <div className=" d-flex justify-content-start align-items-center p-0">
                {url &&
                    <FileUpload mode="basic" name={name}
                        url={config.app.api_url + url}
                        accept={accetps} 
                        maxFileSize={FILE_MAX_SIZE*1000000}
                        onUpload={onUpload}
                        onProgress={onProgress}
                        onError={onError}
                        auto
                        chooseLabel={uploaded ? t("forms.Replace") : t("forms.Browse")}
                        className={`btn btn-primary ${uploaded ? "btn-success" : "btn-primary"}  rounded shadow d-flex`}
                        onValidationFail={(e)=>setError(`${t('forms.Max file Size is')} :  ${FILE_MAX_SIZE} Mb`)}
                    // style={{backgroundColor : 'red'}}
                    />
                }

                {
                    progress && <CircularProgress color="success" />
                }
                {error && <p className="text-danger"><CancelRounded className="text-danger" /> {error}</p>}
                {uploaded && <CheckBoxRounded className="text-success" />}
            </div>
                {errors && errors[name] && <p className="text-danger"><InfoRounded className="text-danger" />{errors[name]?.message}</p>}
            {/* {uploaded && doc &&  <a target="_blank" href={config.app.api_url+"/documents/"+doc?.id} className="text-success">{t('forms.Uploaded')} <i className="bi bi-download"/> </a>} */}
            {uploaded && <small  className="text-success">{t('forms.Uploaded')}</small>}
        </FormControl>
    </>
    )
}