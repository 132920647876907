import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import { RequireAuth, useAuthUser, useIsAuthenticated } from "react-auth-kit";
import Login from "./pages/auth/Login";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Register from "./pages/auth/Register";
import Apply from "./pages/candidature/Apply";
import InfosCandidature from "./pages/candidature/InfosCandidature";
import VerifyEmail from "./pages/auth/verifyEmail";
import Navbar from "./layouts/Navbar";
import React, { useEffect, useState } from "react";
import useDataFetching from "./hooks/useDataFetching";
import Criteria from "./pages/Criteria";
import { SuccessStories } from "./pages/content/SuccessStories";
import { Evaluators } from "./pages/evaluator/Evaluator";
import usePostAxiosData from "./hooks/usePostAxiosData";
import Result from "./pages/Result";
import Preselect from "./pages/Preselect";

export const PageContext = React.createContext(null);
function App() {
  const [activeTab, setactiveTab] = useState(0);
  const handleNext = (e) => { if (e) { e.preventDefault(); e.stopPropagation(); } setactiveTab(activeTab + 1) }
  const handlePrevious = (e) => { if (e) { e.preventDefault(); e.stopPropagation(); } setactiveTab(activeTab - 1) }
  const [loading, errorc, activeEdition, fetchDataActiveEdition] = useDataFetching(`editions/get-active`)

  const [loadingP, postAxiosData, errorPostMassage, successPostMessage, result] = usePostAxiosData(`tracking`, "POST");
  useEffect(() => {

    postAxiosData('')

  }, []);
  return (
    <>
      <PageContext.Provider value={{ activeTab, setactiveTab, handleNext, handlePrevious, activeEdition }}>
        <Toaster />
        <div className="App"></div>


        <div>
          <Router>
            <Routes>

              <Route exact path={"/"} element={<Home />}></Route>

              <Route exact path={"/login"} element={<Login />}></Route>
              <Route exact path={"/register"} element={<Register />}></Route>
              <Route exact path={"/verify-email"} element={<VerifyEmail />}></Route>
              <Route exact path={"/criteria"} element={<Criteria />}></Route>
              <Route exact path={"/success-stories"} element={<SuccessStories />}></Route>
              <Route exact path={"/evaluators"} element={<Evaluators />}></Route>
              <Route exact path={"/blog"} element={<Blog />}></Route>
              <Route exact path={"/result"} element={<Result />}></Route>
              <Route exact path={"/preselected"} element={<Preselect />}></Route>
              
       
              

            {/* Apply routes */}
            <Route
              exact
              path={"/apply"}
              element={
                // <RequireAuth loginPath={"/login"}>
                <InfosCandidature />
                // </RequireAuth>
              }
            ></Route>
            <Route
              exact
              path={"/apply/steps"}
              element={
                <RequireAuth loginPath={"/login"}>
                  <Apply />
                </RequireAuth>
              }
            ></Route>
          </Routes>
        </Router>
        <Footer />
        </div>
      </PageContext.Provider>
    </>
  );
}

export default App;
