import { Divider } from "@mui/material";
import { t } from "i18next";
import React from "react";

const Statistics = () => {
  return (
    <div id="statistic-1" className="py-50 statistic-section division">
      <div className="container border p-2 shadow">
        {/* <!-- STATISTIC-1 WRAPPER --> */}

        <span className="d-flex flex-column justify-content-center text-center mb-3">

          <h2 className="text-primary text-center">
            {t("Results of the previous editions")}
          </h2>

          <small className="text-center w-100 ">{t("Many goals have been")}</small>
        </span>
        <div className="statistic-1-wrapper">
          <div className="row justify-content-md-start align-items-md-start row-cols-1 row-cols-md-3">
            {/* <!-- STATISTIC BLOCK #1 --> */}
            <div className="col my-2 my-sm-4 ">
              <div id="sb-1-1" className="wow fadeInUp">
                <div className="statistic-block">
                  {/* <!-- Digit --> */}
                  <div className="statistic-block-digit text-center">
                    <h2 className=" statistic-number">
                      +<span className="count-element">40</span>
                    </h2>
                  </div>

                  {/* <!-- Text --> */}
                  <div className="statistic-block-txt color--grey">
                    <p className="p-md">{t("statistics.PMEs created")}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- END STATISTIC BLOCK #1 --> */}

           
            {/* <!-- END STATISTIC-1 WRAPPER --> */}
            {/* <!-- START STATISTIC-2 WRAPPER --> */}

            {/* <!-- STATISTIC BLOCK #1 --> */}
            <div className="col my-2 my-sm-4 ">
              <div id="sb-1-1" className="wow fadeInUp">
                <div className="statistic-block">
                  {/* <!-- Digit --> */}
                  <div className="statistic-block-digit text-center">
                    <h2 className=" statistic-number">
                      <span className="count-element">+30</span>
                    </h2>
                  </div>

                  {/* <!-- Text --> */}
                  <div className="statistic-block-txt color--grey">
                    <p className="p-md">{t("statistics.Nombre de Brevets délivrés")}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- END STATISTIC BLOCK #1 --> */}

            {/* <!-- STATISTIC BLOCK #2 --> */}
            <div className="col my-2 my-sm-4 ">
              <div id="sb-1-1" className="wow fadeInUp">
                <div className="statistic-block">
                  {/* <!-- Digit --> */}
                  <div className="statistic-block-digit text-center">
                    <h2 className=" statistic-number">
                      +<span className="count-element">40</span>
                    </h2>
                  </div>

                  {/* <!-- Text --> */}
                  <div className="statistic-block-txt color--grey">
                    <p className="p-md">{t("statistics.Nombre de prototypes réalisés")}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- END STATISTIC BLOCK #2 --> */}

            {/* <!-- STATISTIC BLOCK #3 --> */}
            <div className="col my-2 my-sm-4 ">
              <div id="sb-1-2" className="wow fadeInUp">
                <div className="statistic-block">
                  {/* <!-- Digit --> */}
                  <div className="statistic-block-digit text-center">
                    <h2 className=" statistic-number">
                      +<span className="count-element">40</span>
                    </h2>
                  </div>

                  {/* <!-- Text --> */}
                  <div className="statistic-block-txt color--grey">
                    <p className="p-md">{t("statistics.Nombre de prototypes mis en marché")}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- END STATISTIC BLOCK #3 --> */}

            {/* <!-- STATISTIC BLOCK #3 --> */}
            <div className="col my-2 my-sm-4 ">
              <div id="sb-1-2" className="wow fadeInUp">
                <div className="statistic-block">
                  {/* <!-- Digit --> */}
                  <div className="statistic-block-digit text-center">
                    <h2 className=" statistic-number">
                      <span className="count-element">17</span>
                    </h2>
                  </div>

                  {/* <!-- Text --> */}
                  <div className="statistic-block-txt color--grey">
                    <p className="p-md">{t("statistics.domaines d'activité concernés")}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- END STATISTIC BLOCK #3 --> */}

            {/* <!-- END STATISTIC BLOCK #4 --> */}

          </div>
        </div>
        <div className="statistic-1-wrapper">
          <div className="row justify-content-md-center align-items-md-center row-cols-1 row-cols-md-1">
            <div className="col  border-top border-bottom py-3 my-2   shadow bg-success   ">
              <div id="sb-1-3" className="wow fadeInUp">
                <div className="statistic-block">
                  {/* <!-- Digit --> */}
                  <div className="statistic-block-digit text-center">
                    <h2 className=" statistic-number text-white">
                      {/* <span className="count-element">4</span>. */}
                      <span className="count-element text-wrap ">200</span> Millions FCFA
                    </h2>
                  </div>

                  {/* <!-- Text --> */}
                  <div className="statistic-block-txt text-white">
                    <p className="p-md ms-sm-1 ">{t("statistics.Montant total des subvention octroyées")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col   border-top border-bottom py-3 my-2   shadow bg-light  ">
              <div id="sb-1-3" className="wow fadeInUp">
                <div className="statistic-block">
                  {/* <!-- Digit --> */}
                  <div className="statistic-block-digit text-center ">
                    <h2 className=" statistic-number  text-wrap">
                      {/* <span className="count-element">4</span>. */}
                      <span className="count-element  text-wrap">867 852 208 </span>FCFA
                    </h2>
                  </div>

                  {/* <!-- Text --> */}
                  <div className="statistic-block-txt color--grey">
                    <p className="p-md ms-sm-1">{t("statistics.Besoins de financement en prototypage")}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col  border-top border-bottom py-3 my-2   shadow bg-primary">
              <div id="sb-1-3" className="wow fadeInUp">
                <div className="statistic-block">
                  {/* <!-- Digit --> */}
                  <div className="statistic-block-digit text-center ">
                    <h2 className=" statistic-number  text-wrap">
                      {/* <span className="count-element">4</span>. */}
                      <span className="count-element  text-wrap text-white">3056</span>
                    </h2>
                  </div>

                  {/* <!-- Text --> */}
                  <div className="statistic-block-txt text-white">
                    <p className="p-md ms-sm-1">{t("statistics.porteurs de projets innovants")}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col border-top border-bottom py-3 my-2   shadow bg-warning">
              <div id="sb-1-3" className="wow fadeInUp">
                <div className="statistic-block">
                  {/* <!-- Digit --> */}
                  <div className="statistic-block-digit text-center ">
                    <h2 className=" statistic-number  text-wrap">
                      {/* <span className="count-element">4</span>. */}
                      <span className="count-element  text-wrap"> 595 490  </span>
                    </h2>
                  </div>

                  {/* <!-- Text --> */}
                  <div className="statistic-block-txt color--black">
                    <p className="p-md ms-sm-1">{t("statistics.Personnes cibles potentielles")}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- End row --> */}
          </div>
        </div>
      </div>
      {/* <!-- End container --> */}
    </div>
  );
};

export default Statistics;
