import { t } from "i18next"
import Navbar from "../../layouts/Navbar"

export const Evaluators = () => {




    return <>
        <Navbar show={false} />
        <div className="container container-mt">

            <h1 className="my-2"> {t("nav.Evaluator")}</h1>


            <img className="rounded shadow mb-3 " width={"100%"} src="assets/images/evaluators/jury.jpeg" />
            <img className="rounded shadow mb-3 " width={"100%"} src="assets/images/evaluators/grand-jury.jpeg" />
            <img className="rounded shadow mb-3 " width={"100%"} src="assets/images/evaluators/partners.jpeg" />
        </div>
    </>
}