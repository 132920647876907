export function toogleMenu(e) {
    if (e) {

        e.preventDefault()
        e.stopPropagation()
    }
    var element = document.getElementById("navwrapper");
    element.classList.toggle("show");
}


export class Utility {
    static convertModelToFormData(model, form, namespace = '') {
        let formData = form || new FormData();
        let formKey;

        for (let propertyName in model) {
            if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
            let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
            if (model[propertyName] instanceof Date)
                formData.append(formKey, model[propertyName].toISOString());

            else if (model[propertyName] instanceof Array) {
                model[propertyName].forEach((element, index) => {
                    const tempFormKey = `${formKey}[${index}]`;
                    this.convertModelToFormData(element, formData, tempFormKey);
                });
            }
            else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
                this.convertModelToFormData(model[propertyName], formData, formKey);
            else if (model[propertyName] instanceof File) {
                console.log("🚀 ~ file: utils.js:23 ~ Utility ~ convertModelToFormData ~ model[propertyName]:", model[propertyName])
                 formData.append(formKey, model[propertyName], model[propertyName]?.name);
                //this.convertModelToFormData(model[propertyName], formData, formKey);

                console.log("🚀 ~ file: utils.js:34 ~ Utility ~ convertModelToFormData ~ formData adding -iimg:", formData)
            }
            else if(!(model[propertyName] instanceof File))
                formData.append(formKey, model[propertyName].toString());
        }
        return formData;
    }
}