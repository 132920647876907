import { t } from "i18next";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../../layouts/Navbar";

const InfosCandidature = () => {
  const location = useLocation()

 
  return (
    <div id="hero-1">
    <Navbar />
    <div className="container p-sm-5 bg-soft rounded">
      <h2
        className="text-center fw-bold mb-3 text-uppercase  text-white p-2 bg-success rounded shadow"
        // style={{ color: "rgb(54, 102, 175)" }}
       
      >
        {t("info.Application form")}
      </h2>
      <div className="row row-cols-1 row-cols-sm-2">
        <div className="col">
          <p className="text-muted" style={{ textAlign: "justify" }}>
            {t("info.Ce dossier constitue")}.
          </p>
        </div>
        <div className="col">
          <p className="text-muted" style={{ textAlign: "justify" }}>
            {t("info.Il est recommandé")} :
            <li className="ms-sm-5">
              {t("info.Veillez à compléter")}
            </li>
            <li className="ms-sm-5">
              {t("info.Si vous ne disposez")}
            </li>
          </p>
          <p className="text-muted mt-4">
            {t("info.Vous pouvez joindre")}
          </p>
        </div>
      </div>
      <div className="my-3 text-center">
      <a className="text-danger border border-danger p-2 shadow-sm rounded" href="/docs/REGLEMENT.pdf" target="_blank"> {t('Voir le règlement')} <i className="bi bi-file-pdf ms-2"/></a>
      </div>
      <div className="d-flex justify-content-center pb-2" aria-haspopup="true">
        <a href={'/apply/steps'} className="btn r-04 btn-success last-link shadow">
          {t("info.Access the form")}<i className="bi bi-arrow-right ms-2"></i>
        </a>
      </div>
    </div>
    </div>
  );
};

export default InfosCandidature;
