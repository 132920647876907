import { TranslateRounded } from "@mui/icons-material";
import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Select, Tooltip } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export default function ChooseLanguage({text_color}) {
  const { i18n } = useTranslation();


  const changeLanguage = (lang) => {
    // const lang = event.target.value
    i18n.changeLanguage(lang);

    window.location.reload(false);

  }

  const [anchorElLang, setAnchorEllang] = useState(null);
  const openLang = Boolean(anchorElLang);

  const handleClickLang = (event) => {
    setAnchorEllang(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEllang(null);
  };
  const backup = () => {
    <>
      <Box

        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Tooltip title="Select languange" className="p-0">
          <IconButton
            className="shadow-sm"
            onClick={handleClickLang}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={openLang ? "lang" : undefined}
            aria-haspopup="true"
            aria-expanded={openLang ? "true" : undefined}
          >
            <Avatar
              className="bg-white text-primary "
              sx={{
                width: 50,
                height: 50,
              }}
            >
              <span className="fw-bold  mt-md-1 d-flex flex-column">
                <small><TranslateRounded className="fs-6" /></small>
                {
                  i18n.language?.split('-')[0]
                  || 'En'
                }
              </span>
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorElLang}
        id="lang"
        open={openLang}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >

        <MenuItem onClick={() => changeLanguage('fr')}>
          <ListItemIcon>
            <TranslateRounded fontSize="small" />
          </ListItemIcon>
          {/* Deconnexion */}
          Fr
        </MenuItem>
        <Divider className="my-1 bg-dark fw-bold" />

        <MenuItem onClick={() => changeLanguage('en')}>
          <ListItemIcon>
            <TranslateRounded fontSize="small" />
          </ListItemIcon>
          {/* Deconnexion */}
          En
        </MenuItem>
      </Menu>
    </>
  }
  return (
    <>
    <span className="choose-lang">
      <Select
       className={`select-lang ${text_color} border border-white rounded`}
      //  style={{width: '200px'}}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={t('Select language"')}
        // items={[{ value: 'en', name: 'En' }, { value: 'fr', name: 'fr' }]}
        value={i18n.language?.split('-')[0]}
        onChange={(e) => changeLanguage(e.target.value)}
      >
        <MenuItem value={'en'}>{t('En')}</MenuItem>
        <MenuItem value={'fr'}>{t('Fr')}</MenuItem>
      </Select>
    </span>

    </>
  )
}