import {
  Alert,
  AlertTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CardForm from "../../../components/form/card";
import FormSectionAccordion from "../../../components/form/FormSection";
import Input from "../../../components/Input";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "../../../hooks/usePostAxiosData";
import useCheckFormSection from "../../../hooks/useCheckFormSection";
import PreloaderSpinner from "../../../components/PreloaderSpinner";
import MSelect from "../../../components/form/Select";
import RadioButton from "../../../components/form/RadioButton";
import Button from "../../../components/Button";
import { t } from "i18next";
import FileUploader from "../../../components/form/FileUploader";
import { PageContext } from "../../../App";
import { ArrowBack, ArrowForward, BackHandRounded } from "@mui/icons-material";
import { Utility } from "../../../utils";
import { Link } from "react-router-dom";
import { config } from "../../../constants/config";

const ProjectLeader = ({ PROPS_DATA }) => {
  const schema = yup.object().shape({
    ph_name: yup.string().required(),
    ph_yearexp: yup.string().required(),
    ph_skill: yup.string().required(),



    // partner 1
    ass1_name: yup.string(),
    ass1_yearexp: yup.string(),
    ass1_skill: yup.string(),

    // partner 2
    ass2_name: yup.string(),
    ass2_yearexp: yup.string(),
    ass2_skill: yup.string(),

    // partner 3
    ass3_name: yup.string(),
    ass3_yearexp: yup.string(),
    ass3_skill: yup.string(),

  });
  const [ph_gender_id, setph_gender_id] = useState(null)
  const [ph_schoolevel_id, setph_schoolevel_id] = useState(null)
  const [ph_nationality, setph_nationality] = useState('Camerounais')


  // partner 1
  const [ass1_sex_id, setass1_sex_id] = useState(null)
  const [ass1_pic, setass1_pic] = useState({})
  const [ass1_schoollevel_id, setass1_schoollevel_id] = useState(null)
  const [ass1_nationality, setass1_nationality] = useState(null)

  // partner 2
  const [ass2_sex_id, setass2_sex_id] = useState(null)
  const [ass2_pic, setass2_pic] = useState({})
  const [ass2_schoollevel_id, setass2_schoollevel_id] = useState(null)
  const [ass2_nationality, setass2_nationality] = useState(null)



  // partner 3
  const [ass3_sex_id, setass3_sex_id] = useState(null)
  const [ass3_pic, setass3_pic] = useState({})
  const [ass3_schoollevel_id, setass3_schoollevel_id] = useState(null)
  const [ass3_nationality, setass3_nationality] = useState(null)

  //others
  const [time_for_project_id, setptime_for_project_id] = useState(null)
  const [time_finish_project_id, settime_finish_project_id] = useState(null)



  // const [ph_nationality, setph_nationality] = useState('Camerounais')
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmitHandler = (data) => {
    handleSave(data);
  };

  const [loading, postAxiosData, errorPostMassage, successPostMessage, result] =
    usePostAxiosData(`candidatures/candidates/${PROPS_DATA?.candidate_id}/step-two`, "POST");
const [errorC, setErrorC] = useState('');
  const handleSave = async (data) => {
    setErrorC('')
   if(!time_for_project_id || !time_finish_project_id || !ph_schoolevel_id){
    setErrorC(t('forms.All fields containing'))
    alert(t('forms.All fields containing'))
   }else{

    const partners = [
      {
        id: PROPS_DATA?.candidature?.get_partners[0]?.id || -1,
        gender_id: ass1_sex_id,
        schoollevel_id: ass1_schoollevel_id,
        name: data?.ass1_name,
        yearexp: data?.ass1_yearexp,
        nationality: ass1_nationality,
        skill: data?.ass1_skill,
        picture: ass1_pic
      },
      {
        id: PROPS_DATA?.candidature?.get_partners[1]?.id || -1,
        gender_id: ass2_sex_id,
        schoollevel_id: ass2_schoollevel_id,
        name: data?.ass2_name,
        yearexp: data?.ass2_yearexp,
        nationality: ass2_nationality,
        skill: data?.ass2_skill,
        picture: ass2_pic
      },
      {
        id: PROPS_DATA?.candidature?.get_partners[2]?.id || -1,
        gender_id: ass3_sex_id,
        schoollevel_id: ass3_schoollevel_id,
        name: data?.ass3_name,
        yearexp: data?.ass3_yearexp,
        nationality: ass3_nationality,
        skill: data?.ass3_skill,
        picture: ass3_pic
      }
    ]
    const toSave = {
      ...data,

      ph_gender_id,
      ph_schoolevel_id,

      time_for_project_id,
      time_finish_project_id,

      partners
      // ass1_sex_id,
      // ass1_schoollevel_id,
      // ass1_nationality,

      // ass2_sex_id,
      // ass2_schoollevel_id,
      // ass2_nationality,

      // ass3_sex_id,
      // ass3_schoollevel_id,
      // ass3_nationality,

    }
    // const formData = new FormData()
    // partners.forEach((p , i)=> {
    //   formData.append('partnersGood', JSON.stringify(p) )
    //   formData.append('partner_'+i+'_Pic$'+p?.id, p?.picture , p?.picture?.name)
    // });
    // Object.keys(toSave)?.map((key)=>{
    //    formData.append(key, toSave[key])
    // })

    //  function buildFormData(formData, data, parentKey) {
    //   if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
    //     Object.keys(data).forEach(key => {
    //       buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    //     });
    //   } else {
    //     const value = data == null ? '' : data;

    //     formData.append(parentKey, value);
    //   }
    // }

    // function jsonToFormData(data) {
    //   const formData = new FormData();

    //   buildFormData(formData, data);

    //   return formData;
    // }

    // console.log("🚀 ~ file: 2_ProjectLeader.js:98 ~ handleSave ~ formData:", jsonToFormData(toSave))
    console.log('Returned formData => ', (Utility.convertModelToFormData(toSave)))
    // await postAxiosData(toSave);
    await postAxiosData(Utility.convertModelToFormData(toSave), 'mulipart/form-data');
  }

  };
  const { activeTab, setactiveTab, handleNext, handlePrevious } = useContext(PageContext);

  useEffect(() => {
    if (successPostMessage.status === 200 || successPostMessage.status === 201) {
      PROPS_DATA?.fetchDataCandidature()
      handleNext()
    }
  }, [successPostMessage])


  const [building, setBuilding] = useState(null)

  const porteur = [
    "ph_name",
    "ph_gender_id",
    "ph_schoolevel_id",
    "ph_yearexp",
    "ph_nationality",
    "ph_skill",
  ]

  const partnet1 = [
    "ass1_name",
    "ass1_yearexp",
    "ass1_nationality",
    "ass1_skill",
    "ass1_sex_id",
    "ass1_schoollevel_id",
    "picture"
  ]

  const partnet2 = [
    "ass2_name",
    "ass2_yearexp",
    "ass2_nationality",
    "ass2_skill",
    "ass2_sex_id",
    "ass2_schoollevel_id",
    "picture"
  ]


  const partnet3 = [
    "ass3_name",
    "ass3_yearexp",
    "ass3_nationality",
    "ass3_skill",
    "ass3_sex_id",
    "ass3_schoollevel_id",
    "picture"
  ]

  const availability = [
    "time_for_project_id",
    "time_finish_project_id",


  ]



  const completed1 = useCheckFormSection(PROPS_DATA?.candidature, porteur)
  const completed2 = cunstomCheckFrom1(partnet1)
  const completed3 = cunstomCheckFrom2(partnet2)
  const completed4 = cunstomCheckFrom3(partnet3)
  const completed5 = useCheckFormSection(PROPS_DATA?.candidature, availability)


  useEffect(() => {
    if (PROPS_DATA?.candidature) {
      setBuilding(true)
      const all = [...porteur, ...partnet1, ...partnet2, ...partnet3, ...availability];
      all.map((key) => {
        const val = PROPS_DATA?.candidature[key]
        // if (key === "ph_nationality") setValue(key, "Camerounais")
        if (key === "ph_gender_id") setph_gender_id(val)
        else if (key === "ph_schoolevel_id") setph_schoolevel_id(val)

        else if (key === "time_for_project_id") setptime_for_project_id(val)
        else if (key === "time_finish_project_id") settime_finish_project_id(val)

        // if (key === "ass1_sex_id") setass1_sex_id(val)
        // if (key === "ass1_schoollevel_id") setass1_schoollevel_id(val)

        // if (key === "ass2_sex_id") setass2_sex_id(val)
        // if (key === "ass2_schoollevel_id") setass2_schoollevel_id(val)

        // if (key === "ass3_sex_id") setass3_sex_id(val)
        // if (key === "ass3_schoollevel_id") setass3_schoollevel_id(val)

        PROPS_DATA?.candidature?.get_partners?.map((p, index) => {
          setValue(`ass${index + 1}_name`, p?.name || '')
          setValue(`ass${index + 1}_yearexp`, p?.yearexp || '')
          setValue(`ass${index + 1}_skill`, p?.skill || '')

          if (index + 1 === 1) {
            setass1_sex_id(p?.gender_id)
            setass1_schoollevel_id(p?.schoolevel_id)
            setass1_nationality(p?.nationality)
            // setass1_pic(p?.picture) 
          }
          if (index + 1 === 2) {
            setass2_sex_id(p?.gender_id)
            setass2_schoollevel_id(p?.schoolevel_id)
            setass2_nationality(p?.nationality)
            // setass2_pic(p?.picture) 

          }
          if (index + 1 === 3) {
            setass3_sex_id(p?.gender_id)
            setass3_schoollevel_id(p?.schoolevel_id)
            setass3_nationality(p?.nationality)
            // setass3_pic(p?.picture) 

          }
        })

        setValue(key, val)

      })


      setTimeout(() => {

        setBuilding(false)
      }, 1000);

    }
  }, [PROPS_DATA?.candidature])

  function cunstomCheckFrom1(keys) {
    let res = null;
    if (keys !== undefined && keys !== null) {
      res = true
      keys && Object.values(keys).map((key) => {

        if (key === "ass1_sex_id" && (ass1_sex_id == null || ass1_sex_id === undefined)) {
          res = false
        }
        else if (key === "ass1_schoollevel_id" && (ass1_schoollevel_id == null || ass1_schoollevel_id === undefined)) {
          res = false

        }
        else if (key === "ass1_nationality" && (ass1_nationality == null || ass1_nationality === undefined)) {
          res = false

        }
        else if (key === "ass1_name" && (getValues(key) === null || getValues(key)) === undefined) {
          return res = false
        }
        else if (key === "ass1_yearexp" && (getValues(key) === null || getValues(key)) === undefined) {
          return res = false
        }
        else if (key === "ass1_yearexp" && (getValues(key) === null || getValues(key)) === undefined) {
          return res = false
        }
      })
    }
    return res
  }

  function cunstomCheckFrom2(keys) {
    let res = null;
    if (keys !== undefined && keys !== null) {
      res = true
      keys && Object.values(keys).map((key) => {

        if (key === "ass2_sex_id" && (ass2_sex_id == null || ass2_sex_id === undefined)) {
          res = false
        }
        else if (key === "ass2_schoollevel_id" && (ass2_schoollevel_id == null || ass2_schoollevel_id === undefined)) {
          res = false

        }
        else if (key === "ass2_nationality" && (ass2_nationality == null || ass2_nationality === undefined)) {
          res = false

        }
        else if (key === "ass2_name" && (getValues(key) === null || getValues(key)) === undefined) {
          return res = false
        }
        else if (key === "ass2_yearexp" && (getValues(key) === null || getValues(key)) === undefined) {
          return res = false
        }
        else if (key === "ass2_yearexp" && (getValues(key) === null || getValues(key)) === undefined) {
          return res = false
        }
      })
    }
    return res
  }

  function cunstomCheckFrom3(keys) {
    let res = null;
    if (keys !== undefined && keys !== null) {
      res = true
      keys && Object.values(keys).map((key) => {

        if (key === "ass3_sex_id" && (ass3_sex_id == null || ass3_sex_id === undefined)) {
          res = false
        }
        else if (key === "ass3_schoollevel_id" && (ass3_schoollevel_id == null || ass3_schoollevel_id === undefined)) {
          res = false
        }
        else if (key === "ass3_nationality" && (ass3_nationality == null || ass3_nationality === undefined)) {
          res = false
        }
        else if (key === "ass3_name" && (getValues(key) === null || getValues(key)) === undefined) {
          return res = false
        }
        else if (key === "ass3_yearexp" && (getValues(key) === null || getValues(key)) === undefined) {
          return res = false
        }
        else if (key === "ass3_yearexp" && (getValues(key) === null || getValues(key)) === undefined) {
          return res = false
        }
      })
    }
    return res
  }


  return (
    loading || building === true ? <PreloaderSpinner /> :
      <>
        <form onSubmit={handleSubmit(onSubmitHandler)} className="px-2">
          {
            (errorPostMassage || errorC )&& <Alert className="my-2 mb-3 rounded shadow  " severity="error">< AlertTitle> {(errorC || errorPostMassage?.message)}</AlertTitle></Alert>
          }
          <Alert className="shadow rounded" severity="info" >

            <span className="text-decoration-underline">{t("forms.Objective")}:</span>
            {t("forms.step2.Show that there is a match")}
          </Alert>
          <FormSectionAccordion
            title={t("Instructions")}

            type="info"
          >
            <div className="row row-cols-1 row-cols-sm-3 my-2 mx-2">
              <CardForm text={t("forms.step2.For each partner")} />
              <CardForm text={t("forms.step2.Fill in a line of the table")} />
              <CardForm text={t("forms.step2.Important reminder")} />
            </div>
          </FormSectionAccordion>
          {/* form */}

     {/* Availability for the project */}
     <FormSectionAccordion completed={completed5} title={t('forms.step2.Availability for the project')}>

<FormControl>
  <FormLabel id="demo-row-radio-buttons-group-label">{t('forms.step2.How many days')} <span className="text-danger fs-4">*</span></FormLabel>
  <RadioGroup
    row
    aria-labelledby="demo-row-radio-buttons-group-label"
    name="row-radio-buttons-group"
    defaultValue={PROPS_DATA?.candidature?.time_for_project_id}

  >


    {
      PROPS_DATA?.time_for_projects?.map((t) => {
        return (
          <RadioButton label={t?.name} value={t?.id}
            handlChange={(e) => setptime_for_project_id(e.target.value)} />

        )
      })
    }
  </RadioGroup>
</FormControl>
<FormControl>
  <FormLabel id="demo-row-radio-buttons-group-label">{t('forms.step2.What deadline')} <span className="text-danger fs-4">*</span></FormLabel>
  <RadioGroup
    row
    aria-labelledby="demo-row-radio-buttons-group-label"
    name="row-radio-buttons-group"
    defaultValue={PROPS_DATA?.candidature?.time_finish_project_id}

  >

    {
      PROPS_DATA?.time_finish_projects?.map((t) => {
        return (
          <RadioButton label={t?.name} value={t?.id} checked={t?.id === PROPS_DATA?.candidature?.time_finish_project_id}
            handlChange={(e) => settime_finish_project_id(e.target.value)} />

        )
      })
    }
  </RadioGroup>
</FormControl>
</FormSectionAccordion>


          {/* Porteur */}
          <FormSectionAccordion expanded={'panel1'} completed={completed1}
            title={t("forms.step2.Principal Carrier/Project")}
          >
            <div className="row row-cols-1 row-cols-sm-2">

              <Input
                register={register}
                error={errors.ph_name?.message}
                className={errors.ph_name?.message && `border-danger`}
                label={t("forms.step2.Name(s) and surname(s)")}
                placeholder={t("forms.step2.Name(s) and surname(s)")}
                isRequired={true}
                name={"ph_name"}
                id={"ph_name"}
                type={"text"}

              />

              <MSelect
                label={t('forms.step2.Gender')}
                items={PROPS_DATA?.genders}
                handleChange={(e) => setph_gender_id(e.target.value)}
                value={ph_gender_id}
                isRequired={true}

              />

              <MSelect
                label={t('forms.step2.Education')}
                items={PROPS_DATA?.schoollevels}
                handleChange={(e) => setph_schoolevel_id(e.target.value)}
                value={ph_schoolevel_id}
                isRequired={true}

              />

              <Input
                register={register}
                error={errors.ph_yearexp?.message}
                className={errors.ph_yearexp?.message && `border-danger`}
                label={t("forms.step2.Years of experience")}
                placeholder={t("forms.step2.Years of experience")}
                isRequired={true}
                name={"ph_yearexp"}
                id={"ph_yearexp"}
                type={"number"}


              />

              <Input
                label={t("forms.step2.Nationality/Country")}
                placeholder={t("forms.step2.Nationality/Country")}
                register={register}
                value={ph_nationality}
                disabled={true}
                error={errors.ph_nationality?.message}
                className={errors.ph_nationality?.message && `border-danger`}
                isRequired={true}
                name={"ph_nationality"}
                id={"ph_nationality"}
                type={"text"}

              />

              <Input
                label={t("forms.step2.Principal skills")}
                placeholder={t("forms.step2.Principal skills")}
                register={register}
                error={errors.ph_skill?.message}
                className={errors.ph_skill?.message && `border-danger`}
                isRequired={true}
                name={"ph_skill"}
                id={"ph_skill"}
                type={"textarea"}

              />

            </div>

          </FormSectionAccordion>

          {/* partner 1 */}
          <FormSectionAccordion completed={completed2} title={t('forms.step2.Project team members/associates') + " 1 " + t("forms.Optional")}>
            <div className="row row-cols-1 row-cols-sm-2">

              <Input
                register={register}
                error={errors.ass1_name?.message}
                className={errors.ass1_name?.message && `border-danger`}
                label={t("forms.step2.Full name")}
                placeholder={t("forms.step2.Full name")}
                // isRequired={true}
                name={"ass1_name"}
                id={"ass1_name"}
                type={"text"}

              />

              <MSelect
                label={t('forms.step2.Gender')}
                items={PROPS_DATA?.genders}
                handleChange={(e) => setass1_sex_id(e.target.value)}
                value={ass1_sex_id}
              // isRequired={true}
              />

              <MSelect
                label={t("forms.step2.Education")}
                items={PROPS_DATA?.schoollevels}
                handleChange={(e) => setass1_schoollevel_id(e.target.value)}
                value={ass1_schoollevel_id}
              // isRequired={true}
              />
              <Input
                register={register}
                error={errors.ass1_yearexp?.message}
                className={errors.ass1_yearexp?.message && `border-danger`}
                label={t("forms.step2.Years of experience")}
                placeholder={t("forms.step2.Years of experience")}
                // isRequired={true}
                name={"ass1_yearexp"}
                id={"ass1_yearexp"}
                type={"number"}

              />

              <MSelect
                label={t("forms.step2.Nationality/Country")}
                items={PROPS_DATA?.countries}
                handleChange={(e) => setass1_nationality(e.target.value)}
                value={ass1_nationality}
              // isRequired={true}
              />

              <Input
                label={t("forms.step2.Principal skills")}
                placeholder={t("forms.step2.Principal skills")}
                register={register}
                error={errors.ass1_skill?.message}
                className={errors.ass1_skill?.message && `border-danger`}
                // isRequired={true}
                name={"ass1_skill"}
                id={"ass1_skill"}
                type={"textarea"}

              />
              <div className="col ">
                <Input
                  label={t("forms.step2.Partner profile")}
                  placeholder={t("forms.step2.Partner profile")}
                  // register={register}
                  // error={errors.ass1_pic?.message}
                  // className={errors.ass1_pic?.message && `border-danger`}
                  // isRequired={true}
                  // value={ass1_pic}
                  name={"ass1_pic"}
                  id={"ass1_pic"}
                  type={"file"}
                  onChange={(e) => { setass1_pic(e.target.files[0]) }}

                />
                {/* <FileUploader name={'parnet-img'} label={'Partner profile'} url={`candidatures/candidates/${PROPS_DATA?.candidate_id}/step-two/partner_img/${PROPS_DATA?.candidature?.get_partners[0]?.id || -1}`} /> */}
                {PROPS_DATA?.candidature?.get_partners && PROPS_DATA?.candidature?.get_partners[0]?.picture &&
                  // <Link target="_blank" to={config.app.api_url + "/documents/" + PROPS_DATA?.candidature?.get_partners[0]?.id + "/partners"}

                  //   className="text-success p-2 border rounded shadow-sm">{t('forms.Uploaded')} <i class="bi bi-download"></i>
                  // </Link>

                  <small className="text-success">{t('forms.Uploaded')}</small>


                }
              </div>
            </div>
          </FormSectionAccordion>

          {/* Partner 2 */}
          <FormSectionAccordion completed={completed3} title={t('forms.step2.Project team members/associates') + " 2 " + t("forms.Optional")}>
            <div className="row row-cols-1 row-cols-sm-2">

              <Input
                register={register}
                error={errors.ass2_name?.message}
                className={errors.ass2_name?.message && `border-danger`}
                label={t("forms.step2.Full name")}
                placeholder={t("forms.step2.Full name")}
                // isRequired={true}
                name={"ass2_name"}
                id={"ass2_name"}
                type={"text"}

              />

              <MSelect
                label={t('forms.step2.Gender')}
                items={PROPS_DATA?.genders}
                handleChange={(e) => setass2_sex_id(e.target.value)}
                value={ass2_sex_id}
              // isRequired={true}
              />

              <MSelect
                label={t("forms.step2.Education")}
                items={PROPS_DATA?.schoollevels}
                handleChange={(e) => setass2_schoollevel_id(e.target.value)}
                value={ass2_schoollevel_id}
              // isRequired={true}
              />
              <Input
                register={register}
                error={errors.ass2_yearexp?.message}
                className={errors.ass2_yearexp?.message && `border-danger`}
                label={t("forms.step2.Years of experience")}
                placeholder={t("forms.step2.Years of experience")}
                // isRequired={true}
                name={"ass2_yearexp"}
                id={"ass2_yearexp"}
                type={"number"}

              />

              <MSelect
                label={t("forms.step2.Nationality/Country")}
                items={PROPS_DATA?.countries}
                handleChange={(e) => setass2_nationality(e.target.value)}
                value={ass2_nationality}
              // isRequired={true}
              />

              <Input
                label={t("forms.step2.Principal skills")}
                placeholder={t("forms.step2.Principal skills")}
                register={register}
                error={errors.ass2_skill?.message}
                className={errors.ass2_skill?.message && `border-danger`}
                // isRequired={true}
                name={"ass2_skill"}
                id={"ass2_skill"}
                type={"textarea"}

              />
              <div className="ms-&">
                <Input
                  label={t("forms.step2.Partner profile")}
                  placeholder={t("forms.step2.Partner profile")}
                  // register={register}
                  // error={errors.ass1_pic?.message}
                  // className={errors.ass1_pic?.message && `border-danger`}
                  // isRequired={true}
                  // value={ass1_pic}
                  name={"ass2_pic"}
                  id={"ass2_pic"}
                  type={"file"}
                  onChange={(e) => { setass2_pic(e.target.files[0]) }}

                />
                {/* <FileUploader name={'parnet-img'} label={'Partner profile'} url={`candidatures/candidates/${PROPS_DATA?.candidate_id}/step-two/partner-img/${PROPS_DATA?.candidature?.get_partners[0]?.id || -1}`} /> */}
                {/* {PROPS_DATA?.candidature?.get_partners && PROPS_DATA?.candidature?.get_partners[1]?.picture &&  <Link target="_blank" to={config?.app?.file_url + PROPS_DATA?.candidature?.get_partners[1]?.picture} */}
                {PROPS_DATA?.candidature?.get_partners && PROPS_DATA?.candidature?.get_partners[1]?.picture &&
                  // <Link target="_blank" to={config.app.api_url + "/documents/" + PROPS_DATA?.candidature?.get_partners[1]?.id + "/partners"}

                  //   className="text-success p-2 border rounded shadow-sm">{t('forms.Uploaded')} <i class="bi bi-download"></i>
                  // </Link>
                  <small className="text-success">{t('forms.Uploaded')}</small>

                }
              </div>
            </div>
          </FormSectionAccordion>

          {/* Partner 3 */}
          <FormSectionAccordion completed={completed4} title={t('forms.step2.Project team members/associates') + " 3 " + t("forms.Optional")} >
            <div className="row row-cols-1 row-cols-sm-2">

              <Input
                register={register}
                error={errors.ass3_name?.message}
                className={errors.ass3_name?.message && `border-danger`}
                label={t("forms.step2.Full name")}
                placeholder={t("forms.step2.Full name")}
                // isRequired={true}                    
                name={"ass3_name"}
                id={"ass3_name"}
                type={"text"}

              />

              <MSelect
                label={t('forms.step2.Gender')}
                items={PROPS_DATA?.genders}
                handleChange={(e) => setass3_sex_id(e.target.value)}
                value={ass3_sex_id}
              // isRequired={true}
              />

              <MSelect
                label={t("forms.step2.Education")}
                items={PROPS_DATA?.schoollevels}
                handleChange={(e) => setass3_schoollevel_id(e.target.value)}
                value={ass3_schoollevel_id}
              // isRequired={true}
              />
              <Input
                register={register}
                error={errors.ass3_yearexp?.message}
                className={errors.ass3_yearexp?.message && `border-danger`}
                label={t("forms.step2.Years of experience")}
                placeholder={t("forms.step2.Years of experience")}
                // isRequired={true}
                name={"ass3_yearexp"}
                id={"ass3_yearexp"}
                type={"number"}

              />

              <MSelect
                label={t("forms.step2.Nationality/Country")}
                items={PROPS_DATA?.countries}
                handleChange={(e) => setass3_nationality(e.target.value)}
                value={ass3_nationality}
              // isRequired={true}
              />

              <Input
                label={t("forms.step2.Principal skills")}
                placeholder={t("forms.step2.Principal skills")}
                register={register}
                error={errors.ass3_skill?.message}
                className={errors.ass3_skill?.message && `border-danger`}
                // isRequired={true}
                name={"ass3_skill"}
                id={"ass3_skill"}
                type={"textarea"}

              />
              <div className="ms-&">
                <Input
                  label={t("forms.step2.Partner profile")}
                  placeholder={t("forms.step2.Partner profile")}
                  // register={register}
                  // error={errors.ass1_pic?.message}
                  // className={errors.ass1_pic?.message && `border-danger`}
                  // isRequired={true}
                  // value={ass1_pic}
                  name={"ass3_pic"}
                  id={"ass3_pic"}
                  type={"file"}
                  onChange={(e) => { setass3_pic(e.target.files[0]) }}

                />
                {PROPS_DATA?.candidature?.get_partners && PROPS_DATA?.candidature?.get_partners[2]?.picture &&
                  // <Link target="_blank" to={config.app.api_url + "/documents/" + PROPS_DATA?.candidature?.get_partners[2]?.id + "/partners"}

                  //   className="text-success p-2 border rounded shadow-sm">{t('forms.Uploaded')} <i class="bi bi-download"></i>
                  // </Link>

                  <small className="text-success">{t('forms.Uploaded')}</small>


                }
                {/* <FileUploader name={'parnet-img'} label={'Partner profile'} url={`candidatures/candidates/${PROPS_DATA?.candidate_id}/step-two/partner-img/${PROPS_DATA?.candidature?.get_partners[0]?.id || -1}`} /> */}
              </div>
            </div>
          </FormSectionAccordion>


     

          <div className="d-flex justify-content-between mt-5 " aria-haspopup="true">

            <Button text={t('forms.Previous')}
              // disabled={PROPS_DATA?.candidature?.step8 === 1}
              className={'btn-danger me-2'}
              handleClick={handlePrevious}
              icon={<ArrowBack />}

            />
            <Button text={t('forms.Save And Continue')}
              disabled={PROPS_DATA?.candidature?.step8 === 1}
              className={'btn-primary'}
              icon={<ArrowForward />}
              arrowEnd={true}
            />
          </div>
        </form>
      </>
  );
};

export default ProjectLeader;
