import React from 'react'
import Navbar from '../layouts/Navbar'
import { t } from 'i18next'

export default function Blog() {
  return <>
<Navbar show={false} />
      <section id="blog-page" className="pb-60 inner-page-hero blog-page-section">
        <div className="container">



          <div className="blog-post wide-post wow fadeInUp">
            <div className="row d-flex align-items-center">



              <div className="col-md-6">
                <div className="blog-post-img">
                  <img className="img-fluid r-16" src="/assets/images/blog/apme11.jpg" alt="blog-post-image" />
                </div>
              </div>



              <div className="col-md-6">
                <div className="blog-post-txt">


                  <span className="post-tag color--blue-400">Social Media Marketing</span>


                  <h3 className="s-38 w-700">
                    <a href="single-post.html">Congue magna tempor and ipsum Martex sapien turpis
                      laoreet augue
                    </a>
                  </h3>


                  <p>Aliqum mullam blandit vitae and tempor sapien and donec lipsum gravida porta undo
                    velna dolor libero a risus aliquet tempus posuere a tempor velna tempus posuere dolor
                  </p>


                  <div className="blog-post-meta mt-30">
                    <ul className="post-meta-list ico-10">
                      <li><p className="w-500">By Martex Team</p></li>
                      <li className="meta-list-divider"><p><span className="flaticon-minus"></span></p></li>
                      <li><p>Apr 28, 2023</p></li>
                    </ul>
                  </div>

                </div>
              </div>


            </div>
          </div>



          <div className="posts-wrapper">
            <div className="row">



              <div className="col-md-6 col-lg-4">
                <div className="blog-post mb-40 wow fadeInUp clearfix">



                  <div className="blog-post-img mb-35">
                    <img className="img-fluid r-16" src="/assets/images/blog/apme10.jpg" alt="blog-post-image" />
                  </div>



                  <div className="blog-post-txt">


                    <span className="post-tag color--red-400">Product News</span>


                    <h6 className="s-20 w-700">
                      <a href="single-post.html">Aliqum mullam porta blandit: lacus and sapien
                        gravida
                      </a>
                    </h6>


                    <p>Egestas luctus vitae augue and ipsum ultrice quisque in cursus lacus feugiat
                      congue diam ultrice laoreet sagittis
                    </p>


                    <div className="blog-post-meta mt-20">
                      <ul className="post-meta-list ico-10">
                        <li><p className="p-sm w-500">By Martex Team</p></li>
                        <li className="meta-list-divider"><p><span className="flaticon-minus"></span></p></li>
                        <li><p className="p-sm">Apr 23, 2023</p></li>
                      </ul>
                    </div>

                  </div>


                </div>
              </div>



              <div className="col-md-6 col-lg-4">
                <div className="blog-post mb-40 wow fadeInUp clearfix">



                  <div className="blog-post-img mb-35">
                    <img className="img-fluid r-16" src="/assets/images/blog/apme12.jpg"  alt="blog-post-image" />
                  </div>



                  <div className="blog-post-txt">


                    <span className="post-tag color--green-400">Community</span>


                    <h6 className="s-20 w-700">
                      <a href="single-post.html">Porttitor cursus fusce neque CEO egestas cursus
                        magna sapien and suscipit ipsum
                      </a>
                    </h6>


                    <p>Aliqum mullam ipsum vitae and blandit vitae tempor sapien and donec lipsum</p>


                    <div className="blog-post-meta mt-20">
                      <ul className="post-meta-list ico-10">
                        <li><p className="p-sm w-500">By Miranda Green</p></li>
                        <li className="meta-list-divider"><p><span className="flaticon-minus"></span></p></li>
                        <li><p className="p-sm">Apr 09, 2023</p></li>
                      </ul>
                    </div>

                  </div>


                </div>
              </div>



              <div className="col-md-12 col-lg-4">
                <div className="blog-post mb-40 wow fadeInUp clearfix">



                  <div className="blog-post-img mb-35">
                    <img className="img-fluid r-16" src="/assets/images/blog/apme15.jpg"  alt="blog-post-image" />
                  </div>



                  <div className="blog-post-txt">


                    <span className="post-tag color--violet-400">Freelancer Tips</span>


                    <h6 className="s-20 w-700">
                      <a href="single-post.html">Cubilia laoreet ipsum augue eget egestas Martex magna</a>
                    </h6>


                    <p>Luctus vitae egestas augue and ipsum ultrice quisque in cursus lacus feugiat
                      egets congue ultrice sagittis laoreet
                    </p>


                    <div className="blog-post-meta mt-20">
                      <ul className="post-meta-list ico-10">
                        <li><p className="p-sm w-500">By Helen J.</p></li>
                        <li className="meta-list-divider"><p><span className="flaticon-minus"></span></p></li>
                        <li><p className="p-sm">Apr 01, 2023</p></li>
                      </ul>
                    </div>

                  </div>


                </div>
              </div>


            </div>
          </div>



          <div className="row">
            <div className="col">
              <div className="posts-category ico-20 wow fadeInUp">
                <h4 className="s-34 w-700">Latest News <span className="flaticon-next"></span></h4>
              </div>
            </div>
          </div>



          <div className="posts-wrapper">
            <div className="row">



              <div className="col-md-6 col-lg-4">
                <div className="blog-post mb-40 wow fadeInUp clearfix">



                  <div className="blog-post-img mb-35">
                    <img className="img-fluid r-16" src="/assets/images/blog/apme23.jpg"  alt="blog-post-image" />
                  </div>



                  <div className="blog-post-txt">


                    <span className="post-tag color--pink-400">Tutorials</span>


                    <h6 className="s-20 w-700">
                      <a href="single-post.html">Phasellus blandit justo undo aliquam vitae molestie
                        nunc sapien augue justo aliquet
                      </a>
                    </h6>


                    <p>Aliqum mullam ipsum vitae and blandit vitae tempor sapien and donec lipsum</p>


                    <div className="blog-post-meta mt-20">
                      <ul className="post-meta-list ico-10">
                        <li><p className="p-sm w-500">By Miranda Green</p></li>
                        <li className="meta-list-divider"><p><span className="flaticon-minus"></span></p></li>
                        <li><p className="p-sm">Mar 20, 2023</p></li>
                      </ul>
                    </div>

                  </div>


                </div>
              </div>



              <div className="col-md-6 col-lg-4">
                <div className="blog-post mb-40 wow fadeInUp clearfix">



                  <div className="blog-post-img mb-35">
                    <img className="img-fluid r-16" src="/assets/images/blog/amis.png"   alt="blog-post-image" />
                  </div>



                  <div className="blog-post-txt">


                    <span className="post-tag color--violet-400">Freelancer Tips</span>


                    <h6 className="s-20 w-700">
                      <a href="single-post.html">Blandit justo phasellus undo aliquam diam molestie
                        vitae
                      </a>
                    </h6>


                    <p>Luctus vitae egestas augue and ipsum ultrice quisque in cursus lacus feugiat
                      egets congue ultrice sagittis laoreet
                    </p>


                    <div className="blog-post-meta mt-20">
                      <ul className="post-meta-list ico-10">
                        <li><p className="p-sm w-500">By Martex Team</p></li>
                        <li className="meta-list-divider"><p><span className="flaticon-minus"></span></p></li>
                        <li><p className="p-sm">Mar 13, 2023</p></li>
                      </ul>
                    </div>

                  </div>


                </div>
              </div>



              <div className="col-md-12 col-lg-4">
                <div className="blog-post mb-40 wow fadeInUp clearfix">



                  <div className="blog-post-img mb-35">
                    <img className="img-fluid r-16" src="/assets/images/blog/apme12.jpg"   alt="blog-post-image" />
                  </div>



                  <div className="blog-post-txt">


                    <span className="post-tag color--blue-400">Extensions</span>


                    <h6 className="s-20 w-700">
                      <a href="single-post.html">Cursus porttitor fusce neque CEO egestas cursus magna
                        sapien and ipsum suscipit
                      </a>
                    </h6>


                    <p>Mullam ipsum aliqum vitae and blandit vitae tempor sapien and lipsum donec</p>


                    <div className="blog-post-meta mt-20">
                      <ul className="post-meta-list ico-10">
                        <li><p className="p-sm w-500">By Martex Team</p></li>
                        <li className="meta-list-divider"><p><span className="flaticon-minus"></span></p></li>
                        <li><p className="p-sm">Feb 23, 2023</p></li>
                      </ul>
                    </div>

                  </div>


                </div>
              </div>


            </div>
          </div>



          <div className="blog-post square-post">
            <div className="row">
              <div className="col">



                <div className="square-post-wrapper bg--black-400 block-shadow r-16 wow fadeInUp">
                  <div className="row d-flex align-items-center">



                    <div className="col-md-6 order-last order-md-2">
                      <div className="blog-post-txt color--white">


                        <span className="post-tag color--pink-400">Video Tutorials</span>


                        <h4 className="s-34 w-700 mb-20">
                          <a href="single-post.html">Lipsum sodales sapien Martex aliquet blandit
                            augue gravida posuere
                          </a>
                        </h4>


                        <p>Aliqum mullam blandit vitae and tempor sapien and donec lipsum gravida porta
                          undo velna dolor libero risus aliquet tempus posuere vitae tempor
                        </p>


                        <div className="blog-post-meta mt-30">
                          <ul className="post-meta-list ico-10">
                            <li><p className="w-500">By Martex Team</p></li>
                            <li className="meta-list-divider"><p><span className="flaticon-minus"></span></p></li>
                            <li><p>Feb 12, 2023</p></li>
                          </ul>
                        </div>

                      </div>
                    </div>



                    <div className="col-md-6 order-first order-md-2">
                      <div className="blog-post-img video-preview">


                        <a className="video-popup1" href="https://www.youtube.com/embed/SZEflIVnhH8">
                          <div className="video-btn video-btn-xl bg--pink-400 ico-90">
                            <div className="video-block-wrapper"><span className="flaticon-play-button"></span></div>
                          </div>
                        </a>


                        <img className="img-fluid" src="/assets/images/blog/apme5.jpg"   alt="video-preview" />

                      </div>
                    </div>


                  </div>
                </div>


              </div>
            </div>
          </div>




        </div>
      </section>









      <hr className="divider" />





      <section id="newsletter-1" className="newsletter-section">
        <div className="newsletter-overlay">
          <div className="container">
            <div className="row d-flex align-items-center row-cols-1 row-cols-lg-2">



              <div className="col">
                <div className="newsletter-txt">
                  <h4 className="s-34 w-700">{t('What are you waiting for')} ?</h4>
                </div>
              </div>



              <div className="col">

                  <a className='btn btn-success shadow' href='/apply'>{t('nav.Apply')}</a>



              </div>


            </div>
          </div>
        </div>
      </section>








    </>

}
