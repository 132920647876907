import { t } from "i18next";
import Navbar from "../layouts/Navbar";
import { ArrowForward, ArrowRight } from "@mui/icons-material";
import CriteriaCard from "../components/Criteriacard";

export default function Criteria() {


    return <div id="hero-1" className="criteria">
        <Navbar />
        <div className="container   pb-2 " >
            {/* <div className="container-mt bg-primary">

</div> */}
            <div class="col-lg-12 text-center bg-soft rounded p-2">
                <section>

                    <h2 class="section-heading text-uppercase shadow text-white bg-success rounded d-flex flex-column justify-content-center align-items-center  flex-sm-row">
                        <img src="/assets/images/criterias/list.png" className="criteria-img-title me-3" alt="icon-title" />
                         {t("criteria.ELIGIBILITY CRITERIA")}
                         </h2>
                    <p class="section-subheading text-muted my-2">
                        {t('criteria.title')}
                    </p>
                    <hr/>

                    <div class="row row-cols-1 row-cols-sm-2 " >

                        <CriteriaCard text={t('criteria.1')} icon={<img className="criteria-img" src="/assets/images/criterias/lamp.png" alt="card" />} />
                        <CriteriaCard text={t('criteria.2')} icon={<img className="criteria-img" src="/assets/images/criterias/lamp-pencil.png" alt="card" />} />
                        <CriteriaCard text={t('criteria.3')} icon={<img className="criteria-img" src="/assets/images/criterias/list-poc.png" alt="card" />} />
                        <CriteriaCard text={t('criteria.4')} icon={<img className="criteria-img" src="/assets/images/criterias/camera.png" alt="card" />} />

                    </div>
                    <p className="mt-3">{t('criteria.NB')}</p>
                </section>

                <section>
                    <hr />
                    <h2 class="section-heading text-uppercase text-white bg-success shadow rounded d-flex flex-column justify-content-center align-items-center  flex-sm-row">
                    <img src="/assets/images/criterias/list.png" className="criteria-img-title me-3" alt="icon-title" />
                        {t('criteria.CONDITIONS OF ELIGIBILITY')}</h2>
                    <p class="section-subheading text-muted my-2">
                    
                        {t('criteria.etitle')}
                    </p>
                    <hr/>
                    <div class="row row-cols-1 row-cols-sm-2">

                        <CriteriaCard text={t('criteria.e1')} icon={<img className="criteria-img" src="/assets/images/criterias/leaf.png" alt="card" />} />
                        <CriteriaCard text={t('criteria.e2')} icon={<img className="criteria-img" src="/assets/images/criterias/women.png" alt="card" />} />
                        <CriteriaCard text={t('criteria.e3')} icon={<img className="criteria-img" src="/assets/images/criterias/house.png" alt="card" />} />
                        <CriteriaCard text={t('criteria.e4')} icon={<img className="criteria-img" src="/assets/images/criterias/hands.png" alt="card" />} />

                    </div>
                </section>
                <div className="d-flex justify-content-center mt-5">
                    <a className="btn btn-success shadow" href="/apply">{t('Continue')} <ArrowForward /></a>
                </div>
            </div>
        </div>

    </div>

}