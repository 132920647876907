
export const config = {
  app: {
    // api_url: "http://192.168.243.244:8000/api",
    //  api_url: "http://192.168.1.8:8001/api",
    api_url: "https://api.fonds-proto-apme.com/public/api",
    // file_url: "http://192.168.243.244:8000/storage/",
    file_url: "https://api.fonds-proto-apme.com/public/storage/",
  },
};
