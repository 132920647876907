import { t } from "i18next";
import React from "react";

const SmartSolution = () => {
  return (
    <>
      {/* <section id="lnk-2" className="pt-100 ct-02 content-section division">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div className="img-block left-column wow fadeInRight">
                <img
                  className="img-fluid"
                  src="/assets/images/img-03.png"
                  alt="content-image"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="txt-block right-column wow fadeInLeft">
                <div className="txt-box">
                  <h5 className="s-24 w-700">The smarter way to work</h5>

                  <p>
                    Sodales tempor sapien quaerat ipsum undo congue laoreet
                    turpis neque auctor turpis vitae dolor luctus placerat magna
                    and ligula cursus purus vitae purus an ipsum suscipit
                  </p>
                </div>

                <div className="txt-box mb-0">
                  <h5 className="s-24 w-700">Full access to all features</h5>

                  <ul className="simple-list">
                    <li className="list-item">
                      <p>
                        Cursus purus suscipit vitae cubilia magnis volute
                        egestas vitae sapien turpis sodales magna undo aoreet
                        primis
                      </p>
                    </li>

                    <li className="list-item">
                      <p className="mb-0">
                        Tempor sapien quaerat an ipsum laoreet purus and sapien
                        dolor an ultrice ipsum aliquam undo congue dolor cursus
                        purus congue and ipsum purus sapien a blandit
                      </p>
                    </li>
                  </ul>

                  <a
                    href="#features-2"
                    className="btn btn-sm r-04 btn--tra-black hover--theme"
                  >
                    What's possible?
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section
        id="features-12"
        className="shape--bg shape--white-500 pt-100 features-section division mb-md-5"
      >
        <div className="container">
          <div className="row d-flex align-items-center">
            {/* <!-- TEXT BLOCK --> */}
            <div className="col-md-5">
              <div className="txt-block left-column wow fadeInRight">
                {/* <!-- Section ID --> */}
                <span className="section-id">{t('winner.Winners')}</span>

                {/* <!-- Title --> */}
                <h2 className="s-46 w-700">
                  {t("winner.Follow the winners of the First edition")}
                </h2>

                {/* <!-- Text --> */}
                {/* <p>
                  Sodales tempor sapien quaerat ipsum and congue undo laoreet
                  turpis neque auctor turpis vitae dolor luctus placerat magna
                  ligula and cursus vitae
                </p> */}

                {/* <!-- CONTENT BOX #1 --> */}
                {/* <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>

                  <div className="cbox-1-txt">
                    <p>Magna dolor luctus at egestas sapien</p>
                  </div>
                </div> */}

                {/* <!-- CONTENT BOX #2 --> */}
                {/* <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>

                  <div className="cbox-1-txt">
                    <p>
                      Cursus purus suscipit vitae cubilia magnis volute egestas
                      vitae sapien turpis and ultrice magnis
                    </p>
                  </div>
                </div> */}

                {/* <!-- CONTENT BOX #3 --> */}
                {/* <div className="cbox-1 ico-15">
                  <div className="ico-wrap color--theme">
                    <div className="cbox-1-ico">
                      <span className="flaticon-check"></span>
                    </div>
                  </div>

                  <div className="cbox-1-txt">
                    <p className="mb-0">
                      Volute turpis dolores and sagittis congue
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <!-- END TEXT BLOCK --> */}

            {/* <!-- FEATURES-12 WRAPPER --> */}
            <div className="col-md-7">
              <div className="fbox-12-wrapper wow fadeInLeft">
                <div className="row">
                  <div className="col-md-6">
                    {/* <!-- FEATURE BOX #1 --> */}
                    <div
                      id="fb-12-1"
                      className="fbox-12 bg--white-100 block-shadow r-12 mb-30"
                    >
                      {/* <!-- Icon --> */}
                      <div className="fbox-ico ico-50">
                        <div className="shape-ico color--theme">
                          {/* <!-- Vector Icon --> */}
                          <span className="flaticon-layers-1"></span>

                          {/* <!-- Shape --> */}
                          <svg
                            viewBox="0 0 200 200"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                              transform="translate(100 100)"
                            />
                          </svg>
                        </div>
                      </div>
                      {/* <!-- End Icon --> */}

                      {/* <!-- Text --> */}
                      <div className="fbox-txt">
                        <h5 className="s-19 w-700">BELLA ODEN Gervais
                          Martial</h5>
                        <p>
                          Projet de production locale et de
                          commercialisation de l’alcool
                          pharmaceutique a base des fruits
                          avariés à DOUALA -CAMEROUN
                        </p>
                      </div>
                    </div>

                    {/* <!-- FEATURE BOX #2 --> */}
                    <div
                      id="fb-12-2"
                      className="fbox-12 bg--white-100 block-shadow r-12"
                    >
                      {/* <!-- Icon --> */}
                      <div className="fbox-ico ico-50">
                        <div className="shape-ico color--theme">
                          {/* <!-- Vector Icon --> */}
                          <span className="flaticon-tutorial"></span>

                          {/* <!-- Shape --> */}
                          <svg
                            viewBox="0 0 200 200"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                              transform="translate(100 100)"
                            />
                          </svg>
                        </div>
                      </div>
                      {/* <!-- End Icon --> */}

                      {/* <!-- Text --> */}
                      <div className="fbox-txt">
                        <h5 className="s-19 w-700">MATEMTSAP MBOU
BEQUERELLE </h5>
                        <p>
                        FARMGUARD 
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    {/* <!-- FEATURE BOX #3 --> */}
                    <div
                      id="fb-12-3"
                      className="fbox-12 bg--white-100 block-shadow r-12 mb-30"
                    >
                      {/* <!-- Icon --> */}
                      <div className="fbox-ico ico-50">
                        <div className="shape-ico color--theme">
                          {/* <!-- Vector Icon --> */}
                          <span className="flaticon-pay-per-click"></span>

                          {/* <!-- Shape --> */}
                          <svg
                            viewBox="0 0 200 200"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                              transform="translate(100 100)"
                            />
                          </svg>
                        </div>
                      </div>
                      {/* <!-- End Icon --> */}

                      {/* <!-- Text --> */}
                      <div className="fbox-txt">
                        <h5 className="s-19 w-700">MEYE BA NTYAM
SERGE STEPHANE </h5>
                        <p>
                        ANTIVIRUS MEYE PROTECT
                        </p>
                      </div>
                    </div>

                    {/* <!-- FEATURE BOX #4 --> */}
                    <div
                      id="fb-12-4"
                      className="fbox-12 bg--white-100 block-shadow r-12"
                    >
                      {/* <!-- Icon --> */}
                      <div className="fbox-ico ico-50">
                        <div className="shape-ico color--theme">
                          {/* <!-- Vector Icon --> */}
                          <span className="flaticon-taxes"></span>

                          {/* <!-- Shape --> */}
                          <svg
                            viewBox="0 0 200 200"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z"
                              transform="translate(100 100)"
                            />
                          </svg>
                        </div>
                      </div>
                      {/* <!-- End Icon --> */}

                      {/* <!-- Text --> */}
                      <div className="fbox-txt">
                        <h5 className="s-19 w-700">AISSATA IBAMIE </h5>
                        <p>
                        Asaab thermal cooker
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End row --> */}
            </div>
            {/* <!-- END FEATURES-12 WRAPPER --> */}
            <div className="d-flex justify-content-end">
              <a
                href=""
                className=" btn btn-sm r-04 btn-primarycol-12 col-md-2
               my-3"
              >
                See All
              </a>
            </div>
          </div>
          {/* <!-- End row --> */}
        </div>
        {/* <!-- End container --> */}
      </section>

      {/* <section className="py-100 ct-02 content-section division">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div className="img-block left-column wow fadeInRight">
                <img
                  className="img-fluid"
                  src="/assets/images/img-02.png"
                  alt="content-image"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="txt-block right-column wow fadeInLeft">
                <span className="section-id">Easy Integration</span>

                <h2 className="s-46 w-700">
                  Plug your essential tools in few clicks
                </h2>

                <ul className="simple-list">
                  <li className="list-item">
                    <p>
                      Cursus purus suscipit vitae cubilia magnis volute egestas
                      vitae sapien turpis sodales magna undo aoreet primis
                    </p>
                  </li>

                  <li className="list-item">
                    <p className="mb-0">
                      Tempor sapien quaerat an ipsum laoreet purus and sapien
                      dolor an ultrice ipsum aliquam undo congue dolor cursus
                      purus congue and ipsum purus sapien a blandit
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default SmartSolution;
