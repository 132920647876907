import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";
import { CancelRounded, CheckBoxRounded, HelpCenterRounded, ImportContacts, SendAndArchiveRounded, TimeToLeaveRounded } from "@mui/icons-material";

export default function FormSectionAccordion(params) {
    const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <>
            <Accordion
                expanded={expanded === params?.expanded} onChange={handleChange(params?.expanded)}
                className="my-2  "
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="shadow-sm bg-light "
                >
                    <Typography className="fw-bold fs-6 d-flex align-content-baseline">
                     <span>   {params?.title}</span>
                        {
                            params?.completed === true ? <CheckBoxRounded className="text-success ms-2 fs-5 " /> :
                            params?.completed === false &&   <CancelRounded className="text-danger ms-2 fs-5  " />
                        }
                     { params?.type==="info" &&  <span className="ms-2 text-info">  <HelpCenterRounded/></span>}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {params?.children}
                </AccordionDetails>
            </Accordion>
        </>
    )
}