import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../components/Input";
import {
  Alert,
  AlertTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import FormSectionAccordion from "../../../components/form/FormSection";
import Button from "../../../components/Button";
import usePostAxiosData from "../../../hooks/usePostAxiosData";
import useCheckFormSection from "../../../hooks/useCheckFormSection";
import PreloaderSpinner from "../../../components/PreloaderSpinner";
import RadioButton from "../../../components/form/RadioButton";
import { t } from "i18next";
import { PageContext } from "../../../App";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const Coaching = ({ PROPS_DATA }) => {
  const [is_laureat, setis_laureat] = useState(false);
  const [hasrequestadvice, sethasrequestadvice] = useState(false);
  const [typeadvice, settypeadvice] = useState(false);
  /** Form validation config */
  const schema = yup.object().shape({
    if_laureat: yup.string(),
    if_hasrequestadvice: yup.string(),
    if_typeadvice: yup.string(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = (data) => {
    handleSave(data);
  };
  const [loading, postAxiosData, errorPostMassage, successPostMessage, result] =
    usePostAxiosData(`candidatures/candidates/${PROPS_DATA?.candidate_id}/step-six`, "POST");

  const handleSave = async (data) => {
    const toSave = {
      ...data,
      is_laureat,
      hasrequestadvice,
      typeadvice,
    }
    console.log("🚀 ~ file: 2_ProjectLeader.js:98 ~ handleSave ~ toSave:", toSave)
    // console.log('tosave => ',toSave)
    await postAxiosData(toSave);

  };
  const {activeTab, setactiveTab, handleNext, handlePrevious} = useContext(PageContext);

  useEffect(() => {
    if (successPostMessage.status === 200 || successPostMessage.status === 201) {
      PROPS_DATA?.fetchDataCandidature()
      handleNext()
    }
    
  }, [successPostMessage])

  const description = [
    // "if_laureat",
    // "if_hasrequestadvice",
    // "if_typeadvice",

    "is_laureat",
    "hasrequestadvice",
    "typeadvice",

  ]

  const completed1 = useCheckFormSection(PROPS_DATA?.candidature, description)
  const [building, setBuilding] = useState(null)
  useEffect(() => {
    if (PROPS_DATA?.candidature) {
      setBuilding(true)
      description.map((key) => {
        const val = PROPS_DATA?.candidature[key]
        // Yup set value
        // States setValue
        if (key === "is_laureat") setis_laureat(val)
        else if (key === "hasrequestadvice") sethasrequestadvice(val)
        else if (key === "typeadvice") settypeadvice(val)


        else if (key === "if_hasrequestadvice") setValue(key, '')
        else if (key === "if_typeadvice") setValue(key, '')
        else if (key === "if_laureat") setValue(key, '')

        else setValue(key, val)

      })

      setTimeout(() => {

        setBuilding(false)
      }, 1000);

    }
  }, [PROPS_DATA?.candidature])

  return (
    loading || building === true ? <PreloaderSpinner /> :
      <>
        <form className="mt-4" onSubmit={handleSubmit(onSubmitHandler)}>
        {
            errorPostMassage && <Alert className="my-2 mb-3 rounded shadow  " severity="error">< AlertTitle> {errorPostMassage?.message}</AlertTitle></Alert>
          }
          <Alert className="shadow rounded" severity="info" >
            <span className="">
              <span className="text-decoration-underline">Objective:</span> Take
              into account the achievements of other support
            </span>r
          </Alert>
          <FormSectionAccordion expanded={'panel1'} completed={completed1}
            title={'Coaching'}>
            <div className="row row-cols-1">
              <div className="col mt-2">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                  {t("forms.step6.Are you a recipient")}

                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={PROPS_DATA?.candidature?.is_laureat===1 ? "yes" : "no"}

                  >
                    <RadioButton
                      value={"yes"}
                      label={t("yes")}
                      handlChange={() => setis_laureat(true)}
                    />

                    <RadioButton
                      value="no"
                      label={t("no")}
                      handlChange={() => setis_laureat(false)}
                    />


                  </RadioGroup>
                </FormControl>
                {is_laureat ? (
                  <Input
                    register={register}
                    error={errors.if_laureat?.message}
                    className={errors.if_laureat?.message && `border-danger`}
                    label={t("forms.step6.Please specify")}
                    placeholder={t("forms.step6.Please specify")}
                    isRequired={true}
                    name={"if_laureat"}
                    id={"if_laureat"}
                    type={"textarea"}
                    cols={20}
                    rows={3}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="col mt-2">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                   {t("forms.step6.Have you ever contacted")}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={PROPS_DATA?.candidature?.hasrequestadvice===1 ? "yes" : "no"}

                  >

                    <RadioButton
                      value={"yes"}
                      label={t("yes")}
                      handlChange={() => sethasrequestadvice(true)}
                    />

                    <RadioButton
                      value="no"
                      label={t("no")}
                      handlChange={() => sethasrequestadvice(false)}
                    />


                  </RadioGroup>
                </FormControl>
                {hasrequestadvice ? (
                  <Input
                    register={register}
                    error={errors?.if_hasrequestadvice?.message}
                    className={
                      errors?.if_hasrequestadvice?.message && `border-danger`
                    }
                    label={t("forms.step6.Please specify")}
                    placeholder={t("forms.step6.Please specify")}
                    isRequired={true}
                    name={"if_hasrequestadvice"}
                    id={"if_hasrequestadvice"}
                    type={"textarea"}
                    cols={20}
                    rows={3}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="col mt-2">
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                  {t("forms.step6.What type of follow-up")}

                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={PROPS_DATA?.candidature?.typeadvice===1 ? "yes" : "no"}

                  >
                       <RadioButton
                      value={"yes"}
                      label={t("yes")}
                      handlChange={() => settypeadvice(true)}
                    />

                    <RadioButton
                      value="no"
                      label={t("no")}
                      handlChange={() => settypeadvice(false)}
                    />
                    
                  </RadioGroup>
                </FormControl>
                {typeadvice ? (
                  <Input
                    register={register}
                    error={errors?.if_typeadvice?.message}
                    className={errors?.if_typeadvice?.message && `border-danger`}
                    label={t("forms.step6.Please specify")}
                    placeholder={t("forms.step6.Please specify")}
                    isRequired={true}
                    name={"if_typeadvice"}
                    id={"followType"}
                    type={"textarea"}
                    cols={20}
                    rows={3}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </FormSectionAccordion>

          <div className="d-flex justify-content-between mt-5 " aria-haspopup="true">
           
          <Button text={t('forms.Previous')}
              // disabled={PROPS_DATA?.candidature?.step8 === 1}
              className={'btn-danger me-2'}
              handleClick={handlePrevious}
              icon={<ArrowBack />}

            />
            <Button text={t('forms.Save And Continue')}
            
            disabled={PROPS_DATA?.candidature?.step8 === 1}
            className={'btn-primary'}
            icon={<ArrowForward />}
            arrowEnd={true}

            />
          </div>
        </form>
      </>
  );
};

export default Coaching;
