import { t } from "i18next";
import { config } from "../../constants/config";

export default function CardStory({ s, index }) {

    return <>
        <div key={index} className={`row d-flex  ${index % 2 ? ' flex-row-reverse ' : ''}  align-items-center border rounded shadow mb-3 pt-4 `}>

            <div className="d-flex align-items-baseline text-success mb-3">
                <h2 className="text-success">{s?.candidate_name}</h2>
                <small className="ms-2">{s?.titre}</small>
            </div>
            <div className="col-md-12 order-last order-md-2">

                {/* <span className="section-id">Productivity Focused</span> */}
                <img className="img-story" src={config?.app?.file_url + s?.image || "assets/images/img-06.png"} alt="content-image" />

                <p>
                    {s?.body}
                </p>

            </div>


            {/* <div className="col-md-6 order-first order-md-2 position-relative ">
                <span className="badge bg-warning rounded-0 p-2 position-absolute top-0 w-25"> Edtion {s?.edition}</span>

                <div className="img-block right-column wow fadeInLeft">
                    <img className="img-fluid" src={config?.app?.file_url + s?.image || "assets/images/img-06.png"} alt="content-image" />
                </div>
            </div> */}


        </div>
    </>

}