import { useState } from "react";
import { config } from "../constants/config";
import axios from "axios";
// import { useAuthHeader } from "react-auth-kit";

const usePostAxiosData = (url, method = "POST") => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const [errorMassage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState({
    status: null,
    message: "",
  });
  // const authHeader = useAuthHeader();
  const postAxiosData = async (data,contentType) => {
    try {
      setLoading(true);
      setErrorMessage(null)
      const configurationData = {
        method: method,
        url: `${config.app.api_url}/${url}`,
        headers: {
          "Content-Type": contentType || "application/json",
          // Authorization: authHeader(),
        },
        data: data,
      };
      await axios(configurationData)
        .then((response) => {
          setLoading(false);

          // console.log("RESPONSE post", response);
          if (response?.status === 200 || response?.status === 201 || response?.data?.status === 200 || response?.data?.status === 201) {
            setResult(response?.data);
            setSuccessMessage({
              status: response?.data?.status || response?.status,
              message: response?.data?.message,
            });
          }

        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage({
            status: error?.response?.status,
            message: error?.response?.data?.message,
            already_registered :error?.response?.data?.already_registered,
          });
          return null;
        });
    } catch (error) {
      setLoading(false);
      setErrorMessage({
        status: error?.response?.data?.status,
        message: error?.response?.data?.message,
      });
    }
  };

  return [loading, postAxiosData, errorMassage, successMessage, result];
};

export default usePostAxiosData;
