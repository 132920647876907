import { t } from "i18next"
import useDataFetching from "../../hooks/useDataFetching"
import Navbar from "../../layouts/Navbar"
import { config } from "../../constants/config"
import CardStory from "../stories/CardStoriy"

export function SuccessStories() {
    const [loadinginnovations, errorinnovations, stories, fetchDataGinnovations] = useDataFetching('stories')
    // const ss = [
    //     {
    //         title: "Achieve more with better workflows",
    //         body: "Achieve more with better workflows Sodales tempor Achieve more with better workflows Sodales tempor  sapien quaerat ipsum undo congue laoreet turpis neque auctor turpis itae dolor luctus placerat magna and ligula cursus purus vitae purus an ipsum suscipit",
    //         img: { src: "assets/images/img-06.png" }
    //     },
    //     {
    //         title: "Achieve more with better workflows",
    //         body: "Achieve more with better workflows Sodales tempor Achieve more with better workflows Sodales tempor  sapien quaerat ipsum undo congue laoreet turpis neque auctor turpis itae dolor luctus placerat magna and ligula cursus purus vitae purus an ipsum suscipit",
    //         img: { src: "assets/images/img-05.png" }
    //     }
    // ]

    const succesStories = stories?.map((s, index) => {

        return (

            <>
                <CardStory s={s} index={index} />
            </>
        )
    })

    const StorYMoafo = () => {
        return <>

            <div className="p-3 story shadow rounded mb-3">
                <div className="d-flex align-items-center text-success mb-3">
                    <span className="text-success text-wrap "><span className="fs-1 fw-bold">MOUAFO FOGIENG :</span>
                        <small className="ms-2 fs-3">le respirateur multiparamétrique solaire bipatients prototypé a déjà généré +70 millions FCFA de chiffre d'affaires en moins de 2 ans de test de marché</small>
                    </span>

                </div>
                <div className="row row-cols-1 row-cols-sm-2 wow fadeIn">
                    <div className="col">
                        <div className="img-story"
                            style={
                                {
                                    backgroundImage: "url(assets/images/stories/mou.png)", backgroundSize: 'cover', backgroundRepeat: 'no-repeat'
                                }
                            }

                        >
                            {/* <img className="img-story" src={`assets/images/img-06.png`} alt="content-image" /> */}
                        </div>

                        <div className="d-flex align-items-baseline">
                            <span className="text-success fs-1 m-0 p-0">G</span>
                            <span>ENERAL BIOTECH SARL basée à Bangangté, est l'entreprise créée par</span>
                        </div>
                        <p>
                            cet innovateur pour apporter des solutions médicales et biomédicales pour améliorer le système de santé camerounais (plateau technique des hôpitaux, réduction du taux de mortalité des personnes atteintes de maladies ou en détresse respiratoires. Le Respirateur GBT RESPI conçu en 2020 avec l'appui du Fonds-Proto, est destiné à une clientèle variée (Etat/CTD. ONG/Associations, formations sanitaires privées, distributeurs d'équipements biomédicaux,
                        </p>
                    </div>
                    <div className="col">

                        <p>
                            etc.) qui constitue un marché global de 200 milliards de FCFA. Après avoir offerts 03 unités gratuites à la Clinique Virginia à Bertoua à l'Université des Montagnes (UDM) et à l'hôpital Mère et Enfants de Ngaoundéré pour un test en environnement réel. la startup a ensuite produit et commercialisé une pré-série de 22 unités de GBT RESPI à ce jour, pour un CA de 70 millions de FCFA, avec 11 salariés.
                        </p>
                        <p>
                            Cet innovateur recherche un financement de 4 milliards pour implanter  02 usines de production (Bonabéri à Douala et Odza et Yaoundé) pouvant lui permettre de satisfaire 15% de ce marché.
                        </p>
                        <div className="img-story"
                            style={
                                {
                                    backgroundImage: "url(assets/images/stories/mou2.png)", backgroundSize: 'cover', backgroundRepeat: 'no-repeat'
                                }
                            }

                        >
                            {/* <img className="img-story" src={`assets/images/img-06.png`} alt="content-image" /> */}
                        </div>
                    </div>

                </div>
            </div>
        </>
    }

    const StorYDognmo = () => {
        return <>

            <div className="p-3 story shadow rounded mb-3">
                <div className="d-flex align-items-center text-success mb-3">
                    <span className="text-primary text-wrap "><span className="fs-1 fw-bold">
                        VANYL DONGO :
                    </span>
                        <small className="ms-2 fs-3">
                            déjà 02 prototypes d'outillages industriels réalisés, 02 PME spécialisées créées Madcon 3D SARL et PEMESSO SARL qui emploient 8 salariés à temps plein et 12 salariés à temps partiel
                        </small>
                    </span>

                </div>
                <div className="row row-cols-1 row-cols-sm-2 wow fadeInRight">
                    <div className="col">
                        <div className="img-story"
                            style={
                                {
                                    backgroundImage: "url(assets/images/stories/don.png)", backgroundSize: 'cover', backgroundRepeat: 'no-repeat'
                                }
                            }

                        >
                            {/* <img className="img-story" src={`assets/images/img-06.png`} alt="content-image" /> */}
                        </div>

                        <div className="d-flex align-items-center">
                            <span className="text-primary fs-1 m-0 p-0 me-2 ">A</span>
                            <span>u terme du processus des Appels à Projets 2020 et 2021 du Fond-Proto. j'ai bénéficié des appui-subventions</span>
                        </div>
                        <p>
                            respectivement de 2.000.000 Fcfa et 2.500.000Fcfa. Ces sommes m'ont aidé à fabriquer deux prototypes: une machine de moulage à injection et une machine combinée de découpe et gravure laser (avec fraiseuse 3D et une imprimante 3D en option)
                        </p>
                        <p>
                            Avec le Découpeur Plasma », d'une envergure de 350/220cm. la découpe de toute sorte de métaux avec une haute précision est facilitée pour nos divers clients (architectes. ferronniers, propriétaires de maison en construction et entrepreneurs divers)
                        </p>
                    </div>
                    <div className="col">

                        <p>
                            Pour la mise en exploitation de cette technologie en 2022, j'ai créé l'entreprise PEMESSO SARL qui a développé des produits/services autour de l'ameublement, la ferronnerie, la robotique et la formation, qui ont généré un chiffre d'affaires de +15 millions de FCFA
                        </p>
                        <p>
                            Le coût minimal d'une machine est de 06 millions de FCFA. Très peu d'artisans de menuiserie métallique et/ou bois (+de 6000) peuvent l'acquérir au Cameroun. La demande potentielle s'évalue à 36 milliards de FCFA un marché dont je compte
                            acquérir et contrôler 20% de parts par an. en produisant en masse. Pour ce faire, je recherche un financement de FCFA 500 millions, avec l'aide de l'Etat et l'apport des investisseurs. pour implanter une unité de fabrication en série, ainsi que 20 clusters (ateliers mutualisés) de fabrication numérique pour améliorer la productivité et doper les activités des PME/artisans.
                        </p>
                        <div className="img-story"
                            style={
                                {
                                    backgroundImage: "url(assets/images/stories/don2.png)", backgroundSize: 'cover', backgroundRepeat: 'no-repeat'
                                }
                            }

                        >
                            {/* <img className="img-story" src={`assets/images/img-06.png`} alt="content-image" /> */}
                        </div>
                    </div>

                </div>
            </div>
        </>
    }


    const StorYKouoGue = () => {
        return <>

            <div className="p-3 story shadow rounded mb-3 wow fadeInLeft">
                <div className="d-flex align-items-center text-success mb-3">
                    <span className="text-warning text-wrap "><span className="fs-1 fw-bold">
                        TCHULANG KOUOGUE Triomphant,
                    </span>
                        <small className="ms-2 fs-3">
                            l'innovateur
                            qui propose le Frigo Solaire Photothermique «BISOLAR-TECH FRIDGE ou BTF-10/17 » pour le stockage et la conservation à froid de diverses denrées quand l'accès à l'énergie électrique est impossible
                        </small>
                    </span>

                </div>
                <div className="row row row-cols-1 row-cols-sm-2">
                    <div className="col">
                        <div className="img-story"
                            style={
                                {
                                    backgroundImage: "url(assets/images/stories/kou.png)", backgroundSize: 'cover', backgroundRepeat: 'no-repeat'
                                }
                            }

                        >
                            {/* <img className="img-story" src={`assets/images/img-06.png`} alt="content-image" /> */}
                        </div>

                        {/* <div className="d-flex align-items-center">
                            <span className="text-primary fs-1 m-0 p-0 me-2 ">S</span>
                            <span>u terme du processus des Appels à Projets 2020 et 2021 du Fond-Proto. j'ai bénéficié des appui-subventions</span>
                        </div> */}
                        <p>
                            Selon une étude, la FAO a estimé qu'environ 50% des produits alimentaires au Cameroun sont perdus après la récolte, par défaut de conservation et de stockage à froid adéquats. Idem pour les vaccins et autres produits médicaux.
                        </p>
                        <p>
                            La solution proposée est BTF-10/17 un éco- congélateur alimenté par l'énergie solaire pour la conservation des denrées alimentaires. produits pharmaceutique et vaccins dans des environnements hors réseau électrique. Ce produit vient renforcer la sécurité alimentaire et sanitaire des communautés. Il utilise des réfrigérants non toxiques. réduit les besoins en énergie pour le stockage à froid et fournit l'excédent d'énergie pour d'autres besoins domestiques.
                        </p>


                    </div>
                    <div className="col">

                        <p>
                            En recyclant de vieux appareils frigorifiques, 58 unités (pré- série du prototype actuel) ont déjà été produites à date et vendues pour un CA d'environ FCFA 70 millions. La startup basée à Douala emploie 25 salariés dont 11 permanents. Cet effectif passera à +135
                        </p>
                        <p>
                            salariés lors du passage à l'échelle souhaité en 2024. qui va porter la production à 7.800 BTF-10/17 par an (25/ jour) représentant 3.12% des importations annuelles du Cameroun. Pour ce faire. le coût des investissements nécessaires dans l'immédiat est évalué à FCFA 147 millions avec une projection de ventes de FCFA 3.9 milliards.
                        </p>
                        <div className="img-story"
                            style={
                                {
                                    backgroundImage: "url(assets/images/stories/kou2.png)", backgroundSize: 'cover', backgroundRepeat: 'no-repeat'
                                }
                            }

                        >
                            {/* <img className="img-story" src={`assets/images/img-06.png`} alt="content-image" /> */}
                        </div>
                    </div>

                </div>
            </div>
        </>
    }

    return <>
        <Navbar show={false} />
        <div className="container container-mt">
            <h1 className="mb-3">{t('nav.Success Stories')}</h1>
            <StorYMoafo />
            <StorYDognmo />
            <StorYKouoGue/>
        </div>
    </>
}


