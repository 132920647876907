import { useState, useEffect, useCallback } from "react";
import { config } from "../constants/config";
// import { useAuthHeader } from "react-auth-kit";

const useDataFetching = (url, type) => {
  console.log("🚀 ~ file: useDataFetching.js:6 ~ useDataFetching ~ url:", url)

  const [loading, setLoading] = useState(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  // const authHeader = useAuthHeader()
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      if (url !== null && url !== undefined) {
        const data = await fetch(config.app.api_url + '/' + url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const result = await data.json();
        if (result) {
          setData(result?.data);
          setLoading(false);
        }
      }
    } catch (e) {
      setError(e?.message);
      setLoading(false);
      setData(null);
    }
  }, [url]);

  useEffect(() => {
    if (type === 'no') {
    } else {

      fetchData();
    }
  }, []);


  return [loading, error, data, fetchData];
};

export default useDataFetching;
