import React from "react";

const Footer = () => {
  return (
    <footer id="footer-3" className="pt-5 border bg-light footer ft-3-ntr" >
      <div className="container">
        {/* <!-- FOOTER CONTENT --> */}
        <div className="row text-center text-sm-start">
          {/* <!-- FOOTER LOGO --> */}
          <div className="col-xl-3">
            <div className="footer-info">
              <img
                className="footer-logo"
                src="/logo.png"
                alt="footer-logo"
              />
              <img
                className="footer-logo-dark"
                src="/logo.png"
                alt="footer-logo"
              />
            </div>
          </div>

          {/* <!-- FOOTER LINKS --> */}
          <div className="col col-sm-4 col-lg-3 col-xl-4">
            <div className="footer-links fl-1">
              {/* <!-- Title --> */}
              <h6 className="s-17 w-700">APME</h6>

              {/* <!-- Links --> */}
              <ul className="foo-links clearfix">
                <li>
                  <p className="m-0 p-0">
                    <a href="#">About Us</a>
                  </p>
                </li>
                <div className="d-flex flex-column">
                  <span className="my-2">699 35 57 09/ 6 95 38 11 98/ 6 94 58 59 26/ 6 55 37 39 98/ 6 72 88 10 88 </span>
                  {/* <span>fonds-proto-apme.com </span> */}
                  <span className="my-2">contact@fonds-proto-apme.com </span>
                </div>
                {/* <li>
                    <p><a href="blog-listing.html">Our Blog</a></p>
                  </li>
                  <li>
                    <p><a href="testimonials.html">Customers</a></p>
                  </li>
                  <li>
                    <p><a href="#">Community</a></p>
                  </li> */}
              </ul>
            </div>
          </div>
          {/* <!-- END FOOTER LINKS --> */}

          {/* <!-- FOOTER LINKS --> */}
          {/* */}
          {/* <!-- END FOOTER LINKS --> */}


          {/* <!-- FOOTER NEWSLETTER FORM --> */}
          <div className="col-sm-10 col-md-8 col-lg-4 col-xl-3">
            <div className="footer-form">
              {/* <!-- Title --> */}
              <h6 className="s-17 w-700">Follow the Best</h6>

              {/* <!-- Newsletter Form Input --> */}
              <form className="newsletter-form">
                <div className="input-group r-06">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    required
                    id="s-email"
                  />
                  <span className="input-group-btn ico-15">
                    <button type="submit" className="btn color--theme">
                      <span className="flaticon-right-arrow-1"></span>
                    </button>
                  </span>
                </div>

                {/* <!-- Newsletter Form Notification --> */}
                <label HtmlFor="s-email" className="form-notification"></label>
              </form>
            </div>
          </div>
          {/* <!-- END FOOTER NEWSLETTER FORM --> */}
        </div>
        {/* <!-- END FOOTER CONTENT --> */}

        <hr />
        {/* <!-- FOOTER DIVIDER LINE --> */}

        {/* <!-- BOTTOM FOOTER --> */}
        <div className="bottom-footer">
          <div className="row row-cols-1 row-cols-md-2 d-flex align-items-center">
            {/* <!-- FOOTER COPYRIGHT --> */}
            <div className="col">
              <div className="footer-copyright">
                <p className="p-sm">
                  Copyright &copy; APME {new Date().getFullYear()}. <span>All Rights Reserved</span>
                </p>
             
              </div>
            </div>

            {/* <!-- FOOTER SOCIALS --> */}
            <div className="col">
              <ul className="bottom-footer-socials ico-20 text-end">
                <li>
                  <a href="#">
                    <span className="flaticon-facebook"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="flaticon-twitter"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="flaticon-linkedin-logo"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- End row --> */}
        </div>
        {/* <!-- END BOTTOM FOOTER --> */}
      </div>
      {/* <!-- End container --> */}
    </footer>
  );
};

export default Footer;
