import React, { useState } from "react";
import { useSignIn } from "react-auth-kit";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import usePostAxiosData from "../../hooks/usePostAxiosData";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import PreloaderSpinner from "../../components/PreloaderSpinner";
import useCustomSignin from "../../hooks/useCunstomSigin";
import Navbar from "../../layouts/Navbar";
import ResetPassword from "./ResetPassword";
import Button from "../../components/Button";
import { t } from "i18next";

const Login = () => {
  const navigate = useNavigate();
  /** Form validation config */
  const schema = yup.object().shape({
    login: yup.string().required("login is a required field"),
    password: yup.string().required("Password is a required field"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    handleLogin(data);
  };
  /** End validation config */

  /** handle signIn */
  // const signIn = useSignIn();
  const customSignin = useCustomSignin()
  function handleSignin(data) {
    customSignin(data?.data, "/apply")
    // const res = signIn({
    //   // token: data?.access_token,
    //   // refreshToken: data?.refresh_token,
    //   expiresIn: 3600,
    //   // refreshTokenExpireIn: data?.refreshExpiration,
    //   // tokenType: "Bearer",
    //   authState: {
    //     user: data?.data
    //   },
    //   // refreshToken: data.refreshToken,                    // Only if you are using refreshToken feature
    //   // refreshTokenExpireIn: data.refreshTokenExpireIn     // Only if you are using refreshToken feature
    // });
    // if (res) {
    //   navigate("/");
    // }
  }

  /** post registration */
  const [loading, postAxiosData, errorMassage, successMessage, result] =
    usePostAxiosData(`users/login`, "POST");

  const handleLogin = async (dataYub) => {
    const data = {
      login: dataYub?.login,
      password: dataYub?.password,
    };

    await postAxiosData(data);
  };

  React.useEffect(() => { }, [errorMassage]);
  React.useEffect(() => {
    if (successMessage?.status == 200 || successMessage?.status?.user == 201) {
      if (result?.data?.user?.is_email_verified === 0) {
        navigate('/verify-email')
      } else {

        toast.success(`${successMessage?.message} `, {
          duration: 4000,
          icon: "👏",
          className: "bg-success text-white",
        });
        handleSignin(result);
      }
    }
  }, [successMessage]);

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = (e) => {
    e.preventDefault();
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  return (
    <>
      {loading ? (
        <PreloaderSpinner />
      ) : (
        <div id="login">
          <Navbar className={''} />

          <div className="bg--scroll login-section division auth mt-3">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-11">
                  <div className="register-page-wrapper r-16 bg--fixed">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="register-page-txt color--white">
                          <h2 className="s-42 w-700">Welcome</h2>
                          <h2 className="s-42 w-700">back to APME</h2>

                          {/* <p className="p-md mt-25">Integer congue sagittis and velna augue egestas magna 
											   suscipit purus aliquam
											</p> */}

                          <div className="register-page-copyright">
                            <p className="p-sm">
                              {" "}
                              Copyright &copy; APME 2023.{" "}
                              <span>All Rights Reserved</span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="d-flex justify-content-center">
                          <img
                            className="img-fluid"
                            style={{ maxHeight: "90px" }}
                            src="/logo.png"
                            alt="logo-image"
                          />
                        </div>

                        <span
                          className="d-flex justify-content-center mt-3 mb-0 fw-bold text-dark"
                          style={{ fontSize: "3rem" }}
                        >
                          {t("nav.Sign in")}
                        </span>
                        <div className="register-page-form">
                          <form
                            className="row sign-in-form"
                            onSubmit={handleSubmit(onSubmitHandler)}
                          >
                            {/* <div className="col-md-12">
													<a  href="#" className="btn btn-google ico-left">
														<img src="/assets/images/png_icons/google.png" alt="google-icon" /> Sign in with Google
													</a>
												</div>  

												<div className="col-md-12 text-center">	
													<div className="separator-line">Or, sign in with your email</div>
												</div> */}
                            {errorMassage?.message && (
                              <span
                                class="alert alert-danger d-flex align-items-center p-1 w-100"
                                role="alert"
                              >
                                <span>
                                  {errorMassage?.message != "" &&
                                    errorMassage?.message}
                                </span>
                              </span>
                            )}

                            <div className="col-md-12">
                              <p className="p-sm input-header">{t("forms.Email address")}  {t("forms.or")} {t("forms.step1.Phone number")}</p>
                              <input
                                {...register(`login`)}
                                className={`form-control  m-0 eamil ${errors.login?.message && `border-danger`
                                  }`}
                                type="text"
                                name="login"
                                id="email"
                                placeholder="example@example.com"
                              />
                              <p className="text-danger p-0 ">
                                {errors.login?.message !== ""
                                  ? errors.email?.message
                                  : ""}
                              </p>
                            </div>

                            <div className="col-md-12">
                              <p className="p-sm input-header">{t("forms.Password")}</p>
                              <div className="wrap-input">
                                <span
                                  onClick={(e) => handleShowPassword(e)}
                                  className="btn-show-pass ico-20"
                                >
                                  <span
                                    className={`${showPassword
                                      ? "flaticon-invisible"
                                      : "flaticon-visibility "
                                      } eye-pass`}
                                  ></span>
                                </span>
                                <input
                                  {...register(`password`)}
                                  className={`form-control  m-0 password ${errors.password?.message && `border-danger`
                                    }`}
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  id="password"
                                  placeholder="* * * * * * * * *"
                                />
                              </div>
                              <p className="text-danger p-0 ">
                                {errors.password?.message !== ""
                                  ? errors.password?.message
                                  : ""}
                              </p>
                            </div>

                            <div className="col-md-12 mt-3">
                              <div className="reset-password-link">
                                <p className="p-sm">
                                  <ResetPassword />
                                </p>
                              </div>
                            </div>

                            <div className="d-flex justify-content-center mt-4">

                              <Button text={t('nav.Sign in')}
                                className={'btn-primary'}
                              />
                            </div>

                            <div className="col-md-12">
                              <p className="create-account text-center">
                                {t("forms.Don't have an account")}?{" "}
                                <a href="/register" className="text-primary">
                                  {t("nav.Sign up")}
                                </a>
                              </p>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
